import Vue from "vue";
import ApiService from "@/core/services/api.service";

const patch_market = "api/v1/market";
//Actions
export const GET_ALL_MARKETS = "getAllMarkets";
export const GET_MARKET = "getMarket";
export const STORE_MARKET = "storeMarket";
export const UPDATE_MARKET = "updateMarket";
export const DELETE_MARKET = "deleteMarket";
export const CANCEL_MARKET = "cancelMarket";

//Mutations
export const SET_MARKETS = "setMarkets";
export const SET_MARKET = "setMarket";
export const PUSH_NEW_MARKET = "pushMarket";
export const PUSH_UPDATE_MARKET = "pushUpdateMarket";
export const REMOVE_ONE_MARKET = "removeOneMarket";
export const SET_LOADING = "setLoading";
export const SET_EDITING = "setEditing";
export const CLEAN_MARKET = "cleanmarket";

const state = {
  markets: [],
  market: {},
  isLoading: false,
  isEditing: false,
};

const getters = {
  markets(state) {
    state.markets.forEach((item, index) => {
      let auxCountries = [];
      item.countries.forEach(country => {
        auxCountries.push(country.nameTranslation);
      });
      state.markets[index].countriesName = auxCountries.join();
      if (auxCountries.length > 50) {
        state.markets[index].auxName =
          String(auxCountries).slice(0, 130) + "...";
        state.markets[index].aux = true;
      } else {
        state.markets[index].auxName = auxCountries.join();
        state.markets[index].aux = false;
      }
    });
    return state.markets;
  },
  market(state) {
    return state.market;
  },
  isLoadingMarket(state) {
    return state.isLoading;
  },
  isEditingMarket(state) {
    return state.isEditing;
  },
};

const actions = {
  [GET_ALL_MARKETS](context) {
    context.commit("setIsVisibleLoader", true);
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(patch_market)
        .then(({ data }) => {
          context.commit(SET_MARKETS, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },

  [GET_MARKET](context, id) {
    const market = context.state.markets.filter(item => {
      if (Number(item.marketId) === Number(id)) {
        return item;
      }
    });
    context.commit(SET_MARKET, market[0]);
  },

  [STORE_MARKET](context, data) {
    context.commit(SET_LOADING, true);
    ApiService.setHeader();
    return new Promise(resolve => {
      ApiService.post(patch_market, data)
        .then(({ data }) => {
          context.commit(PUSH_NEW_MARKET, data.market.record);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [UPDATE_MARKET](context, data) {
    ApiService.setHeader();
    context.commit(SET_LOADING, true);
    return new Promise(resolve => {
      ApiService.put(`${patch_market}/${data.marketId}`, data)
        .then(({ data }) => {
          context.commit(PUSH_UPDATE_MARKET, data.market.record);
          context.commit(SET_LOADING, false);
          context.commit(SET_EDITING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [DELETE_MARKET](context, id) {
    context.commit(SET_LOADING, true);
    ApiService.setHeader();
    return new Promise(resolve => {
      ApiService.delete(`${patch_market}/${id}`)
        .then(data => {
          context.commit(REMOVE_ONE_MARKET, id);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [CANCEL_MARKET](context) {
    context.commit(SET_LOADING, true);
  },
};
const mutations = {
  [SET_MARKETS](state, data) {
    state.markets = data;
  },
  [SET_MARKET](state, data) {
    state.market = data;
  },
  [PUSH_NEW_MARKET](state, data) {
    state.markets.push(data);
  },
  [PUSH_UPDATE_MARKET](state, data) {
    const position = state.markets.indexOf(state.market);
    Vue.set(state.markets, position, data);
  },
  [REMOVE_ONE_MARKET](state, data) {
    state.markets.forEach((item, index) => {
      if (Number(item.marketId) === Number(data)) {
        state.markets.splice(index, 1);
      }
    });
  },
  [SET_LOADING](state, data) {
    state.isLoading = data;
  },
  [SET_EDITING](state, data) {
    state.isEditing = data;
  },
  [CLEAN_MARKET](state) {
    Vue.set(state.market, {});
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
