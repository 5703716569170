import Vue from "vue";
import ApiService from "@/core/services/api.service";
import { SET_KEY_LEFT_MENU } from "../auth.module";
//Actions
export const GET_COMPANY = "getCompany";
export const GET_COMPANY_CONDITION = "getCompanyCondition";
export const GET_COMPANY_COMMISSION = "getCompanyCommission";
export const STORE_COMPANY = "storeCompany";
export const UPDATE_COMPANY = "updateCompany";
export const DELETE_COMPANY = "deleteCompany";
export const REGISTER_COMPANY = "registerCompany";

//Superadmin action
export const LOGIN_COMPANY = "loginCompany";
export const LOGOUT_COMPANY = "logoutCompany";
export const GET_ALL_COMPANY = "getAllCompany";
export const SEND_WIZARD_EMAIL_COMPANY = "sendWizardEmailCompany";
export const INACTIVE_COMPANY = "inactiveCompany";
export const REACTIVE_COMPANY = "reactiveCompany";
export const CHANGE_ORIGIN_PRICE = "changeOriginPrice";

//Mutations
export const SET_COMPANY = "setCompany";
export const SET_COMPANY_CONDITION = "setCompanyCondition";
export const SET_COMPANY_COMMISSION = "setCompanyCommission";
export const PUSH_NEW_COMPANY = "pushCompany";
export const PUSH_UPDATE_COMPANY = "pushUpdateCompany";
export const REMOVE_ONE_COMPANY = "removeOneCompany";
export const SET_LOADING = "setLoading";
export const SET_EDITING = "setEditing";
export const CLEAN_COMPANY = "cleancompany";
export const SET_ERROR = "setError";

const patch_company = "api/v1/company";

const state = {
  errors: null,
  companies: [],
  company: {},
  companyCondition: {},
  companyCommission: {},
  isLoading: false,
  isEditing: false,
};

const getters = {
  Companies(state) {
    return state.companies;
  },
  company(state) {
    return state.company;
  },
  CompanyCondition(state) {
    return state.companyCondition;
  },
  CompanyCommission(state) {
    return state.companyCommission;
  },
  isLoadingCompany(state) {
    return state.isLoading;
  },
  isEditingCompany(state) {
    return state.isEditing;
  },
};

const actions = {
  [GET_COMPANY](context, id) {
    context.commit(SET_LOADING, true);
    ApiService.setHeader();
    return new Promise(resolve => {
      ApiService.get(`${patch_company}/${id}`)
        .then(({ data }) => {
          context.commit(SET_COMPANY, data.company);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [STORE_COMPANY](context, data) {
    context.commit(SET_LOADING, true);
    return new Promise(resolve => {
      ApiService.post(patch_company, data)
        .then(({ data }) => {
          context.commit(PUSH_NEW_COMPANY, data);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [UPDATE_COMPANY](context, data) {
    context.commit(SET_LOADING, true);
    return new Promise(resolve => {
      ApiService.put(`${patch_company}/${data.companyId}`, data)
        .then(data => {
          context.commit(PUSH_UPDATE_COMPANY, data.data.company);
          context.commit(SET_LOADING, false);
          context.commit(SET_EDITING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [DELETE_COMPANY](context, id) {
    context.commit(SET_LOADING, true);
    return new Promise(resolve => {
      ApiService.delete(`api/v1/company/${id}`)
        .then(({ data }) => {
          context.commit(REMOVE_ONE_COMPANY, data);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [REGISTER_COMPANY](context, data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("api/v1/registration-form", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          context.commit(SET_ERROR, err);
          reject(err);
        });
    });
  },

  [LOGIN_COMPANY](context, formData) {
    ApiService.setHeader();
    context.commit("setIsVisibleLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.post("api/v1/company/login", { companyId: formData })
        .then(({ data }) => {
          context.dispatch("loadVariableData", data);

          const currentUser = context.getters.currentUser;

          context.commit("setUser", {
            ...currentUser,
            user: { ...data["user"] },
          });

          context.commit(SET_KEY_LEFT_MENU);
          resolve(data);
        })
        .catch(err => {
          context.commit("setIsVisibleLoader", false);
          reject(err);
        });
    });
  },
  [LOGOUT_COMPANY](context, data) {
    ApiService.setHeader();
    context.commit("setIsVisibleLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.post("api/v1/company/logout", { companyId: data })
        .then(({ data }) => {
          context.commit("setCampuses", undefined);
          context.commit("setCampus", undefined);
          context.commit("setMarkets", undefined);
          context.commit("setCompany", undefined);
          context.commit("setProductStartDateTemplates", undefined);

          const currentUser = context.getters.currentUser;

          context.commit("setUser", {
            ...currentUser,
            user: { ...data["user"] },
          });

          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(err => {
          context.commit("setIsVisibleLoader", false);
          reject(err);
        });
    });
  },
  [GET_ALL_COMPANY](context) {
    ApiService.setHeader();
    context.commit("setIsVisibleLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.get("api/v1/company/list/all")
        .then(({ data }) => {
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(err => {
          context.commit("setIsVisibleLoader", false);
          reject(err);
        });
    });
  },
  [SEND_WIZARD_EMAIL_COMPANY](context, data) {
    ApiService.setHeader();
    context.commit("setIsVisibleLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.post("api/v1/company/send-email", { companyId: data })
        .then(({ data }) => {
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(err => {
          context.commit("setIsVisibleLoader", false);
          reject(err);
        });
    });
  },
  [INACTIVE_COMPANY](context, data) {
    ApiService.setHeader();
    context.commit("setIsVisibleLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.post("api/v1/company/inactive", { companyId: data })
        .then(({ data }) => {
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(err => {
          context.commit("setIsVisibleLoader", false);
          reject(err);
        });
    });
  },
  [REACTIVE_COMPANY](context, data) {
    ApiService.setHeader();
    context.commit("setIsVisibleLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.post("api/v1/company/reactive", { companyId: data })
        .then(({ data }) => {
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(err => {
          context.commit("setIsVisibleLoader", false);
          reject(err);
        });
    });
  },

  [GET_COMPANY_CONDITION](context) {
    ApiService.setHeader();
    context.commit("setIsVisibleLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.get("api/v1/condition/actual")
        .then(({ data }) => {
          context.commit(SET_COMPANY_CONDITION, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(err => {
          context.commit("setIsVisibleLoader", false);
          reject(err);
        });
    });
  },

  [GET_COMPANY_COMMISSION](context, json) {
    if (json.auth) {
      ApiService.setHeader();
    }
    context.commit("setIsVisibleLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/commission/${json.companyId}`)
        .then(({ data }) => {
          context.commit(SET_COMPANY_COMMISSION, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(err => {
          context.commit("setIsVisibleLoader", false);
          reject(err);
        });
    });
  },
  [CHANGE_ORIGIN_PRICE](context, companyId) {
    ApiService.setHeader();
    context.commit("setIsVisibleLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v1/company/change/origin-price/${companyId}`)
        .then(({ data }) => {
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(err => {
          context.commit("setIsVisibleLoader", false);
          reject(err);
        });
    });
  },
};
const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_COMPANY](state, data) {
    state.company = data;
  },
  [PUSH_NEW_COMPANY](state, data) {
    state.companies.push(data);
  },
  [PUSH_UPDATE_COMPANY](state, data) {
    state.company = data;
  },
  [REMOVE_ONE_COMPANY](state, data) {
    state.companies.splice(state.companies.indexOf(data), 1);
  },
  [SET_LOADING](state, data) {
    state.isLoading = data;
  },
  [SET_EDITING](state, data) {
    state.isEditing = data;
  },
  [CLEAN_COMPANY](state) {
    Vue.set(state.company, {});
  },
  [SET_COMPANY_CONDITION](state, data) {
    state.companyCondition = data;
  },
  [SET_COMPANY_COMMISSION](state, data) {
    state.companyCommission = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
