const USER_KEY = "id_user";
const USER_EMAIL = "email_user";
const USER_ROL = "rol_user";
const USER_NAME = "name_user";
const CAMPUS_KEY = "id_campus";
const SCOPE = "scope";

export const getInfo = () => {
  return {
    id: window.localStorage.getItem(USER_KEY),
    email: window.localStorage.getItem(USER_EMAIL),
    roleId: window.localStorage.getItem(USER_ROL),
    name: window.localStorage.getItem(USER_NAME),
    campusId: window.localStorage.getItem(CAMPUS_KEY),
    scope: window.localStorage.getItem(SCOPE),
  };
};

export const saveData = data => {
  window.localStorage.setItem(USER_KEY, data.id);
  window.localStorage.setItem(USER_EMAIL, data.email);
  window.localStorage.setItem(USER_ROL, data.roleId);
  window.localStorage.setItem(USER_NAME, data.name);
  window.localStorage.setItem(CAMPUS_KEY, data.campusId);
  window.localStorage.setItem(SCOPE, data.scope);
};

export const destroyData = () => {
  window.localStorage.removeItem(USER_KEY);
  window.localStorage.removeItem(USER_EMAIL);
  window.localStorage.removeItem(USER_ROL);
  window.localStorage.removeItem(USER_NAME);
  window.localStorage.removeItem(CAMPUS_KEY);
  window.localStorage.removeItem(SCOPE);
};

export default { saveData, destroyData, getInfo };
