import ApiService from "@/core/services/api.service";

// ACTIONS
export const GET_COMMISSION_BY_COMPANY = "getCommissionByCompany";
export const STORE_COMMISSION = "storeCommission";

// MUTATION
export const SET_COMMISSION = "getCommissionByCompany";

const path = "api/v1/commission";

const state = {
  commission: {},
};

const getters = {
  Commission(state) {
    return state.commission;
  },
};
const actions = {
  [GET_COMMISSION_BY_COMPANY](context, data) {
    ApiService.setHeader();
    context.commit("setIsVisibleLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.query(`${path}/${data}`)
        .then(({ data }) => {
          context.commit("setIsVisibleLoader", false);
          context.commit(SET_COMMISSION, data);
          resolve(data);
        })
        .catch(err => {
          context.commit("setIsVisibleLoader", false);
          reject(err);
        });
    });
  },
  [STORE_COMMISSION](context, data) {
    ApiService.setHeader();
    context.commit("setIsVisibleLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.post(`${path}`, { ...data })
        .then(({ data }) => {
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(err => {
          context.commit("setIsVisibleLoader", false);
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_COMMISSION](state, data) {
    state.commission = data;
  },
};
export default {
  state,
  actions,
  mutations,
  getters,
};
