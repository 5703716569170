export default {
  START_DATE: "Start Date",
  PATTERN: "Pattern of dates",
  SPECIFIC: "Specific start dates",
  WEEK: "Week days",
  MONTH: "Month days",
  ENTER_DAY_OF_MONTH: "Enter the days of the month (e.g.: 1,7,14,21)",
  REPEAT_FRECUENCY: "Repeat frequency",
  ADD_DATE: "Add Date",
  DAYS_OF_MONTH: "Days of month",
};
