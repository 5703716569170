import Vue from "vue";
import ApiService from "@/core/services/api.service";

//Actions
export const GET_ROOMS = "getRooms";
export const GET_ROOM = "getRoom";
export const STORE_ROOM = "storeRoom";
export const UPDATE_ROOM = "updateRoom";
export const DELETE_ROOM = "deleteRoom";
export const LOAD_ADDITIONAL_SETTINGS = "loadAdditionalSettings";
export const FORCE_DELETE_ROOM = "forceDeleteRoom";

//Mutations
export const SET_ROOMS = "setRooms";
export const SET_ROOMS_UPDATED = "setRoomsUpdated";
export const SET_ROOM = "setRoom";
export const SET_ADDITIONAL_SETTINGS = "setAdditionalSettings";
export const ADD_ROOM = "addRoom";
export const REMOVE_ROOM = "removeRoom";
export const SET_EDIT = "setEdit";
export const SET_DUPLICATE = "setDuplicate";
export const CLEAN_ROOM = "cleanRoom";

export const PRODUCT_TYPE_ID = 2;

const state = {
  rooms: [],
  room: {},
  additionalSettings: {},
  isEdit: false,
  isDuplicate: false,
};

const getters = {
  rooms(state) {
    return state.rooms;
  },
  room(state) {
    return state.room;
  },
  isEditRoom(state) {
    return state.isEdit;
  },
  isDuplicateRoom(state) {
    return state.isDuplicate;
  },
  messageRoom(state) {
    return state.message;
  },
};

const actions = {
  [GET_ROOMS](context, formData) {
    context.commit("setIsVisibleLoader", true);
    let campusId;
    if (formData && formData.campusId) {
      campusId = formData.campusId;
    } else {
      const campus = context.getters.campus;
      campusId = campus.campusId;
    }

    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.query(`api/v1/product-room?campusId=${campusId}`, {})
        .then(({ data }) => {
          context.commit(SET_ROOMS, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [GET_ROOM](context, id) {
    context.commit("setIsVisibleLoader", true);
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.query(`api/v1/product-room/${id}`)
        .then(({ data }) => {
          context.commit(SET_ROOM, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [STORE_ROOM](context, dataForm) {
    ApiService.setHeader();
    const campus = context.getters.campus;
    const accommodations = context.getters.accommodations;
    let accommodation = null;
    let accommodationId = null;
    accommodation = accommodations?.filter(item => {
      return item.campusId === campus.campusId;
    });
    if (accommodation.length > 1) {
      accommodation = context.getters.accommodation;
      if (accommodation.accommodationId > 0) {
        accommodationId = accommodation.accommodationId;
      }
    } else if (accommodation.length === 1) {
      accommodationId = accommodation[0].accommodationId;
    }
    let productPricing = context.getters.productPricing;
    if (productPricing.length <= 0) {
      productPricing = undefined;
    }
    const dataSend = {
      ...dataForm,
      isReady: dataForm.isReady ? true : false,
      beds: context.getters.beds,
      maintenanceRate: context.getters.maintenanceRates,
      roomInfo: context.getters.roomInfo,
      apartment: context.getters.apartment,
      accommodationId: accommodationId,
      campusId: campus.campusId,
      productPricing,
    };
    dataSend.startDateTemplate.campusId = campus.campusId;
    dataSend.startDateTemplate.productTypeId = PRODUCT_TYPE_ID;
    context.commit("setIsVisibleLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.post("api/v1/product-room", dataSend)
        .then(({ data }) => {
          context.commit(SET_ROOM, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  async [UPDATE_ROOM](context, dataForm) {
    ApiService.setHeader();
    const campus = context.getters.campus;
    const accommodations = context.getters.accommodations;
    let accommodation = null;
    let accommodationId = null;
    accommodation = await accommodations?.filter(item => {
      return item.campusId === campus.campusId;
    });
    if (accommodation.length > 1) {
      accommodation = context.getters.accommodation;
      if (accommodation.accommodationId > 0) {
        accommodationId = accommodation.accommodationId;
      }
    } else if (accommodation.length === 1) {
      accommodationId = accommodation[0].accommodationId;
    }
    let productPricing = context.getters.productPricing;
    if (productPricing.length <= 0) {
      productPricing = undefined;
    }
    const dataSend = {
      ...dataForm,
      isReady: dataForm.isReady ? true : false,
      beds: context.getters.beds,
      maintenanceRate: context.getters.maintenanceRates,
      roomInfo: context.getters.roomInfo,
      apartment: context.getters.apartment,
      campusId: campus.campusId,
      accommodationId,
      productPricing,
    };
    dataSend.startDateTemplate.campusId = campus.campusId;
    dataSend.startDateTemplate.productTypeId = PRODUCT_TYPE_ID;
    return new Promise((resolve, reject) => {
      context.commit("setIsVisibleLoader", true);
      ApiService.put(`api/v1/product-room/${dataForm.productId}`, dataSend)
        .then(({ data }) => {
          context.commit(SET_ROOM, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [DELETE_ROOM](context, dataForm) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      context.commit("setIsVisibleLoader", true);
      ApiService.delete(`api/v1/product-room/${dataForm}`, dataForm)
        .then(({ data }) => {
          if (data.success) {
            context.commit(REMOVE_ROOM, dataForm);
          }

          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [FORCE_DELETE_ROOM](context, dataForm) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      context.commit("setIsVisibleLoader", true);
      ApiService.delete(
        `api/v1/product-room/delete/force/${dataForm}`,
        dataForm
      )
        .then(({ data }) => {
          context.commit(REMOVE_ROOM, { productId: dataForm });
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [LOAD_ADDITIONAL_SETTINGS](context, data) {
    context.commit(SET_ADDITIONAL_SETTINGS, data);
  },
};

const mutations = {
  [SET_ROOMS](state, data) {
    state.rooms = data;
  },
  [SET_ROOM](state, data) {
    state.room = data;
  },
  [REMOVE_ROOM](state, data) {
    const roomFinded = state.rooms.find(room => {
      if (room.productId === data.productId) {
        return true;
      }
    });
    const position = state.rooms.indexOf(roomFinded);
    state.rooms.splice(position, 1);
  },
  [SET_EDIT](state, data) {
    state.isEdit = data;
  },
  [SET_DUPLICATE](state, data) {
    state.isDuplicate = data;
  },
  [CLEAN_ROOM](state) {
    Vue.set(state.course, {});
  },
  [SET_ADDITIONAL_SETTINGS](state, data) {
    state.additionalSettings = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
