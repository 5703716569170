//Mutations
export const SET_CURRENCIES = "setCurrencies";

const state = {
  currencies: [],
  currency: {},
  isLoading: false,
  isEditing: false,
};

const getters = {
  Currencies(state) {
    state.Currencies.map(currency => {
      const { code, name, symbol } = currency;
      const auxSymbol = symbol ? `(${symbol})` : "";
      currency.newCode = `${code} - ${name} ${auxSymbol}`;
      return currency;
    });
    return state.Currencies;
  },
};

const actions = {};
const mutations = {
  [SET_CURRENCIES](state, data) {
    state.Currencies = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
