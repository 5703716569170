export default {
  NAME: "Name",
  TIMETABLES: "Timetables",
  REST_TIME_INCLUDED: "Break time included",
  COURSES_USING_TIMETABLE: "Courses using this Timetable",
  MONDAY: "Monday",
  TUESDAY: "Tuesday",
  WEDNESDAY: "Wednesday",
  THURSDAY: "Thursday",
  FRIDAY: "Friday",
  SATURDAY: "Saturday",
  SUNDAY: "Sunday",
  NEW_TIMETABLE: "New",
  EDIT_TIMETABLE: "Edit",
  COPY_MONDAY: "Copy Monday",
  COPY_PREVIOUS_DAY: "Copy Previous Day",
  ENTER_START_END_OF_DAY: "Enter the start and end time of the day.",
};
