//Mutations
export const SET_DIET_TYPES = "setDietTypes";

const state = {
  dietTypes: [],
  dietType: {},
  isLoading: false,
  isEditing: false,
};

const getters = {
  dietTypes(state) {
    return state.dietTypes;
  },
};

const actions = {};

const mutations = {
  [SET_DIET_TYPES](state, data) {
    state.dietTypes = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
