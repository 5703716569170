import Vue from "vue";
import ApiService from "@/core/services/api.service";

const ENDPOINT = "api/v1/my-messages";
//Actions
export const GET_ALL_MESSAGES = "getAllMessages";
export const GET_MESSAGE = "getAllReadMessages";
export const DELETE_MESSAGE = "deleteMessage";
export const REPLY_MESSAGE = "replyMessage";

//Mutations
export const SET_MESSAGES = "setMessages";
export const SET_MESSAGE = "setReadMessage";
export const CLEAN_MESSAGES = "cleanMessages";

const state = {
  allmessages: [],
  readmessage: [],
};

const getters = {
  allmessages(state) {
    return state.allmessages;
  },
  readmessage(state) {
    return state.readmessage;
  },
};

const actions = {
  [GET_ALL_MESSAGES](context) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      const campus = context.getters.campus;
      context.commit("setIsVisibleLoader", true);
      ApiService.query(`${ENDPOINT}?campusId=${campus.campusId}`)
        .then(({ data }) => {
          context.commit(SET_MESSAGES, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [GET_MESSAGE](context, config) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      const campus = context.getters.campus;
      context.commit("setIsVisibleLoader", true);
      ApiService.query(`${ENDPOINT}?campusId=${campus.campusId}&${config}`)
        .then(({ data }) => {
          context.commit(SET_MESSAGE, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [REPLY_MESSAGE](context, data) {
    ApiService.setHeader();
    context.commit("setIsVisibleLoader", true);
    return new Promise((resolve, reject) => {
      const campus = context.getters.campus;
      data.campusId = campus.campusId;
      ApiService.post(`${ENDPOINT}`, data)
        .then(({ response }) => {
          context.commit("setIsVisibleLoader", false);
          // context.commit(SET_USER_LIST, data.user);
          resolve(response);
        })
        .catch(err => {
          context.commit("setIsVisibleLoader", false);
          reject(err);
        });
    });
  },
  [DELETE_MESSAGE](context, messageId) {
    ApiService.setHeader();
    context.commit("setIsVisibleLoader", true);
    return new Promise((resolve, reject) => {
      // const campus = context.getters.campus;
      ApiService.delete(`${ENDPOINT}/${messageId}`)
        .then(({ data }) => {
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(err => {
          context.commit("setIsVisibleLoader", false);
          reject(err);
        });
    });
  },
};
const mutations = {
  [SET_MESSAGES](state, data) {
    state.allmessages = data;
  },
  [SET_MESSAGE](state, data) {
    state.readmessage = data;
  },
  [CLEAN_MESSAGES](state) {
    Vue.set(state.allmessages, {});
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
