import ApiService from "@/core/services/api.service";
//Actions
export const GET_FEE_TYPES = "getFeeTypes";
export const GET_FEE_TYPE = "getFeeType";

//Mutations
export const SET_FEE_TYPES = "setFeeTypes";
export const SET_FEE_TYPE = "setFeeType";
export const SET_LOADING = "setLoading";

const state = {
  feeTypes: [],
  feeType: {},
};

const getters = {
  feeTypes(state) {
    return state.feeTypes;
  },
  feeType(state) {
    return state.feeType;
  },
};

const actions = {
  [GET_FEE_TYPES](context) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.query("api/v1/fee-type", {})
        .then(({ data }) => {
          context.commit(SET_FEE_TYPES, data);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_LOADING, false);
        });
    });
  },

  [GET_FEE_TYPE](context, id) {
    context.commit(SET_LOADING, true);
    return new Promise(resolve => {
      ApiService.get(`api/v1/fee-type/${id}`)
        .then(({ data }) => {
          context.commit(SET_FEE_TYPE, data);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
};
const mutations = {
  [SET_FEE_TYPES](state, data) {
    state.feeTypes = data;
  },
  [SET_FEE_TYPE](state, data) {
    state.feeType = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
