import Vue from "vue";
import ApiService from "@/core/services/api.service";

const ENDPOINT = "api/v1/product-package";
//Actions
export const GET_ALL_PRODUCT_PACKAGES = "getAllProductPackages";
export const GET_PRODUCT_PACKAGE = "getProductPackage";
export const STORE_PRODUCT_PACKAGE = "storeProductPackage";
export const UPDATE_PRODUCT_PACKAGE = "updateProductPackage";
export const DELETE_PRODUCT_PACKAGE = "deleteProductPackage";
export const CANCEL_PRODUCT_PACKAGE = "cancelProductPackage";

//Mutations
export const SET_PRODUCT_PACKAGES = "setProductPackages";
export const SET_PRODUCT_PACKAGE = "setProductPackage";
export const PUSH_NEW_PRODUCT_PACKAGE = "pushProductPackage";
export const PUSH_UPDATE_PRODUCT_PACKAGE = "pushProductPackage";
export const REMOVE_ONE_PRODUCT_PACKAGE = "pushProductPackage";
export const SET_LOADING = "setLoading";
export const SET_EDITING = "setEditing";
export const CLEAN_PRODUCT_PACKAGE = "cleanProductPackage";

const state = {
  productPackages: [],
  productPackage: {},
  isLoading: false,
  isEditing: false,
};

const getters = {
  productPackages(state) {
    return state.productPackages;
  },
  productPackage(state) {
    return state.productPackage;
  },
  isLoadingProductPackage(state) {
    return state.isLoading;
  },
  isEditingProductPackage(state) {
    return state.isEditing;
  },
};

const actions = {
  [GET_ALL_PRODUCT_PACKAGES](context, config) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      const campus = context.getters.campus;
      context.commit("setIsVisibleLoader", true);
      ApiService.query(`${ENDPOINT}?campusId=${campus.campusId}`, config)
        .then(({ data }) => {
          context.commit(SET_PRODUCT_PACKAGES, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },

  [GET_PRODUCT_PACKAGE](context, id) {
    ApiService.setHeader();
    context.commit("setIsVisibleLoader", true);
    return new Promise(resolve => {
      ApiService.query(`${ENDPOINT}/${id}`)
        .then(({ data }) => {
          context.commit(SET_PRODUCT_PACKAGE, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(() => {
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [STORE_PRODUCT_PACKAGE](context, data) {
    ApiService.setHeader();
    context.commit("setIsVisibleLoader", true);
    return new Promise(resolve => {
      ApiService.post(ENDPOINT, data)
        .then(({ data }) => {
          context.commit(PUSH_NEW_PRODUCT_PACKAGE, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(() => {
          context.commit("setIsVisibleLoader", false);
        });
    });
  },

  [UPDATE_PRODUCT_PACKAGE](context, data) {
    ApiService.setHeader();
    context.commit("setIsVisibleLoader", true);
    return new Promise(resolve => {
      ApiService.put(`${ENDPOINT}/${data.id}`, {
        ...data,
        product: {
          ...data.product,
          productId: data.id,
        },
      })
        .then(({ data }) => {
          context.commit(PUSH_UPDATE_PRODUCT_PACKAGE, data);
          context.commit(SET_PRODUCT_PACKAGE, data);
          context.commit("setIsVisibleLoader", false);
          context.commit(SET_EDITING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit("setIsVisibleLoader", false);
        });
    });
  },

  [DELETE_PRODUCT_PACKAGE](context, id) {
    ApiService.setHeader();
    context.commit("setIsVisibleLoader", true);
    return new Promise(resolve => {
      ApiService.delete(`${ENDPOINT}/${id}`)
        .then(({ data }) => {
          if (data) {
            context.commit(REMOVE_ONE_PRODUCT_PACKAGE, data);
            context.commit(CLEAN_PRODUCT_PACKAGE);
          }
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(() => {
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
};
const mutations = {
  [SET_PRODUCT_PACKAGES](state, data) {
    state.productPackages = data;
  },
  [SET_PRODUCT_PACKAGE](state, data) {
    state.productPackage = data;
  },
  [PUSH_NEW_PRODUCT_PACKAGE](state, data) {
    state.productPackages.push(data);
  },
  [PUSH_UPDATE_PRODUCT_PACKAGE](state, data) {
    const position = state.productPackages.indexOf(state.productPackage);
    Vue.set(state.productPackages, position, data);
  },
  [REMOVE_ONE_PRODUCT_PACKAGE](state, data) {
    state.productPackages.splice(state.productPackages.indexOf(data), 1);
  },
  [SET_LOADING](state, data) {
    state.isLoading = data;
  },
  [SET_EDITING](state, data) {
    state.isEditing = data;
  },
  [CLEAN_PRODUCT_PACKAGE](state) {
    Vue.set(state.productPackage, {});
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
