//Mutations
export const SET_ENGLISH_LEVEL = "setEnglishLevel";

const state = {
  englishLevels: [],
};

const getters = {
  englishLevels(state) {
    return state.englishLevels;
  },
};

const mutations = {
  [SET_ENGLISH_LEVEL](state, data) {
    state.englishLevels = data;
  },
};

export default {
  state,
  mutations,
  getters,
};
