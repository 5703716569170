// USA
export default {
  SEARCH: "Search",
  SELECT_ALL: "Select All",
  VISIBILITY: "Publish",
  TRANSLATIONS: "Translations",
  HIDE_ADDITIONAL_SETTINGS: "Hide additional settings",
  SHOW_ADDITIONAL_SETTINGS: "Show additional settings",
  SAVE: "Save",
  CONTINUE: "Continue",
  CANCEL: "Cancel",
  NEW: "New",
  EDIT: "Edit",
  DELETE: "Delete",
  NEXT: "Next",
  ADD: "Add",
  SELECT: "select",
  ADD_NEW: "Add new",
  DUPLICATE: "Duplicate",
  COPY_DUPLICATE: "(Copy)",
  DAYS: "Day",
  WEEKS: "Week",
  MONTHS: "Month",
  YEARS: "Year",
  LESSONS: "Lesson",
  FIELD_ERROR: "Required field.",
  UPLOAD: "Upload",
  UPLOAD_PHOTO: "Upload Photo",
  UPLOAD_LOGO: "Upload Logo",
  ACCEPTED_FORMATS: "Accepted formats:",
  FORMATS: "JPEG, JPG, GIF, PNG. Under 2mb",
  REMOVE_PHOTO: "Remove current picture",
  PASSWORD_NOT_EQUALS: "Passwords do not match, try again!",
  MAX_CHARACTERS: "Max {num} characters",
  MIN_CHARACTERS: "Min 4 characters",
  VALIDATE_FIELDS: "Please verify the fields",
  PLEASE_VERIFY_FIELDS: "Please verify the fields",
  DECIMAL_FIELDS: "Please do not enter decimal numbers",
  NUMBER_FIELDS: "Please enter a valid number",
  NEGATIVE_FIELDS: "Please do not enter negative numbers",
  NUMBER_GREATER: "the number must be greater",
  NUMBER_MINOR: "the number must be less",
  MORE_100_FIELDS: "Please do not enter a number greater than 100",
  SAVED_SUCCESS: "Success. Saved",
  DUPLICATED_SUCCESS: "Success. Duplicated",
  DELETED_SUCCESS: "Success. Deleted",
  INFO: {
    VISIBILITY: "Publish",
  },
  AMOUNT: "Amount",
  TYPE: "Type",
  DATE: "Date",
  NOT_CAMPUS: "there are no campuses",
  CREATE_CAMPUS: "Create Campus",
  NO_NAME: "(No name)",
  MARKETS_COUNTRY_SELECTOR: {
    LABEL: "Choose market",
    COUNTRIES_LABEL: "Countries",
    MARKETS_LABEL: "Markets",
    OPTIONS: {
      ELIGIBLE_ALL_COUNTRIES: "All Countries",
      SELECT_BY_COUNTRY: "Select by Country",
      SELECT_BY_MARKET: "Select by Market",
    },
    SAVE_LABEL: "Save",
  },
  SHOW_HELP: "Show Help",
  HIDE_HELP: "Hide Help",
  DURATION: "Duration",
  DURATION_TYPE: "Duration Type",
  DESCRIPTION: "Description",
  BONUS: "Bonus",
  COMMISSION: "Commission",
  FROM: "From",
  TO: "To",
  NEVER_EXPIRES: "Never expires",
  ENTER_DATE: "Enter Date",
  SAVE_AND_NEW: "Save And Create New",
  TRANSLATION_ADD: "Add translation",
  TRANSLATION_NEW: "New translation",
  TRANSLATION_LANGUAGE: "Language",
  NAME: "Name",
  SEND: "Send",
  MAIN_TITLE_1: "You are in the Campus",
  MAIN_TITLE_2: "You are in the Lodging",
  THANKS: "Thank You",
  MESSAGE_VISIBLE_WARNING: "Post must be reviewed by Ynsitu before publishing.",
  MESSAGE_VISIBLE_PUBLISHED:
    "The product is currently published, if it is updated it will be unpublished.",
  INVALID_TOKEN: "This Token is not valid!",
  BAD_BUT_NOT_FATAL: "Bad, but not fatal",
  CLOSE: "Close",
  DISCONNECT: "Disconnect",
  ADDRESS_ERROR: "The address entered is not valid",
  CITY_ERROR: "The city entered is not valid",
  COMPLETE_INFORMATION: "Please complete the information",
  RESTRICTED_AREA: "Restricted Area",
  RESTRICTED_AREA_TEXT: "You don't have permissions to access to this content.",
  MENU: {
    HOME: "Home",
    CAMPUS: "Campus",
    LODGING: "Lodging",
    LODGINGS: "Lodgings",
    APPLICATIONS: "Applications",
    PRODUCTS: "Products",
    COURSES: "Courses",
    ROOMS: "Rooms",
    SERVICES: "Services",
    PROMOTIONS: "Promotions",
    PACKAGES: "Packages",
    CONFIGURE: "Configure",
    PRICING_TEMPLATES: "Pricing Templates",
    FEES: "Fees",
    TIMETABLES: "Timetables",
    CALENDAR: "Calendar",
    MARKETS: "Markets",
    REVIEWS: "Reviews",
    MESSAGESCENTER: "Messages Center",
    BASIC_TEMPLATE: "Basic Template (only development)",
    COMPANIES: "Companies",
    HOMETUITION_PROFILE: "Teacher Profile",
    COUPONS: "Coupons",
    PRICING: "Pricing",
    CALENDARS: "Calendars",
    HELP: "Help",
  },
  IMAGE_HIGHLIGHT: "Image highlighted",
  HIGHLIGHT_IMAGE: "Highlight image",
  SAVE_FILE: "The file is uploaded",
  NO_DATA: "No records",
  ACTION: "Action",
  REPORT_ISSUE: "Report issue",
  PUBLISH: "Publish",
  ACCEPT: "Accept",
  PERCENTAGE: "The value entered must be between 1 and 100",
};
