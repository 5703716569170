//Mutations
export const SET_ACCOMMODATION_TYPES = "setAccommodationTypes";

const state = {
  accommodationTypes: [],
  accommodationType: {},
  isLoading: false,
  isEditing: false,
};

const getters = {
  AccommodationTypes(state) {
    return state.AccommodationTypes;
  },
};

const actions = {};
const mutations = {
  [SET_ACCOMMODATION_TYPES](state, data) {
    state.AccommodationTypes = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
