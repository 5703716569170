import Vue from "vue";
import ApiService from "@/core/services/api.service";

//Actions
export const GET_ADDONS = "getAddons";
export const GET_ADDON = "getAddon";
export const STORE_ADDON = "storeAddon";
export const UPDATE_ADDON = "updateAddon";
export const DELETE_ADDON = "deleteAddon";
export const FORCE_DELETE_SERVICE = "forceDeleteService";

//Mutations
export const SET_ADDONS = "setAddons";
export const SET_ADDON = "setAddon";
export const REMOVE_ADDON = "removeAddon";
export const CLEAN_ADDON = "cleanAddon";

const state = {
  addons: [],
  addon: {},
};

const getters = {
  addons(state) {
    return state.addons;
  },
  addon(state) {
    return state.addon;
  },
  isLoadingAddon(state) {
    return state.isLoading;
  },
};

const actions = {
  [GET_ADDONS](context, formData) {
    context.commit("setIsVisibleLoader", true);
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      let campusId;
      if (formData && formData.campusId) {
        campusId = formData.campusId;
      } else {
        campusId = context.getters.campus.campusId;
      }

      ApiService.query(`api/v1/product-service?campusId=${campusId}`, {})
        .then(({ data }) => {
          context.commit(SET_ADDONS, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [GET_ADDON](context, id) {
    ApiService.setHeader();
    context.commit("setIsVisibleLoader", true);
    return new Promise(resolve => {
      ApiService.get(`api/v1/product-service/${id}`)
        .then(({ data }) => {
          context.commit(SET_ADDON, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(response => {
          context.commit("setMessage", {
            status: 500,
            message: response.message,
          });
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [STORE_ADDON](context, dataForm) {
    ApiService.setHeader();
    const campus = context.getters.campus;
    const dataSend = {
      ...dataForm,
      isVisible: context.getters.visibility,
      campusId: campus.campusId,
      offeringTypeId: 3, // Is Addon
    };
    context.commit("setIsVisibleLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.post("api/v1/product-service", dataSend)
        .then(({ data }) => {
          context.commit("setIsVisibleLoader", false);
          context.commit("setMessage", {
            status: 200,
            message: "Addon saved successfully.",
          });
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setMessage", {
            status: 500,
            message: "Error during addon save.",
          });
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [UPDATE_ADDON](context, dataForm) {
    ApiService.setHeader();
    const campus = context.getters.campus;
    const dataSend = {
      ...dataForm,
      campusId: campus.campusId,
    };
    context.commit("setIsVisibleLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.put(`api/v1/product-service/${dataForm.productId}`, dataSend)
        .then(({ data }) => {
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setMessage", {
            status: 500,
            message: "Error during addon update.",
          });
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [DELETE_ADDON](context, dataForm) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      context.commit("setIsVisibleLoader", true);
      ApiService.delete(`api/v1/product-service/${dataForm}`, dataForm)
        .then(({ data }) => {
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [FORCE_DELETE_SERVICE](context, dataForm) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      context.commit("setIsVisibleLoader", true);
      ApiService.delete(
        `api/v1/product-service/delete/force/${dataForm}`,
        dataForm
      )
        .then(({ data }) => {
          resolve(data);
          context.commit("setIsVisibleLoader", false);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
};

const mutations = {
  [SET_ADDONS](state, data) {
    state.addons = data;
  },
  [SET_ADDON](state, data) {
    state.addon = data;
  },
  [REMOVE_ADDON](state, data) {
    const addonsFinded = state.addons.find(addon => {
      if (addon.productId === data.productId) {
        return true;
      }
    });
    const position = state.addons.indexOf(addonsFinded);
    state.addons.splice(position, 1);
  },
  [CLEAN_ADDON](state) {
    Vue.set(state.addon, {});
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
