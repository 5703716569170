//Mutations
export const SET_MEAL_TYPES = "setMealTypes";

const state = {
  mealTypes: [],
};

const getters = {
  mealTypes(state) {
    return state.mealTypes;
  },
};

const mutations = {
  [SET_MEAL_TYPES](state, data) {
    state.mealTypes = data;
  },
};

export default {
  state,
  mutations,
  getters,
};
