import axios from "axios";
import JwtService from "@/core/services/jwt.service";
import errorHandler from "@/core/services/utils/errorHandler";

let instance;

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    instance = axios.create({
      baseURL: process.env.VUE_APP_API_PATH,
    });
    instance.interceptors.response.use(
      response => response,
      error => errorHandler(error)
    );
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    instance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return instance.get(resource, params).catch(error => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    if (slug !== "") {
      slug = `/${slug}`;
    }
    return instance.get(`${resource}${slug}`);
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return instance.post(`${resource}`, params);
  },

  /**
   * Set the POST file HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  postFile(resource, params) {
    return instance.post(`${resource}`, params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return instance.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return instance.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return instance.delete(resource);
  },
};

export default ApiService;
