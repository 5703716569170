export default {
  TITLE: "We would be very grateful if you value your experience at {campus}",
  LABEL:
    "Copy and paste the email list of people that have been studying in your Campus",
  EMAIL: "Email",
  NAME: "Name",
  REVIEW: "Review",
  CALIFICATION: "Rating",
  INFO: "The emails entered must be separated by ',' or 'line breaks'",
  BOTTOM_MESSAGE:
    "Your feedback will be published in the profile {campus} at www.ynsitu.com",
  COPY_CLIPBOARD: "Copy to clipboard",
  DESCRIPTION1:
    "80% of potential customers read the comments and 70% of the buyers are more likely to buy if this type of feedback is offered.",
  DESCRIPTION2:
    "Possibly you already know that and that is why YNSITU offers you until you start to have bookings and comments on our platform, the possibility of requesting feedback from former students so that they can appear in all your courses, adding value and boosting your sales.",
  DESCRIPTION3:
    "To do this, you just have to send a personalized link that we provide you below, to your students in an email, kindly asking to rate their experience at your school.",
  DESCRIPTION4: "This little effort will surely help to boost your sales.",
  URL: "URL",
  URL_SUCCESS: "The URL has been successfully copied to the clipboard",
  TABLE: {
    TITLE: "Reviews",
    TAB0: "Rejected",
    TAB1: "Approved",
    TAB2: "Pending",
    TAB3: "Pending Verify",
    REVIEWS_HEADER: {
      ITEM1: "Id",
      ITEM2: "Campus",
      ITEM3: "Student",
      ITEM4: "Calification",
      ITEM5: "Review",
    },
    PRODUCTS_HEADER: {
      ITEM1: "Product",
      ITEM2: "Service Status",
      ITEM3: "Provider",
      ITEM4: "Service Date",
      ITEM5: "Updated Date",
    },
  },
};
