import ApiService from "@/core/services/api.service";

const ENDPOINT = "api/v1/product";

//Actions
export const GET_PRODUCTS = "getProducts";
export const PUBLISH_PRODUCT = "publishProduct";
export const NOTIFY_PRODUCT = "notifyProduct";

//Mutations
export const SET_PRODUCTS = "setProducts";
export const REMOVE_PRODUCT = "removeProduct";

const state = {
  products: [],
};

const getters = {
  products(state) {
    return state.products;
  },
};

const actions = {
  async [GET_PRODUCTS](context, validation = {}) {
    ApiService.setHeader();
    return await new Promise((resolve, reject) => {
      ApiService.post(`${ENDPOINT}/list/all`, { where: validation })
        .then(({ data }) => {
          context.commit(SET_PRODUCTS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  async [PUBLISH_PRODUCT](context, productId) {
    ApiService.setHeader();
    return await new Promise((resolve, reject) => {
      ApiService.post(`${ENDPOINT}/${productId}/publish`)
        .then(({ data }) => {
          context.commit(REMOVE_PRODUCT, productId);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  async [NOTIFY_PRODUCT](context, dataForm) {
    ApiService.setHeader();
    return await new Promise((resolve, reject) => {
      ApiService.post(`${ENDPOINT}/notify/error`, dataForm)
        .then(({ data }) => {
          context.commit(REMOVE_PRODUCT, dataForm.product.productId);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};

const mutations = {
  [SET_PRODUCTS](state, data) {
    state.products = data;
  },
  [REMOVE_PRODUCT](state, data) {
    const product = state.products.filter(item => {
      return item.productId === data;
    });
    const indexList = state.products.indexOf(product[0]);
    state.products.splice(indexList, 1);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
