import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import productPricing from "./configure/product_pricing.module";
import course from "./productsAddons/course.module";
import addon from "./productsAddons/addon.module";
import room from "./productsAddons/room.module";
import visibility from "./productsAddons/visibility.module";
import bed from "./productsAddons/bed.module";
import roomInfo from "./productsAddons/room_info.module";
import apartment from "./productsAddons/apartment.module";
import maintenanceRate from "./productsAddons/maintenance_rate.module";
import productPackage from "./packages/product_package.module";
import feeForm from "./configure/fee.module";
import product from "./general/product.module";
//General
import loader from "./general/loader.module";
import message from "./general/messages.module";
import notification from "./general/notification.module";

// General - Persisted
import auth from "./auth.module";
import categoryLanguage from "./general/category_language.module";
import category from "./general/category.module";
import language from "./general/language.module";
import subcategory from "./general/subcategory.module";
import market from "./market/market.module";
import durationType from "./general/duration_type.module";
import amenityType from "./general/amenity_type.module";
import bathroomType from "./general/bathroom_type.module";
import mealType from "./general/meal_type.module";
import promotion from "./general/promotion.module";
import promotionType from "./general/promotion_type.module";
import roomType from "./general/room_type.module";
import campusType from "./general/campus_type.module";
import country from "./general/country.module";
import campus from "./general/campus.module";
import companyType from "./general/company_type.module";
import startDate from "./general/start_date.module";
import timeTable from "./general/time_table.module";
import accommodationType from "./general/accommodation_type.module";
import distanceType from "./general/distance_type.module";
import transportType from "./general/transport_type.module";
import apartmentType from "./general/apartment_type.module";
import serviceType from "./general/service_type.module";
import currency from "./general/currency.module";
import dietType from "./general/diet_type.module";
import company from "./general/company.module";
import accommodation from "./general/accommodation.module";
import productStartDateTemplate from "./general/product_start_date_template.module";
import englishLevel from "./general/english_level.module";
import reviews from "./reviews/reviews.module";
import logs from "./logs/logs.module";
import messages from "./messages/messages.module";
import applications from "./application/application.module";
import coupons from "./general/coupons.module";
import commission from "./general/commission.module";
import feeType from "./general/fee_type.module";
import campusPage from "./general/campus_page.module";

// GALLERY
import gallery from "./gallery.module";

Vue.use(Vuex);

const dataPersisted = createPersistedState({
  paths: [
    "auth",
    "categoryLanguage",
    "category",
    "language",
    "subcategory",
    "market",
    "durationType",
    "amenityType",
    "bathroomType",
    "mealType",
    "promotion",
    "promotionType",
    "roomType",
    "campusType",
    "country",
    "campus",
    "companyType",
    "pricingTemplate",
    "startDate",
    "timeTable",
    "company",
    "accommodationType",
    "distanceType",
    "transportType",
    "apartmentType",
    "serviceType",
    "currency",
    "dietType",
    "productStartDateTemplate",
    "productPricing",
    "productPackage",
    "englishLevel",
    "notification",
    "reviews",
    "logs",
    "messages",
    "applications",
    "coupons",
    "feeType",
  ],
});

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    productPricing,
    course,
    accommodation,
    addon,
    categoryLanguage,
    category,
    language,
    subcategory,
    market,
    durationType,
    amenityType,
    bathroomType,
    mealType,
    promotion,
    promotionType,
    roomType,
    campusType,
    country,
    loader,
    campus,
    companyType,
    startDate,
    timeTable,
    company,
    room,
    visibility,
    gallery,
    accommodationType,
    distanceType,
    transportType,
    apartmentType,
    serviceType,
    currency,
    dietType,
    roomInfo,
    bed,
    maintenanceRate,
    apartment,
    productStartDateTemplate,
    message,
    productPackage,
    feeForm,
    englishLevel,
    notification,
    reviews,
    logs,
    messages,
    applications,
    product,
    coupons,
    commission,
    feeType,
    campusPage,
  },
  plugins: [dataPersisted],
});
