//Mutations
export const SET_COMPANY_TYPES = "setCompanyTypes";

const state = {
  companyTypes: [],
};

const getters = {
  Campuscompanytypes(state) {
    return state.companyTypes;
  },
};

const mutations = {
  [SET_COMPANY_TYPES](state, data) {
    state.companyTypes = data;
  },
};

export default {
  state,
  mutations,
  getters,
};
