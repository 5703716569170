import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faAngleDown,
  faWrench,
  faBox,
  faBook,
  faBookmark,
  faBoxes,
  faPlus,
  faDollarSign,
  faTrashAlt,
  faEdit,
  faInfoCircle,
  faEnvelope,
  faAngleDoubleDown,
  faUser,
  faBuilding,
  faUsers,
  faLaptopHouse,
  faBed,
  faArrowAltCircleDown,
  faHome,
  faUserCog,
  faCheckCircle,
  faCommentDots,
  faAngleRight,
  faUpload,
  faEye,
  faCar,
  faPaperPlane,
  faBell,
  faStar,
  faTrash,
  faTimes,
  faTicketAlt,
  faCommentAlt,
  faChevronRight,
  faChevronDown,
  faSuitcaseRolling,
  faParking,
  faMoneyCheckAlt,
  faCalendarAlt,
  faExchangeAlt,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

library.add(faAngleDown);
library.add(faWrench);
library.add(faBox);
library.add(faBook);
library.add(faBookmark);
library.add(faBoxes);
library.add(faPlus);
library.add(faDollarSign);
library.add(faTrashAlt);
library.add(faEdit);
library.add(faInfoCircle);
library.add(faEnvelope);
library.add(faAngleDoubleDown);
library.add(faUser);
library.add(faBuilding);
library.add(faUsers);
library.add(faLaptopHouse);
library.add(faBed);
library.add(faArrowAltCircleDown);
library.add(faHome);
library.add(faUserCog);
library.add(faCheckCircle);
library.add(faCommentDots);
library.add(faAngleRight);
library.add(faUpload);
library.add(faEye);
library.add(faCar);
library.add(faPaperPlane);
library.add(faBell);
library.add(faStar);
library.add(faTrash);
library.add(faTimes);
library.add(faTicketAlt);
library.add(faCommentAlt);
library.add(faChevronRight);
library.add(faChevronDown);
library.add(faSuitcaseRolling);
library.add(faParking);
library.add(faMoneyCheckAlt);
library.add(faCalendarAlt);
library.add(faExchangeAlt);
library.add(faQuestionCircle);
Vue.component("font-awesome-icon", FontAwesomeIcon);
