const ID_TOKEN_KEY = "id_token";
const ID_REFRESH_KEY = "id_refresh";
const ID_EXPIRES_KEY = "expires_token";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token.access_token);
  window.localStorage.setItem(ID_REFRESH_KEY, token.refresh_token);
  window.localStorage.setItem(ID_EXPIRES_KEY, token.expires_in);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(ID_REFRESH_KEY);
  window.localStorage.removeItem(ID_EXPIRES_KEY);
};

export default { getToken, saveToken, destroyToken };
