//Mutations
export const SET_IS_VISIBLE_LOADER = "setIsVisibleLoader";

const state = {
  isReadyLoader: false,
};

const getters = {
  isReadyLoader(state) {
    return state.isReadyLoader;
  },
};

const mutations = {
  [SET_IS_VISIBLE_LOADER](state, data) {
    if (data) {
      this.dispatch("addBodyClassName", "page-loading");
    } else {
      this.dispatch("removeBodyClassName", "page-loading");
    }

    if (state.isReadyLoader !== data) {
      state.isReadyLoader = data;
    }
  },
};

export default {
  state,
  mutations,
  getters,
};
