//Mutations
export const SET_BATHROOM_TYPES = "setBathroomTypes";

const state = {
  bathroomTypes: [],
};

const getters = {
  Bathroomtypes(state) {
    return state.bathroomTypes;
  },
};

const mutations = {
  [SET_BATHROOM_TYPES](state, data) {
    state.bathroomTypes = data;
  },
};

export default {
  state,
  mutations,
  getters,
};
