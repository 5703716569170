import ApiService from "@/core/services/api.service";

const path_log = "api/v1/logs";
//Actions
export const GET_ALL_LOGS_CUSTOM = "getAllLogsCustom";

const state = {
  logs: [],
};

const getters = {
  logs(state) {
    state.logs.map(item => {
      if (item.log) {
        item.logTable = String(item.log).slice(0, 20) + "...";
      }
      return item;
    });
    return state.logs;
  },
};

const actions = {
  [GET_ALL_LOGS_CUSTOM](context, dataForm) {
    ApiService.setHeader();
    context.commit("setIsVisibleLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.post(`${path_log}/list/custom`, dataForm)
        .then(({ data }) => {
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(response => {
          context.commit("setIsVisibleLoader", false);
          reject(response);
        });
    });
  },
};
export default {
  state,
  actions,
  getters,
};
