export default {
  TITLE: "Markets",
  TITLE1: "Africa",
  TITLE2: "Asia",
  TITLE3: "Europe",
  TITLE4: "North America",
  TITLE5: "Oceania",
  TITLE6: "South America",
  TITLE7: "Countries",
  TITLE8: "Market Information",
  TITLE9: "Group countries to assign specific prices or promotions",
  NAME: "Name",
};
