export default {
  TITLE: "Fee",
  ALTERNATIVE_TITLE: "Fees",
  NEW: "New",
  EDIT: "Edit",
  DELETE: "Delete",
  UPDATE: "Update",
  SAVE: "Save",
  CLEAR: "Clear",
  PRICINGS: "Pricing",
  PRICING: "Pricing",
  REGISTRATION_FEE: "Registration fee",
  EXAM_FEE: "Exam fee",
  COURSE_MATERIAL: "Course material",
  VISADO: "Visa",
  OTHER: "Other",
  ACCOMMODATION_FINDING_FEE: "Accommodation placement fee",
  COURSES: "Courses",
  LODGING: "Lodging",
  SERVICES: "Services",
  PACKAGES: "Packages",
  NAME: "Name",
  FEE_TYPE: "Fee type",
  APPLY_ONLY_ONE_TIME: "Apply only one time",
  PLEASE_APPLICABLE_TO: "Please select applicable to",
  APPLICABLE_TO: "Apply to",
  DESCRIPTION: "Description",
  AMOUNT: "Amount",
  REPEAT: "Apply more that once",
  QUANTITY: "Quantity",
  TYPE_DURATION: "Apply every",
  DAY: "Day",
  WEEK: "Week",
  MONTH: "Month",
  TRIMESTER: "Term",
  SEMESTER: "Semester",
  YEAR: "Year",
  TYPE: "Type",
  UPDATE_AT: "Update at",
  GENERAL_INFORMATION: "Fee information",
  DESCRIPTION_GENERAL_INFORMATION:
    "Only the items that are a mandatory part of the price are considered rates. Any optional fees must be entered as a service.",
  IS_MINOR_AGE: "Is minor age",
  FEE_ALTERNATIVE_TO: "Alternative to",
  MAX_REPEAT: "Max repeat",
  MAXIMUM_TYPE_DURATION: "Maximum {typeDuration} to repeat",
  MAXIMUM_TO_REPEAT: "Maximum to repeat",
};
