import Vue from "vue";
import ApiService from "@/core/services/api.service";
import lodash from "lodash";

const api_path = "/api/v1/application";
const api_path_admin = "/api/v1/superadmin/application";
const PAYED = 2;
//Actions
export const GET_ALL_APPLICATIONS = "getAllApplications";
export const GET_APPLICATION = "getApplication";
export const UPDATE_APPLICATION = "updateApplication";
export const GET_MESSAGES = "getMessages";
export const STORE_MESSAGES = "storeMessages";
export const UPDATE_STATUS = "updateStatus";
export const RESET_MESSAGES = "resetMessages";
export const UPLOAD_FILE = "uploadFile";
export const GET_ALL_APPLICATIONS_ADMIN = "getAllApplicationsAdmin";
export const UPDATE_STATUS_PAYMENT_ADMIN = "getUpdateStatusPayment";

//Mutations
export const SET_APPLICATIONS = "setApplications";
export const SET_APPLICATION = "setApplication";
export const PUSH_UPDATE_APPLICATION = "pushUpdateApplication";
export const SET_MESSAGES = "setMessages";
export const PUSH_MESSAGES = "pushMessages";
export const UPDATED_FILE = "updateFile";
export const SET_STATUS_PAYMENT_ADMIN = "setUpdateStatusPayment";

const state = {
  applications: [],
  application: {},
  messages: [],
  files: [],
};

const getters = {
  applications(state) {
    return state.applications;
  },
  application(state) {
    return state.application;
  },
  messages(state) {
    return lodash.groupBy(state.messages, "date");
  },
};

const actions = {
  [GET_ALL_APPLICATIONS](context, query) {
    context.commit("setIsVisibleLoader", true);
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`${api_path}?${query}`)
        .then(({ data }) => {
          context.commit(SET_APPLICATIONS, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },

  [GET_APPLICATION](context, id) {
    context.commit("setIsVisibleLoader", true);
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`${api_path}/${id}`)
        .then(({ data }) => {
          context.commit(SET_APPLICATION, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },

  [UPDATE_APPLICATION](context, data) {
    ApiService.setHeader();
    context.commit("setIsVisibleLoader", true);
    return new Promise(resolve => {
      ApiService.put(`${api_path}/${data.applicationId}`, data)
        .then(({ data }) => {
          context.commit(PUSH_UPDATE_APPLICATION, data);
          resolve(data);
        })
        .catch(() => {
          context.commit("setIsVisibleLoader", false);
        });
    });
  },

  [GET_MESSAGES](context, query) {
    context.commit("setIsVisibleLoader", true);
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`${api_path}-messages?${query}`)
        .then(({ data }) => {
          context.commit(SET_MESSAGES, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },

  [STORE_MESSAGES](context, json) {
    ApiService.setHeader();
    return new Promise(resolve => {
      ApiService.post(`${api_path}-messages`, json).then(({ data }) => {
        context.commit(PUSH_MESSAGES, data);
        resolve(data);
      });
    });
  },

  [UPDATE_STATUS](context, json) {
    context.commit("setIsVisibleLoader", true);
    ApiService.setHeader();
    return new Promise(resolve => {
      ApiService.put(`${api_path}/state/${json.id}`, json).then(data => {
        context.commit("setIsVisibleLoader", false);
        resolve(data);
      });
    });
  },

  [RESET_MESSAGES](context) {
    context.commit(SET_MESSAGES, []);
  },

  [UPLOAD_FILE](context, formData) {
    ApiService.setHeader();

    const { applicationId } = context.state.application;

    return new Promise((resolve, reject) => {
      ApiService.postFile(
        `${api_path}/upload-documents/${applicationId}`,
        formData
      )
        .then(({ data }) => {
          resolve(data);
          context.commit(UPDATED_FILE, data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  [GET_ALL_APPLICATIONS_ADMIN](context, query) {
    context.commit("setIsVisibleLoader", true);
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`${api_path_admin}?${query}`)
        .then(({ data }) => {
          context.commit(SET_APPLICATIONS, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },

  [UPDATE_STATUS_PAYMENT_ADMIN](context, applicationPaymentId) {
    context.commit("setIsVisibleLoader", true);
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      const data = {
        status: PAYED,
      };
      ApiService.put(
        `/api/v1/superadmin/confirm-payment/${applicationPaymentId}`,
        data
      )
        .then(({ data }) => {
          context.commit(SET_STATUS_PAYMENT_ADMIN, applicationPaymentId);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
};

const mutations = {
  [SET_APPLICATIONS](state, data) {
    state.applications = data;
  },
  [SET_APPLICATION](state, data) {
    state.application = Object.assign({}, state.application, data);
  },
  [PUSH_UPDATE_APPLICATION](state, data) {
    const position = state.application.indexOf(state.application);
    Vue.set(state.application, position, data);
  },
  [SET_MESSAGES](state, data) {
    state.messages = data;
  },
  [PUSH_MESSAGES](state, data) {
    state.messages.push(data);
  },
  [UPDATED_FILE](state, data) {
    state.application.application_files = [];
    state.application.application_files.push(data);
  },
  [SET_STATUS_PAYMENT_ADMIN](state, applicationPaymentId) {
    state.application.ApplicationPayments.forEach((payment, index) => {
      if (payment.applicationPaymentId === applicationPaymentId) {
        state.application.ApplicationPayments[index].status = PAYED;
      }
    });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
