export default {
  TITLE_COMPANY: "Companies",
  TITLE_PRODUCT: "Products",
  TITLE_REVIEW: "Reviews",
  TITLE_FEATURE_PRODUCT: "Feature product",
  COMPANY_NAME: "Company Name",
  COMPANY_TYPE: "Company Type",
  LEGAL_COMPANY_TYPE: "Legal Company Type",
  ACTIONS: "Actions",
  SEARCH: "Search",
  SEND_EMAIL: "Send email to access of wizard",
  DISABLE: "Disable this company",
  ENABLE: "Reactive this company",
  LOGING: "Login in this company",
  CONFIRM_SEND_EMAIL: "Are you sure of send email to access of wizard?",
  CONFIRM_DISABLE: "Would you like to disable this company?",
  CONFIRM_ENABLE: "Would you like to reactivate this company?",
  CONFIRM_LOGING: "Would you like to log-in in this company?",
  PRODUCT_NAME: "Name",
  PRODUCT_CAMPUS_LODGING: "Campus / Lodging",
  PRODUCT_CITY_COUNTRY: "City - Country",
  PRODUCT_TYPE_PRODUCT: "Type product",
  PRODUCT_CATEGORY: "Category",
  PRODUCT_DATE_REQUEST_PUBLISH: "Date of request to publish",
  PRODUCT_DETAIL: "View product details",
  PRODUCT_APPROVE: "Publish the product",
  PRODUCT_SEND_NOTIFICATION: "Notify of issues in product",
  PRODUCT_PUBLISHED: "The product has been published",
  PRODUCT_NOTIFIED: "The product has been notified",
  YOU_ARE_SURE_AGREE: "Do you agree with this review?",
  YOU_ARE_SURE_DENY: "Would you like to deny this review?",
  CAMPUS: "Campus",
  COMPANY: "Company",
  NAME: "Name",
  EMAIL: "Email",
  SCORE: "Score",
  COMMENT: "Comment",
  DATE: "Date",
  REVIEW_DENY: "Disagree with this review",
  REVIEW_AGREE: "Agree with this review",
  LANGUAGE: "Language",
  CATEGORY: "Category",
  CITY: "City",
  COUNTRY: "Country",
  N_COUNTRIES_FEATURED: "N° countries featured",
  FEATURE_PRODUCT: "Config feature product",
  REVIEW: "Review",
  CANCEL: "Cancel",
  VIEW_DETAILS: "View details",
  COMISSION: "Assign commissions",
  COURSE: "Course",
  ACCOMMODATION: "Accommodation",
  FEE: "Fee",
  SERVICE: "Service",
  COMMISSION_LABELS: {
    COURSE_BUSINESS: "Business",
    COURSE_EXAM_PREPARATION: "Exam preparation",
    COURSE_FAMILY_PROGRAMS: "Family programs",
    COURSE_GAP_YEAR: "Gap year",
    COURSE_GENERAL: "General",
    COURSE_LANGUAGE_PLUS_ACTIVITIES_PACKAGE: "Language plus activities package",
    COURSE_MINI_GROUPS: "Mini groups / Mini stays",
    COURSE_PATHWAY: "Pathway",
    COURSE_PRIVATE_LESSONS: "Private lessons",
    COURSE_PROFESSIONAL: "Professional",
    COURSE_SENIOR_PROGRAMS: "Senior programs",
    COURSE_TEACHER_TRAINING: "Teacher training",
    COURSE_WORK_AND_STUDY: "Work and study",
    COURSE_SPECIALIZED_LANGUAGE: "Specialized language",
    COURSE_JUNIORS: "Juniors",
    COURSE_HOME_TUITION_JUNIORS: "Course home tuition juniors",
    ACCOMMODATION_HOMESTAY: "Homestay",
    ACCOMMODATION_RESIDENCE: "Residence",
    ACCOMMODATION_FULL_APARTMENT: "Full apartment",
    ACCOMMODATION_SHARED_APARTMENT: "Shared apartment",
    ACCOMMODATION_HOTEL: "Hotel room",
    FEE_COURSE_MATERIAL: "Course Material Fee",
    FEE_REGISTRATION: "Registration Fee",
    FEE_ACCOMMODATION: "Accommodation Fee",
    FEE_MINOR: "Minor Fee",
    FEE_OTHER: "Other fee",
    SERVICE_TRANSFER: "Transfer",
    SERVICE_ACTIVITIES: "Activities",
    SERVICE_VISA: "Visa",
    SERVICE_EXAM: "Exam Fee",
  },
  PENDING: "Pending",
  ACTIVE: "Active",
  DISABLED: "Disabled",
  CHANGE_ORIGIN_PRICE:
    "Change origin price to {NEW}, your actual origin is: {ACTUAL}",
  CHANGE_ORIGIN_PRICE_API: "Ynsitu API",
  CHANGE_ORIGIN_PRICE_EXTERNAL_API: "External API",
  CONFIRM_CHANGE_ORIGIN_PRICE: "Are you sure change origin of price?",
};
