import ApiService from "@/core/services/api.service";

export const END_POINT = "api/v1/timetable-template";

import { UPDATE_TIMETABLE_TEMPLATE_CAMPUS } from "@/core/services/store/general/campus.module";
import { STORE_TIMETABLE_TEMPLATE_CAMPUS } from "@/core/services/store/general/campus.module";
import { REMOVE_PRODUCT_TIMETABLE_TEMPLATE_CAMPUS } from "@/core/services/store/general/campus.module";
//Mutations
export const SET_TIMETABLES = "setTimeTables";
export const SET_LOADING = "setLoading";
export const REMOVE_ONE_PRODUCT_TIMETABLE_TEMPLATE =
  "removeOneProductTimetableTemplate";
export const UPDATE_TIMETABLE_TEMPLATE = "updateTimetableTemplate";
export const SET_TIMETABLE = "setTimetable";
//Actions
export const GET_PRODUCT_TIMETABLE_TEMPLATES = "getProductTimetableTemplate";
export const GET_CUSTOM_PRODUCT_TIMETABLE_TEMPLATES =
  "getCustomProductTimetableTemplate";
export const UPDATE_PRODUCT_TIMETABLE_TEMPLATE =
  "updateProductTimetableTemplate";
export const STORE_PRODUCT_TIMETABLE_TEMPLATE = "storeProductTimetableTemplate";
export const DELETE_PRODUCT_TIMETABLE_TEMPLATE =
  "deleteProductTimetableTemplate";

const state = {
  timeTables: [],
};

const getters = {
  timeTables(state) {
    return state.timeTables;
  },
};

const actions = {
  [GET_PRODUCT_TIMETABLE_TEMPLATES](context, config) {
    context.commit("setIsVisibleLoader", true);
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      const campus = context.getters.campus;
      ApiService.query(`${END_POINT}?campusId=${campus.campusId}`, config)
        .then(({ data }) => {
          context.commit(SET_TIMETABLES, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [GET_CUSTOM_PRODUCT_TIMETABLE_TEMPLATES](context, config) {
    ApiService.setHeader();
    const campus = context.getters.campus;
    config.campusId = campus.campusId;
    const objectKeys = Object.keys(config);
    let strConditional = "";
    for (let index = 0; index < objectKeys.length; index++) {
      const item = config[objectKeys[index]];
      if (strConditional === "") {
        strConditional = `${objectKeys[index]}=${item}`;
      } else {
        strConditional = `${strConditional}&${objectKeys[index]}=${item}`;
      }
    }
    return new Promise((resolve, reject) => {
      ApiService.query(`${END_POINT}?${strConditional}`, config)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [UPDATE_PRODUCT_TIMETABLE_TEMPLATE](context, data) {
    context.commit(SET_LOADING, true);
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(
        `api/v1/timetable-template/${data.productTimetableTemplateId}`,
        data
      )
        .then(data => {
          context.commit(
            UPDATE_TIMETABLE_TEMPLATE_CAMPUS,
            data.data.productTimetableTemplate
          );
          context.commit(SET_TIMETABLE, data.data.productTimetableTemplate);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(error => {
          context.commit(SET_LOADING, false);
          reject(error);
        });
    });
  },
  [STORE_PRODUCT_TIMETABLE_TEMPLATE](context, data) {
    context.commit(SET_LOADING, true);
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("api/v1/timetable-template", data)
        .then(data => {
          context.commit(
            STORE_TIMETABLE_TEMPLATE_CAMPUS,
            data.data.productTimetableTemplate
          );
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(error => {
          context.commit(SET_LOADING, false);
          reject(error);
        });
    });
  },
  [DELETE_PRODUCT_TIMETABLE_TEMPLATE](context, id) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.delete(`api/v1/timetable-template/${id}`)
        .then(data => {
          if (data.data.success) {
            context.commit(
              REMOVE_PRODUCT_TIMETABLE_TEMPLATE_CAMPUS,
              data.data.productTimetableTemplate
            );
          }
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(error => {
          context.commit(SET_LOADING, false);
          reject(error);
        });
    });
  },
};

const mutations = {
  [SET_TIMETABLES](state, timetables) {
    state.timeTables = timetables;
  },
  [SET_LOADING](state, data) {
    state.isLoading = data;
  },
  [UPDATE_TIMETABLE_TEMPLATE](state, data) {
    state.timeTables.forEach((timetable, index) => {
      if (
        timetable.productTimetableTemplateId === data.productTimetableTemplateId
      ) {
        state.timeTables.splice(index, 1, data);
      }
    });
  },
  [STORE_TIMETABLE_TEMPLATE_CAMPUS](state, timetable) {
    state.timeTables.push(timetable);
  },
  [SET_TIMETABLE](state, timetable) {
    const timetableFiltered = state.timeTables?.filter(item => {
      return (
        timetable.productTimetableTemplateId === item.productTimetableTemplateId
      );
    });

    if (timetableFiltered.length > 0) {
      const indexTimetables = state.timeTables.indexOf(timetableFiltered[0]);
      state.timeTables[indexTimetables] = timetable;
    }
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
