//Mutations
export const SET_PROMOTION_TYPES = "setPromotionTypes";

const state = {
  promotionTypes: [],
};

const getters = {
  PromotionTypes(state) {
    return state.promotionTypes;
  },
};

const mutations = {
  [SET_PROMOTION_TYPES](state, data) {
    state.promotionTypes = data;
  },
};

export default {
  state,
  mutations,
  getters,
};
