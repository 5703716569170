export default {
  TITLE: "Commission",
  TITLE1: "Courses",
  TITLE2: "Lodgings",
  TITLE3: "Services",
  TITLE4: "Fees",
  TITLE5: "Commission",
  BTN: "View Commissions",
  COURSE: {
    OPT1: "General Language",
    OPT2: "Exam Preparation",
    OPT3: "Business",
    OPT4: "University Preparation",
    OPT5: "Specialized Language",
    OPT6: "Teacher Training",
    OPT7: "Seniors (+30/+40/+50)",
    OPT8: "Work & Study",
    OPT9: "One to One",
    OPT10: "Juniors",
    OPT11: "With Activities",
    OPT12: "Home tuition",
    OPT13: "Home tuition juniors",
  },
  ACCOMMODATION: {
    OPT1: "Homestay",
    OPT2: "Residence",
    OPT3: "Full Apartment",
    OPT4: "Shared Apartment",
    OPT5: "Hotel",
  },
  SERVICES: {
    OPT1: "Airport Transfer",
    OPT2: "Leisure Activities",
    OPT3: "Exam",
    OPT4: "Visa",
  },
  FEES: {
    OPT1: "Registration fee",
    OPT2: "Accommodation fee",
    OPT3: "Course Material fee",
    OPT4: "Minor Fee",
    OPT5: "Others",
  },
  SUBTITLE: {
    OPT1: "Adults",
    OPT2: "Adult Packages",
    OPT3: "Apartment",
    OPT4: "Transfer",
    OPT5: "Activities",
    OPT6: "One to one (view courses)",
  },
};
