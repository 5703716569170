export default {
  GREATER_THAN: "Must be greater than {minvalue}",
  INVALID_EMAIL: "Invalid e-mail.",
  EXCEED_CHARS_MAXIMUM: "Exceeds the maximum number of characters",
  REQUIRED: "{_field_} can not be empty",
  MAX: "{_field_} may not be greater than {max} characters",
  REGEX_NUMBER_COMMAS:
    "{_field_} must be a list of numbers separated by commas",
  TIME: "{_field_} must has time format like HH:MM",
  MIN: "{_field_} must have minimum {min} characters",
  EMAIL: "Email must be valid",
  NUMERIC: "The {_field_} field may only contain numeric characters",
  TIME_LOWER_THAN: "The {_field_} must be lower than end time",
  TIME_GREATER_THAN: "The {_field_} must be greater than start time",
  TIME_GREATER_THAN_PREVIOUS:
    "The {_field_} must be greater than previous end time",
  MAX_VALUE: "The {_field_} cannot exceed {max} or less",
  MIN_VALUE: "The {_field_} must be minimal {min} or more",
};
