import ApiService from "@/core/services/api.service";

// Actions
export const GET_FEES = "getListFees";
export const GET_FEE = "getFee";
export const STORE_FEE = "storeFee";
export const UPDATE_FEE = "updateFee";
export const DELETE_FEE = "deleteFee";

// Mutations
export const SET_LIST_FEES = "setListFees";
export const SET_FEE = "setFee";
export const SET_FEE_NEW_LIST = "setFeeNewList";

const state = {
  list_fees: [],
  fee: {},
};

const getters = {
  getFees(state) {
    return state.list_fees;
  },
  fee(state) {
    return state.fee;
  },
};

const actions = {
  [GET_FEES](context, dataForm) {
    context.commit("setIsVisibleLoader", true);
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      const campus = context.getters.campus;
      let route;
      if (dataForm && dataForm.campusId) {
        route = `api/v1/fee?campusId=${dataForm.campusId}`;
      } else {
        route = `api/v1/fee?campusId=${campus.campusId}`;
      }
      ApiService.query(route, {})
        .then(({ data }) => {
          context.commit(SET_LIST_FEES, data.data);
          context.commit("setIsVisibleLoader", false);
          resolve(data.data);
        })
        .catch(response => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [GET_FEE](context, id) {
    context.commit("setIsVisibleLoader", true);
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.query(`api/v1/fee/${id}`, {})
        .then(({ data }) => {
          context.commit(SET_FEE, data.data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [STORE_FEE](context, data) {
    context.commit("setIsVisibleLoader", true);
    ApiService.setHeader();
    const campus = context.getters.campus;
    const newData = { ...data, campusId: campus.campusId };
    return new Promise((resolve, reject) => {
      ApiService.post("api/v1/fee", newData)
        .then(({ data }) => {
          context.commit(SET_FEE, data.data);
          context.commit("setMessage", {
            status: 200,
            message: "Fee saved successfully.",
          });
          context.commit(SET_FEE_NEW_LIST, data.data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [UPDATE_FEE](context, formData) {
    context.commit("setIsVisibleLoader", true);
    ApiService.setHeader();
    const campus = context.getters.campus;
    const newData = { ...formData, campusId: campus.campusId };
    return new Promise((resolve, reject) => {
      ApiService.put(`api/v1/fee/${formData.id}`, newData)
        .then(({ data }) => {
          context.commit("setIsVisibleLoader", false);
          context.commit("setMessage", {
            status: 200,
            message: "Fee updated successfully.",
          });
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [DELETE_FEE](context, id) {
    context.commit("setIsVisibleLoader", true);
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(`api/v1/fee/${id}`, {})
        .then(({ data }) => {
          context.commit(SET_FEE, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
};

const mutations = {
  [SET_LIST_FEES](state, formData) {
    state.list_fees = formData;
  },
  [SET_FEE](state, data) {
    state.fee = data;
  },
  [SET_FEE_NEW_LIST](state, fee) {
    const arrList = state.list_fees;
    arrList.push(fee);
    state.list_fees = arrList;
  },
};

export default { state, getters, actions, mutations };
