//Mutations
export const SET_TRANSPORT_TYPES = "setTransportTypes";

const state = {
  transportTypes: [],
  transportType: {},
  isLoading: false,
  isEditing: false,
};

const getters = {
  TransportTypes(state) {
    return state.TransportTypes;
  },
};

const actions = {};
const mutations = {
  [SET_TRANSPORT_TYPES](state, data) {
    state.TransportTypes = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
