export default {
  TITLE: "Applications",
  TITLE_FORM: "Application",
  INFORMATION: "Student Information",
  VIEW: "View",
  UPLOAD: "Upload",
  SEND: "Send",
  ACCEPT: "Accept",
  PAYED: "Payed",
  DENY: "Deny",
  CHANGE: "Change",
  SELECT_DATE: "Select Date",
  SELECT_QTY: "Select Qty",
  NEW_PRICE: "New Price:",
  TOTAL_PRICE: "Total Price",
  FILTER_BY: "Filter By",
  OPTIONS: {
    OPT1: "All",
    OPT2: "External Api",
    OPT3: "Ynsitu Api",
  },
  TABLE: {
    TAB1: "All",
    TAB2: "Pending",
    TAB3: "Accepted",
    TAB4: "Accepted by me, pending by others",
    TAB5: "Accepted with additional services",
    TAB6: "Declined",
    TAB7: "Canceled",
    TAB8: "Pending Payment",
    TAB9: "External Api",
    STUDENTS_HEADER: {
      ITEM1: "Id",
      ITEM2: "Student",
      ITEM3: "Nationality",
      ITEM4: "Booking status",
      ITEM8: "Pending Payment",
      ITEM5: "Service status",
      ITEM6: "Booking date",
      ITEM7: "First Product Date",
    },
    PRODUCTS_HEADER: {
      ITEM1: "Product",
      ITEM2: "Service Status",
      ITEM3: "Provider",
      ITEM4: "Service Date",
      ITEM5: "Updated Date",
    },
  },
  STUDENT: {
    TITLE: "Student Information",
    FNAME: "First Name",
    LNAME: "Last Name",
    CITY: "City",
    STATE: "State",
    PHONE: "Phone",
    EMAIL: "Email",
    BILLING: "Billing Information",
    INFO: "The billing information is the same as the student's",
  },
  COURSE: {
    TITLE: "Course",
    PROVIDER: "Provider",
    COURSE_NAME: "Course Name",
    LANGUAGE: "Language",
    INTENSITY: "Intensity",
    START_DATE: "Start Date",
    END_DATE: "End Date",
    DURATION: "Duration",
    PRICE: "Price",
    PROMOTION: "Promotions",
    SELECT_COURSE: "Select Course",
  },
  LODGING: {
    TITLE: "Lodging",
    PROVIDER: "Provider",
    ROOM_NAME: "Room Name",
    CATEGORY: "Category",
    START_DATE: "Start Date",
    END_DATE: "End Date",
    DURATION: "Duration",
    PRICE: "Price",
    SELECT_LODGING: "Select Lodging",
    SELECT_ROOM: "Select Room",
    FEES: "Fees",
    MEALS: "Meals",
    DIET: "Diet",
  },
  SERVICES: {
    TITLE: "Services",
  },
  DOCUMENTS: {
    TITLE: "Documents",
    OPT1: "Terms of service",
    OPT2: "Lodging Info",
    OPT3: "Transfer Info",
    OPT4: "Invoice",
    OPT5: "Letter of acceptance",
    OPT6: "Passport",
  },
  CHAT: {
    TITLE: "Chat",
    PLACEHOLDER: "Type your message",
  },
  FILE_SHARING: {
    TITLE: "File Sharing",
    TITLE_TAB1: "Student Files",
    TITLE_TAB2: "Provider Files",
    COURSE: "Course",
    ROOM: "Room",
    SERVICE: "Service",
  },
  ACTIVITY: {
    TITLE: "Activity Log",
  },
  ICONS: {
    ITEM1: "Course",
    ITEM2: "Lodging",
    ITEM3: "Service",
    ITEM4: "Package",
  },
  STATUS: {
    ITEM1: "Pending",
    ITEM2: "Accepted",
    ITEM3: "Declined",
    ITEM4: "Canceled",
    ITEM5: "Accepted by me, pending by others",
    ITEM6: "Accepted with additional services",
    ITEM7: "Payed",
  },
  PAYMENT: {
    TITLE: "Payment",
    TOTAL_AMOUNT_PRODUCTS: "Product Total Amount",
    TOTAL_AMOUNT_INSURANCE: "Insurance Total Amount",
    TOTAL_REMAINING_PAYMENTS: "Remaining Payment",
    PAYMENT_METHOD: "Payment Method",
    TRANSACTION_ID: "ID",
    EQUIVALENT_AMOUNT: "Amount Paid",
    AMOUNT_PAYED: "Amount in partner currency",
    STATE: "State",
    CONFIRM_PAYMENT: "Confirm Payment",
  },
  PAYMENT_METHOD: {
    CARD: "Card",
    TRANSFER: "Transfer",
    PAYPAL: "Paypal",
    SEPA: "Direct debit",
  },
  ADDONS: {
    TITLE: "Services",
    NAME: "Name",
    AMOUNT: "Amount",
  },
  ADDONS_TYPE: {
    INSURANCE_EU: "Insurance EU",
    INSURANCE_WORLD: "Insurance World",
    CANCELATION: "Cancellation",
    ASSISTANCE: "Assistance",
    TICKET: "Ticket Managing",
    DISCOUNT: "Discount",
    CUPON: "Coupon",
  },
  PROMOTION_TYPE: {
    TYPE1: "Amount Off",
    TYPE2: "Percentage Off",
    TYPE3: "Discount Fee",
    TYPE4: "Duration Extension",
    TYPE5: "Discount Other Offering",
    TYPE6: "Agent Bonus",
    TYPE7: "Override Price",
    TYPE8: "Custom",
    TYPE9: "Bonus Commission",
  },
  MESSAGE: {
    SUCCESS: "Payment has been updated successfully",
  },
};
