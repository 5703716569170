//Mutations
export const SET_MESSAGE = "setMessage";

const state = {
  message: {},
};

const getters = {
  message(state) {
    return state.message;
  },
};

const mutations = {
  [SET_MESSAGE](state, data) {
    state.message = data;
  },
};

export default {
  state,
  mutations,
  getters,
};
