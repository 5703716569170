//Mutations
export const SET_APARTMENT_TYPES = "setApartmentTypes";

const state = {
  apartmentTypes: [],
  apartmentType: {},
  isLoading: false,
  isEditing: false,
};

const getters = {
  ApartmentTypes(state) {
    return state.ApartmentTypes;
  },
};

const actions = {};
const mutations = {
  [SET_APARTMENT_TYPES](state, data) {
    state.ApartmentTypes = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
