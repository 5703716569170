import Vue from "vue";
import ApiService from "@/core/services/api.service";
//Actions
export const GET_ALL_SUBCATEGORIAS = "getAllSubcategorias";
export const GET_SUBCATEGORIA = "getSubcategoria";
export const STORE_SUBCATEGORIA = "storeSubcategoria";
export const UPDATE_SUBCATEGORIA = "updateSubcategoria";
export const DELETE_SUBCATEGORIA = "deleteSubcategoria";
export const CANCEL_SUBCATEGORIA = "cancelSubcategoria";

//Mutations
export const SET_SUBCATEGORIAS = "setSubcategorias";
export const SET_SUBCATEGORIA = "setSubcategoria";
export const PUSH_NEW_SUBCATEGORIA = "pushSubcategoria";
export const PUSH_UPDATE_SUBCATEGORIA = "pushSubcategoria";
export const REMOVE_ONE_SUBCATEGORIA = "pushSubcategoria";
export const SET_LOADING = "setLoading";
export const SET_EDITING = "setEditing";
export const CLEAN_SUBCATEGORIA = "cleansubcategoria";

const state = {
  subcategorias: [],
  subcategoria: {},
  isLoading: false,
  isEditing: false,
};

const getters = {
  Subcategorias(state) {
    return state.Subcategorias;
  },
  subcategoria(state) {
    return state.subcategoria;
  },
  isLoadingSubcategoria(state) {
    return state.isLoading;
  },
  isEditingSubcategoria(state) {
    return state.isEditing;
  },
};

const actions = {
  [GET_ALL_SUBCATEGORIAS](context, config) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.query("api/v1/subcategoria", config)
        .then(({ data }) => {
          context.commit(SET_SUBCATEGORIAS, data.data);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_LOADING, false);
        });
    });
  },

  [GET_SUBCATEGORIA](context, id) {
    context.commit(SET_LOADING, true);
    return new Promise(resolve => {
      ApiService.get(`api/v1/subcategoria/${id}`)
        .then(({ data }) => {
          context.commit(SET_SUBCATEGORIA, data);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [STORE_SUBCATEGORIA](context, data) {
    context.commit(SET_LOADING, true);
    return new Promise(resolve => {
      ApiService.post("api/v1/subcategoria", data)
        .then(({ data }) => {
          context.commit(PUSH_NEW_SUBCATEGORIA, data);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [UPDATE_SUBCATEGORIA](context, data) {
    context.commit(SET_LOADING, true);
    return new Promise(resolve => {
      ApiService.put(`api/v1/subcategoria/${data.id}`, data)
        .then(({ data }) => {
          context.commit(PUSH_UPDATE_SUBCATEGORIA, data);
          context.commit(SET_LOADING, false);
          context.commit(SET_EDITING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [DELETE_SUBCATEGORIA](context, id) {
    context.commit(SET_LOADING, true);
    return new Promise(resolve => {
      ApiService.delete(`api/v1/subcategoria/${id}`)
        .then(({ data }) => {
          context.commit(REMOVE_ONE_SUBCATEGORIA, data);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [CANCEL_SUBCATEGORIA](context) {
    context.commit(SET_LOADING, true);
    return new Promise(() => {
      context.commit(CLEAN_SUBCATEGORIA);
      context.commit(SET_LOADING, false);
    }).catch(() => {
      context.commit(SET_LOADING, false);
    });
  },
};
const mutations = {
  [SET_SUBCATEGORIAS](state, data) {
    state.Subcategorias = data;
  },
  [SET_SUBCATEGORIA](state, data) {
    state.subcategoria = data;
  },
  [PUSH_NEW_SUBCATEGORIA](state, data) {
    state.subcategorias.push(data);
  },
  [PUSH_UPDATE_SUBCATEGORIA](state, data) {
    const position = state.subcategorias.indexOf(state.subcategoria);
    Vue.set(state.subcategorias, position, data);
  },
  [REMOVE_ONE_SUBCATEGORIA](state, data) {
    state.subcategorias.splice(state.subcategorias.indexOf(data), 1);
  },
  [SET_LOADING](state, data) {
    state.isLoading = data;
  },
  [SET_EDITING](state, data) {
    state.isEditing = data;
  },
  [CLEAN_SUBCATEGORIA](state) {
    Vue.set(state.subcategoria, {});
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
