import Vue from "vue";
import ApiService from "@/core/services/api.service";

const patch_accommodation = "api/v1/accommodation";

//Actions
export const GET_ALL_ACCOMMODATION = "getAllAccommodation";
export const GET_ACCOMMODATION = "getAccommodation";
export const STORE_ACCOMMODATION = "storeAccommodation";
export const UPDATE_ACCOMMODATION = "updateAccommodation";
export const DELETE_ACCOMMODATION = "deleteAccommodation";

//Mutations
export const SET_ALL_ACCOMMODATION = "setAllAccommodation";
export const SET_ACCOMMODATION_LIST = "setAccommodationList";
export const SET_ACCOMMODATION = "setAccommodation";
export const PUSH_NEW_ACCOMMODATION = "pushAccommodation";
export const PUSH_UPDATE_ACCOMMODATION = "updatePushAccommodation";
export const REMOVE_ONE_ACCOMMODATION = "deleteOneAccommodation";

import { REMOVE_ONE_CAMPUS } from "@/core/services/store/general/campus.module";

const state = {
  accommodation: {},
  accommodations: [],
};

const getters = {
  accommodation(state) {
    return state.accommodation;
  },
  accommodations(state) {
    return state.accommodations;
  },
};

const actions = {
  [GET_ALL_ACCOMMODATION](context) {
    context.commit("setIsVisibleLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      const campus = context.getters.campus;
      ApiService.get(`${patch_accommodation}?campusId=${campus.campusId}`)
        .then(({ data }) => {
          context.commit(SET_ALL_ACCOMMODATION, data.accommodations);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit("setIsVisibleLoader", false);
          reject(response);
        });
    });
  },

  [GET_ACCOMMODATION](context, id) {
    context.commit("setIsVisibleLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(`${patch_accommodation}/${id}`)
        .then(({ data }) => {
          context.commit(SET_ACCOMMODATION_LIST, data.accommodation);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(response => {
          context.commit("setIsVisibleLoader", false);
          reject(response);
        });
    });
  },

  [STORE_ACCOMMODATION](context, json) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(patch_accommodation, json)
        .then(({ data }) => {
          context.commit(PUSH_NEW_ACCOMMODATION, data.accommodation);
          if (data.new_campus) {
            context.commit("pushNewCampus", data.campus);
          }
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [UPDATE_ACCOMMODATION](context, json) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      const { accommodationId } = json;
      ApiService.put(`${patch_accommodation}/${accommodationId}`, json)
        .then(({ data }) => {
          context.commit(PUSH_UPDATE_ACCOMMODATION, data.accommodation);
          context.commit("pushUpdateCampus", data.campus);
          resolve(data.accommodation);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [DELETE_ACCOMMODATION](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.delete(`${patch_accommodation}/${id}`)
        .then(({ data }) => {
          //If we delete an accccommodation that belongs to company that is not a Course Company, we should delete also the campus that belongs to that accommodation.
          if (context.getters.company.companyTypeId !== 1) {
            context.state.accommodations.forEach((item, index) => {
              if (item.accommodationId === id) {
                let campusId = context.state.accommodations[index].campusId;
                context.getters.campuses.forEach(campus => {
                  if (campus.campusId === campusId) {
                    context.commit(REMOVE_ONE_CAMPUS, campus);
                  }
                });
              }
            });
          }
          context.commit(REMOVE_ONE_ACCOMMODATION, id);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

const mutations = {
  [SET_ALL_ACCOMMODATION](state, data) {
    state.accommodations = data;
  },

  [SET_ACCOMMODATION_LIST](state, data) {
    let position;
    state.accommodations.some((item, index) => {
      if (parseInt(item.accommodationId) === parseInt(data.accommodationId)) {
        position = index;
        return true;
      }
    });

    if (position) {
      state.accommodations[position] = data;
    }
    state.accommodation = data;
  },

  [SET_ACCOMMODATION](state, data) {
    state.accommodation = data;
  },

  [PUSH_NEW_ACCOMMODATION](state, data) {
    state.accommodations.push(data);
  },

  [PUSH_UPDATE_ACCOMMODATION](state, data) {
    state.accommodation = data;
    state.accommodations.forEach((item, index) => {
      if (item.accommodationId === data.accommodationId) {
        Vue.set(state.accommodations, index, data);
      }
    });
  },

  [REMOVE_ONE_ACCOMMODATION](state, id) {
    state.accommodations.forEach((item, index) => {
      if (item.accommodationId === id) {
        state.accommodations.splice(index, 1);
      }
    });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
