export default {
  // GENERAL
  TITLE: "Campus",
  CAMPUSES: "Select Campus",
  TITLE5: "Financial Data",
  TITLE6: "Multimedia Gallery",
  TITLE7: "Nationality Mix",
  SUB_TITLE7_1:
    "The nationality mix is important for the student to make their decisions.",
  SUB_TITLE7_2:
    "We are a global company and we have users from all over the world. It is also important to provide the students with the nationality mix of the Austral summer (July- August) and Boreal Summer (January- February) as they may differ one from each other and from the annual mix.",
  TITLE8: "Campus Facilities",
  TITLE8_1: "Classroom Facilities",
  NATIONALITY: "Nationality",
  PERCENTAGE: "Percentage",
  INFO1: "Maximum 12 files",
  VIDEO: "Featured Video Url",
  TOTAL: "Total:",
  CALENDAR_WEEK: "Calendar Week Start",
  DATE_FORMAT: "Date Format",
  // BASIC INFORMATION
  TITLE1: "Basic Information",
  NAME: "Campus Name",
  ADDRESS: "Address",
  CITY: "City, Country",
  CURRENCY: "Currency",
  PHONE: "Phone Number",
  EMAIL: "Email Address",
  EMAIL_CONTACT: "Email Address for Client Enquiries",
  WEBSITE: "Website",
  AGE_MIN: "Minimum student age",
  AGE_MAX: "Maximum student age",
  FUNDATION_YEAR: "Foundation Year",
  //ADDITIONAL INFORMATION
  TITLE1_2: "Additional Information",
  TRANSPORT_TITLE: "Transport stops near the campus",
  BUS_TITLE: "Bus",
  METRO_TITLE: "Metro",
  TRANVIA_TITLE: "Tram",
  TREN_TITLE: "Train",
  LINES_TITLE: "Lines connecting to the city center",
  UNIT_MED: "Distance to city center",
  UNIT: "Unit of measure",
  UNIT_VALUE: "Distance",
  AGE: "Ages of students on campus",
  AGE_1: "-18",
  AGE_2: "18-24",
  AGE_3: "25-34",
  AGE_4: "35-50",
  AGE_5: "+50",
  TV: "Tv",
  BOARD: "Digital Board",
  AIR_CONDITIONING: "Air Conditioning",
  HEATING: "Heating",
  NATURAL_LIGHT: "Abundant Natural Light",
  WHEELCHAIR_ACCESSIBILITY: "Wheelchair Accessibility",
  DIGITAL_CLASSES_TABLET: "Digital Classes With Tablet",
  IN: "Select In",
  //Facilities
  TOTAL_CLASSROOMS: "Number of classrooms",
  TOTAL_COMPUTERS: "Number of computers for public use",
  // Course Default
  TITLE2: "Course Defaults",
  TITLE2_DES: "You can customize the data for each course later",
  TITLE3: "Default duration of courses",
  DURATION_MIN: "Minimum Duration",
  DURATION_MAX: "Maximum Duration",
  DURATION: "Duration Type",
  // DESCRIPTION
  TITLE4: "Description",
  SCHOOL_DESCRIPTION: "Campus Description",
  CITY_DESCRIPTION: "City Description",
  ENVIROMENT_DESCRIPTION: "Information about the area",
  // CAMPUSNATIONALITY
  CAMPUSNATIONALITY: {
    OPTION1: "Annual",
    OPTION2: "July - August",
    OPTION3: "January -  February",
  },
  // MODAL
  TITLE9: "Enter your nationality breakdown below.",
  TITLE10: "Nationality",
  TITLE11: "Percentage",
  FORMAT: {
    OPT1: "DD/MM/YYYY",
    OPT2: "MM/DD/YYYY",
  },
  CALENDAR: {
    OPT1: "Sunday",
    OPT2: "Monday",
  },
  ERROR_CHARTS: "The total must complete 100%",
  TITLE12: "Display Options",
  TITLE13:
    "You can set pricing transition rules if you want to overwrite the default setting for the courses on this campus.",
  TITLE14: "Free Cancellation Policy",
  TITLE15: "Free Change of Dates Policy",
  TITLE16: "Standard Cancellation Policy",
  TITLE17: "General Cancellation Policy",
  TITLE18: "Visa refusal cancellation policy",
  TITLE19: "Standard YNSITU payment policy",
  TITLE20: "Special payment policy",
  FREE_POSTPONEMENT:
    "how many month's can the start of a course be postponed free of cost and maintaining it's original price.",
  POLICY:
    "Please type here the cancellation policy that applies after the free cancellation period",
  GENERAL_CANCELLATION_POLICY:
    "Please write or copy/paste your cancellation policy here",
  GENERAL_POSTPONE_POLICY: "Free Change of Dates terms",
  MONTH: "Month's",
  DAYS: "Days",
  FREE_POSTPONE_POLICY:
    "Number of days a prior to course start date when it's permitted to postpone the course free of cost",
  ADDRESS_INFO: "Addresses based on google maps",
  MIN_INFO: "Minimum age accepted in your campus",
  MIN_DURATION_INFO: "Minimum duration of the course per day/week/month.",
  PAYMENT_DESCRIPTION:
    // eslint-disable-next-line max-len
    "If a reservation is made with more than 30 days to the departure date, the student is  requested to pay  a deposit which includes the registration and accommodation fees to secure the booking and the balance 30 days  prior to the start date. Ynsitu will pay you the net price between 29 and 21 days before the start date. If a booking is made with less than 30 days to the departure date full payment is required to secure the booking and Ynsitu will pay you the net price as soon as the student receives the booking confirmation. You can check all the bookings and the students payment status in the Applications Section. ",
  PAYMENT_DESCRIPTION_SUB:
    "Ynsitu has provided for exceptions to the general payment conditions that will be passed on to the student, if applicable.",
  PAYMENT_CHECK_SPECIAL:
    "We have special payment conditions for long term courses.",
  WEEKS: "Weeks",
  PAYMENT_POLICY_TERMS: "What length (in weeks) do you consider long term?",
  PAYMENT_POLICY_PERCENTAGE:
    "What percentage of the total net price would you like to receive prior to the students arrival? ",
  PAYMENT_POLICY_RECURRENCE:
    "Select how you want to receive the remaining amount",
  PAYMENT_POLICY_RECURRENCE_TOOLTIP:
    "Payments will be made before the start of the month, term or second half of the course depending",
  PAYMENT_POLICY_RECURRENCE_TOOLTIP_2:
    "on the payments policy that you have selected",
  PAYMENT_POLICY_RECURRENCE_ITEM1: "Before the second half of the course",
  PAYMENT_POLICY_RECURRENCE_ITEM2: "Quarterly in advance",
  PAYMENT_POLICY_RECURRENCE_ITEM3: "Monthly in advance",
  PAYMENT_POLICY_SPECIAL_CONDITION:
    "We have special payment conditions for bookings when a visa is required ",
  PAYMENT_POLICY_SPECIAL:
    "What percentage of the total do you need to receive in order to issue the documents needed by the customers to apply for their visa?",
  PAYMENT_POLICY_SPECIAL_TOOLTIP:
    "The remaining payment will be according to YNSITU standard policy or your long term payment policy, as applicable.",
  FREE_CANCELLATION_CHECK: "We have a free cancellation policy",
  FREE_POSTPONEMENT_CANCELLATION_CHECK: "We have a free posponement policy",
  VISA_CANCELLATION_CHECK: "We have a visa refusal cancellation policy",
  VISA_CANCELLATION: "Visa refusal cancellation terms and conditions",
  FREE_INFO:
    "Ynsitu will highlight those schools that have a 100% free cancellation policy less than 31 days from the start date",
};
