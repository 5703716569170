//Mutations
export const SET_DISTANCE_TYPES = "setDistanceTypes";

const state = {
  distanceTypes: [],
  distanceType: {},
  isLoading: false,
  isEditing: false,
};

const getters = {
  DistanceTypes(state) {
    return state.DistanceTypes;
  },
};

const actions = {};
const mutations = {
  [SET_DISTANCE_TYPES](state, data) {
    state.DistanceTypes = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
