//Mutations
export const SET_AMENITY_TYPES = "setAmenityTypes";

const state = {
  amenityTypes: [],
};

const getters = {
  amenityTypes(state) {
    return state.amenityTypes;
  },
};

const mutations = {
  [SET_AMENITY_TYPES](state, data) {
    state.amenityTypes = data;
  },
};

export default {
  state,
  mutations,
  getters,
};
