//Mutations
export const SET_COUNTRIES = "setCountries";
export const SET_COUNTRIES_CONTINENT = "setCountriesContinent";

const state = {
  countries: [],
  continents: [],
};

const getters = {
  Countries(state) {
    return state.countries;
  },
  Continents(state) {
    return state.continents;
  },
};

const mutations = {
  [SET_COUNTRIES](state, data) {
    state.countries = data;
  },

  [SET_COUNTRIES_CONTINENT](state, data) {
    state.continents = data;
  },
};

export default {
  state,
  mutations,
  getters,
};
