import Vue from "vue";
import ApiService from "@/core/services/api.service";
import { UPDATE_COURSE_START_DATE_TEMPLATE_CAMPUS } from "@/core/services/store/general/campus.module";
import { REMOVE_PRODUCT_TIMETABLE_TEMPLATE_CAMPUS } from "@/core/services/store/general/campus.module";
//Actions
export const GET_ALL_PRODUCT_START_DATE_TEMPLATES =
  "getAllProductStartDateTemplates";
export const GET_CUSTOM_PRODUCT_START_DATE_TEMPLATES =
  "getCustomProductStartDateTemplates";
export const GET_PRODUCT_START_DATE_TEMPLATE = "getProductStartDateTemplate";
export const STORE_PRODUCT_START_DATE_TEMPLATE =
  "storeProductStartDateTemplate";
export const UPDATE_PRODUCT_START_DATE_TEMPLATE =
  "updateProductStartDateTemplate";
export const DELETE_PRODUCT_START_DATE_TEMPLATE =
  "deleteProductStartDateTemplate";
export const CANCEL_PRODUCT_START_DATE_TEMPLATE =
  "cancelProductStartDateTemplate";

//Mutations
export const SET_PRODUCT_START_DATE_TEMPLATES = "setProductStartDateTemplates";
export const SET_PRODUCT_START_DATE_TEMPLATE = "setProductStartDateTemplate";
export const PUSH_NEW_PRODUCT_START_DATE_TEMPLATE =
  "pushNewProductStartDateTemplate";
export const PUSH_UPDATE_PRODUCT_START_DATE_TEMPLATE =
  "pushUpdateProductStartDateTemplate";
export const REMOVE_ONE_PRODUCT_START_DATE_TEMPLATE =
  "removeOneProductStartDateTemplate";
export const SET_LOADING = "setLoading";
export const SET_EDITING_PRODUCT_START_DATE_TEMPLATE =
  "setEditingProductStartDateTemplate";
export const CLEAN_PRODUCT_START_DATE_TEMPLATE =
  "cleanproduct_start_date_template";

const state = {
  productStartDateTemplates: [],
  productStartDateTemplate: {},
  isLoading: false,
  isEditing: false,
};

const getters = {
  productStartDateTemplates(state) {
    return state.productStartDateTemplates;
  },
  productStartDateTemplate(state) {
    return state.productStartDateTemplate;
  },
  isLoadingProductStartDateTemplate(state) {
    return state.isLoading;
  },
  isEditingProductStartDateTemplate(state) {
    return state.isEditing;
  },
};

const actions = {
  [GET_ALL_PRODUCT_START_DATE_TEMPLATES](context, config) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.query(
        `api/v1/product-start-date-template?campusId=${config.campusId}`,
        config
      )
        .then(({ data }) => {
          context.commit(SET_PRODUCT_START_DATE_TEMPLATES, data);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_LOADING, false);
        });
    });
  },

  [GET_CUSTOM_PRODUCT_START_DATE_TEMPLATES](context, config) {
    ApiService.setHeader();
    const campus = context.getters.campus;
    config.campusId = campus.campusId;
    const objectKeys = Object.keys(config);
    let strConditional = "";
    for (let index = 0; index < objectKeys.length; index++) {
      const item = config[objectKeys[index]];
      if (strConditional === "") {
        strConditional = `${objectKeys[index]}=${item}`;
      } else {
        strConditional = `${strConditional}&${objectKeys[index]}=${item}`;
      }
    }
    return new Promise((resolve, reject) => {
      ApiService.query(
        `api/v1/product-start-date-template?${strConditional}`,
        config
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  [GET_PRODUCT_START_DATE_TEMPLATE](context, id) {
    context.commit(SET_LOADING, true);
    return new Promise(resolve => {
      ApiService.get(`api/v1/product-start-date-template/${id}`)
        .then(({ data }) => {
          context.commit(SET_PRODUCT_START_DATE_TEMPLATE, data);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [STORE_PRODUCT_START_DATE_TEMPLATE](context, data) {
    ApiService.setHeader();
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("api/v1/product-start-date-template", data)
        .then(({ data }) => {
          context.commit(
            PUSH_NEW_PRODUCT_START_DATE_TEMPLATE,
            data.product_start_date_template
          );
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(error => {
          context.commit(SET_LOADING, false);
          reject(error);
        });
    });
  },

  [UPDATE_PRODUCT_START_DATE_TEMPLATE](context, data) {
    context.commit(SET_LOADING, true);
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(
        `api/v1/product-start-date-template/${data.productStartDateTemplateId}`,
        data
      )
        .then(data => {
          context.commit(
            UPDATE_COURSE_START_DATE_TEMPLATE_CAMPUS,
            data.data.productStartDateTemplate
          );
          context.commit(SET_LOADING, false);
          context.commit(SET_EDITING_PRODUCT_START_DATE_TEMPLATE, false);
          resolve(data);
        })
        .catch(error => {
          context.commit(SET_LOADING, false);
          reject(error);
        });
    });
  },

  [DELETE_PRODUCT_START_DATE_TEMPLATE](context, id) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.delete(`api/v1/product-start-date-template/${id}`)
        .then(({ data }) => {
          context.commit(REMOVE_PRODUCT_TIMETABLE_TEMPLATE_CAMPUS, data);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(error => {
          context.commit(SET_LOADING, false);
          reject(error);
        });
    });
  },

  [CANCEL_PRODUCT_START_DATE_TEMPLATE](context, id) {
    context.commit(SET_LOADING, true);
    return new Promise(resolve => {
      resolve;
      id;
      context.commit(CLEAN_PRODUCT_START_DATE_TEMPLATE);
      context.commit(SET_LOADING, false);
    }).catch(() => {
      context.commit(SET_LOADING, false);
    });
  },
};
const mutations = {
  [SET_PRODUCT_START_DATE_TEMPLATES](state, data) {
    state.productStartDateTemplates = data;
  },
  [SET_PRODUCT_START_DATE_TEMPLATE](state, data) {
    state.productStartDateTemplate = data;
  },
  [PUSH_NEW_PRODUCT_START_DATE_TEMPLATE](state, data) {
    state.productStartDateTemplates.push(data);
  },
  [PUSH_UPDATE_PRODUCT_START_DATE_TEMPLATE](state, data) {
    const position = state.productStartDateTemplates.indexOf(
      state.productStartDateTemplate
    );
    Vue.set(state.productStartDateTemplates, position, data);
  },
  [SET_LOADING](state, data) {
    state.isLoading = data;
  },
  [SET_EDITING_PRODUCT_START_DATE_TEMPLATE](state, data) {
    state.isEditing = data;
  },
  [CLEAN_PRODUCT_START_DATE_TEMPLATE](state) {
    Vue.set(state.productStartDateTemplate, {});
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
