// USA
export default {
  NEW: "New",
  EDIT: "Edit",
  DELETE: "Delete",
  RECORD: "Record",
  TITLE: "Product",
  TITLE_ALTERNATIVE: "Product",
  COURSE: "Course",
  COURSES: "Courses",
  ACCOMMODATION: "Lodging",
  ACCOMMODATIONS: "Lodgings",
  ADDON: "Service",
  ADDONS: "Services",
  LODGINGS: "Lodgings",
  PACKAGES: "Packages",
  ROOM: "Room",
  ROOMS: "Rooms",
  NAME: "Name",
  CATEGORY: "Category",
  SUB_CATEGORY: "Subcategory",
  LANGUAGE: "Language",
  LAST_UPDATED: "Last updated",
  ACTIONS: "Actions",
  DESCRIPTION: "Description",
  QUANTITY_STUDENT: "Quantity student",
  BEDROOM_TYPE: "Bedroom type",
  BATHROOM_TYPE: "Bathroom type",
  MEALS: "Meals",
  VISIBILITY: "Publish",
  TEMPORAL_SETTINGS: "Temporal settings",
  ADDITIONAL_SETTINGS: "Additional settings",
  QUANTITY_PERSONS: "Number of students per room",
  UPLOAD_IMAGES: "Upload images",
  CAMPUS_CALENDAR: "Use campus calendar",
  LODGING_CALENDAR: "Use Lodging calendar",
  CUSTOM_START_DATES: "Enter custom start dates",
  SET_START_DATES: "How would you like to set start dates?",
  SET_START_DATE_CREATE: "Create a template for dates",
  SET_START_DATE_MANUALLY: "Set arrival dates manually",
  INFO: {
    NAME: "",
    DESCRIPTION: "",
    VISIBILITY: "Publish",
  },
  TRANSLATIONS: "Translations",
  ROOM_INFO: "Room information",
  BEDS: "Bed size",
  START_DATE_AVAILABILITY: "Start date availability",
  PRICING_TEMPLATE: "Pricing template",
  MAINTENANCE_RATES: "Rates maintenance",
  APARTMENT: "Apartment",
  FEE: "Fee",
  FEES: "Fees",
  LODGING_TITLE: "Lodgings for this Course",
  LODGING_NAME: "Lodgings",
  SERVICE: {
    TRANSFER: "Indicate airport name and if the transfer is one way or return",
    ACTIVITIES:
      "If it is an activity package, indicate which activities are included, as well as their duration, frequency and timetable",
    INSURANCE: "Description of main coverages",
    TRAVEL: "Means of transport, place of departure, company, dates and times",
    MONITOR:
      "Indicate if you are traveling with a group, if you stay with the group for the entire stay, if you only accompany them on the flight or if you are a group leader from the organization at the destination.",
    EMERGENCY:
      "Explain how this service is provided: 24H call center, 24H cell phones managed by agency staff, provided by the group leaders, provided by the local organization.",
  },
  TYPE: "Type",
  ONE_MEAL: "One meal",
  TWO_MEALS: "Two meals",
  THREE_MEALS: "Three meals",
  NO_MEALS: "No meals",
  BREAKFAST: "Breakfast",
  BREAKFAST_DINNER: "Breakfast and dinner",
  BREAKFAST_LUNCH_DINNER: "Breakfast, lunch and dinner",
  BREAKFAST_COLD_PACKED_LUNCH_DINNER: "Breakfast, cold packed lunch and dinner",
  BREAKFAST_DINNER_WEEKEND_BREAKFAST_LUNCH_DINNER:
    "Breakfast and dinner + weekend Breakfast, lunch and dinner ",
  DURATION_TYPE: "Duration type",
  MINIMUM_AGE: "Minimum age",
  MAXIMUM_AGE: "Maximum age (Optional)",
  START_DATE: "First date",
  END_DATE: "Last date",
  MAXIMUM_DURATION: "Maximum duration (Optional)",
  MINIMUM_DURATION: "Minimum duration",
  MINIMUM_STUDENTS: "Average (Optional)",
  MAXIMUM_STUDENTS: "Maximum",
  MINIMUM_LEVEL_LANGUAGE: "Select minimum level of language required",
  SELECT_TARIFF_TEMPLATE: "Select fee template",
  CREATE_NEW_TARIFF: "Create new fee",
  CUSTOM_FEE_TEMPLATE: "Custom fee template",
  TIMETABLE: "Timetable",
  QUANTITY_LESSONS: "Number lessons per week",
  DURATION_LESSONS: "Duration of each lesson (minutes)",
  CANCELLATION_POLICY: "Cancellation policy",
  PRICES: "Prices",
  PRICE: "Price",
  AMOUNT: "Amount",
  SCHOOL_CALENDAR: "School calendar",
  CUSTOM_CALENDAR: "Custom calendar",
  LODGING: "Lodging",
  QUANTITY: "Quantity",
  CAMPUS: "Campus",
  TYPE_COURSE: "Course type",
  TYPE_COURSE_1: "Group classes",
  TYPE_COURSE_2: "Individual classes (One to one)",
  NOTE_ADDON:
    "Note: Services will be published in the students' area once they have made a booking",
  MESSAGE_NAME_COURSE: "Do not enter the number of lessons in the name",
  DURATION: "Duration",
  TYPE_DURATION: "Duration type",
  START_DATES_COURSE: "Edit courses start dates",
  NUMBER_OF_STUDENTS: "Number of students in the first class",
  FOR_THIS_COURSE: "Markets / Countries for this Course",
  RATING_THIS_COURSE: "Rating for this Course",
  SELECT_TIMETABLE: "Select Timetable",
  CUSTOM_TIMETABLE: "Make custom timetable",
  MAX_6_FILES: "Maximum 6 files",
  ROOM_SURFACE: "Room surface",
  BASEMENT: "The room is in a basement",
  WIDTH: "Width",
  LENGTH: "Length",
  UNITY: "Unity",
  BEGINNER: "Beginner (A1)",
  ELEMENTARY: "Elementary (A2)",
  INTERMEDIATE: "Intermediate (B1)",
  UPPER_INTERMEDIATE: "Upper intermediate (B2)",
  ADVANCED: "Advanced (C1)",
  PROFICIENCY: "Proficiency (C2)",
  PLEASE_VERIFY_FIELDS: "Please verify the fields",
  WEEKLY_CONTROL_DATES_LABEL: "Limit availability by date",
  WEEKLY_CONTROL_DATES_HELP:
    "Courses must start and end within these start dates",
  NOT_DELETED: "The product is associate with ",
  PACKAGE: "Packages",
  PROMOTION: "Promotions",
  FORCE_DELETE:
    "You are sure of force delete? (Associated packages and promotions will also be removed)",
  AMOUNT_WEEK: "Amount / Week",
  FEE_MESSAGE_ROOM:
    "Enter as Fee only concepts that are part of the price in a mandatory way.",
  GENERAL_DESCRIPTION: "General description",
  UNIT_ROOM_SURFACE: "Unit room surface",
  HELP_CATEGORY: "Information category",
  DESCRIPTION_SINGLE_ENSUITE: "Individual room with private bathroom",
  DESCRIPTION_SINGLE_SHARED_BATHROOM: "Individual room with shared bathroom",
  DESCRIPTION_DOUBLE_ENSUITE: "Double room with private bathroom",
  DESCRIPTION_DOUBLE_SHARED_BATHROOM: "Double room with shared bathroom",
  DESCRIPTION_SHARED_ENSUITE: "Shared room with private bathroom",
  DESCRIPTION_SHARED_BATHROOM: "Shared room with shared bathroom",
  DESCRIPTION_SINGLE_ENSUITE_APARTMENT:
    "Individual room with private bathroom in apartment in a residence",
  DESCRIPTION_SINGLE_SHARED_BATHROOM_APARTMENT:
    "Individual room with shared bathroom in apartment in a residence",
  DESCRIPTION_DOUBLE_ENSUITE_APARTMENT:
    "Double room with private bathroom in apartment in a residence",
  DESCRIPTION_DOUBLE_SHARED_BATHROOM_APARTMENT:
    "Double room with shared bathroom in apartment in a residence",
  DEFAULT_CATEGORY_DESCRIPTION: "First select a category, please.",
  TYPE_TARIFF: "Type tariff",
  REQUIRED_MARKETS_COUNTRIES: "Markets or countries are required.",
  SELECT_TYPE_PRICE: "Please select the pricing template.",
  SERVICE_INFO: "First, please select a type of service.",
  LEVEL_ASSESSED: "How is the student's level assessed?",
  LEVEL_EVALUATE_1:
    "The student communicates it and takes a test on the first day",
  LEVEL_EVALUATE_2: "Before starting the course",
  FIRST_DAY: "Describe what the student does on the first day of class",
};
