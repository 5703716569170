export default {
  BOTON_NEW: "New",
  PRICING_TEMPLATE: "Pricing Template",
  FROM: "From",
  TO: "To",
  END: "End",
  SELECT_IS_SUPPLEMENT: "Please, select season supplement if applicable",
  TAB1: "Active",
  TAB2: "Upcoming",
  TAB3: "Expired",
  DUPLICATED: "Pricing Template duplicated correctly",
  TABLE: {
    NAME: "Name",
    EXPIRES_ON: "Expires On",
    LAST_UPDATE: "Last Updated",
  },
  NO_MIN: "No Min.",
  NO_NAME: "(Without name)",
  NEW: {
    OPTIONS: "Template Type",
    FIELD_NAME: "Name",
    FIELD_NAME_ERROR: "Name is required.",
    BTN_FLEXIBLE: "Add Another Row",
    BTN_SIMPLE: "Add",
    SIMPLE_TITLE: "Enter pricing",
    FLEXIBLE_TITLE_TABLE_1: "Unit",
    FLEXIBLE_TITLE_TABLE_2: "Pricing Per Unit",
    FIXED_TITLE_TABLE_1: "Duration",
    FIXED_TITLE_TABLE_2: "Total Pricing",
    WITHOUT_TITLE: "Without Pricing",
    ONE_TITTLE: "One Time",
    UNIT_OPTIONS: {
      DEFAULT: "unit",
      OPTION1: "Days",
      OPTION2: "Week",
      OPTION3: "Months",
    },
    SIMPLE: {
      TITLE: "Simple",
      TEXT1: "No Duration based pricing variations.",
      TEXT2: "ex. $200 per week for any duration.",
    },
    FLEXIBLE: {
      TITLE: "Flexible",
      TEXT1: "Bookable for a range of durations.",
      TEXT2: "ex. 1-4 weeks for $200/week",
      TEXT3: "ex. +5 weeks for $175/week",
      CHARGET: "Charge in tiers",
      WHAT_IS: "What's this?",
      DESCRIPTION:
        "Ex. for a course that costs $250/week for 1-4 weeks, and $200/week for 5-9 weeks, we'll charge $250/week for the first 4 weeks, $200/week for the next 4 weeks and so on, regardless of the final duration booked.",
    },
    FIXED: {
      TITLE: "Fixed",
      TEXT1: "Pricing where duration is set.",
      TEXT2: "ex. 4 Weeks for $2000",
    },
    HELP: {
      PLEASE_SELECT_TEMPLATE_TYPE: "Please select an option",
      ONE_TIME: "A single charge ",
    },
  },
  EDIT: {
    TITLE: "Edit",
    BTN_DELETE: "Delete Pricing Template",
  },
  PEAK_SEASON_PRICING: "Optional: Peak Season Pricing",
  EXPIRY_DATE: {
    TITLE: "Add an Expiry Date",
    CHECK: "Does not expire",
    PLACHEHOLDER: "End date",
    TITLE_DESCRIPTION:
      "Select a Pricing Template to use after this one expires:",
    DESCRIPTION:
      "Courses using this Pricing Template will automatically change to the selected one, once the current pricing list expires. This is useful if you change pricing yearly and have your next year's pricing already created.",
    CHECK_NOT_ALLOW:
      "Do not allow bookings when the end date is after the expiry date.",
    SELECT_ITEM: "Select Item",
  },
  MARKET: {
    NAME: "Market Name",
    EXPIRY_DATE: "Expiry Date",
  },
  PEAK: {
    ADD: "Add Peak Season",
    REBUILT_TEMPLATE: "Rebuilt template",
    ADD_SUPPLEMENT: "Add supplement",
    ADD_PRICE: "Add price",
  },
  ERROR: {
    SELECT_PRODUCT_PRICE: "Please, select a Template Type",
  },
  ADD_MARKET: "Add market",
  EDIT_MARKET: "Edit market",
  HELP_TEXT:
    "Here you will associate a rate to a market and / or a country, this rate will be applied as the case may be.",
  MARKETS: "Markets",
  COUNTRIES: "Countries",
  PRODUCT_PRICING_TEMPLATE: "Product Pricing Template",
  TYPE: "Type",
  NAME: "Name",
  COUNTRY: "Country",
  NO_NAME_TABLE: "No name",
};
