import Vue from "vue";
import store from "@/core/services/store";
import { CLEAR_DATA_LOGOUT } from "@/core/services/store/auth.module";

const errorHandler = async error => {
  const instance = new Vue({});
  error.config.errorHandler = false;

  if (typeof error.response.data.errors !== "undefined") {
    for (let field of Object.keys(error.response.data.errors)) {
      instance.$bvToast.toast(error.response.data.errors[field][0], {
        title: "Error!",
        variant: "danger",
        solid: true,
      });
    }
    return error;
  }

  if (
    typeof error.response.data !== "undefined" &&
    error.response.data.message
  ) {
    instance.$bvToast.toast(error.response.data.message, {
      title: "Error!",
      variant: "danger",
      solid: true,
    });
    if (error.response.status === 401) {
      await store.dispatch(CLEAR_DATA_LOGOUT);
      window.location.href = "/";
    }
  } else {
    if (typeof error.response.data !== "undefined") {
      for (let field of Object.keys(error.response.data)) {
        instance.$bvToast.toast(error.response.data[field][0], {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      }
    } else {
      instance.$bvToast.toast(error.toString(), {
        title: "Error!",
        variant: "danger",
        solid: true,
      });
    }
  }
  return error;
};

export default errorHandler;
