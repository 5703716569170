//Mutations
export const SET_ROOM_TYPES = "setRoomTypes";

const state = {
  roomTypes: [],
};

const getters = {
  roomTypes(state) {
    return state.roomTypes;
  },
};

const mutations = {
  [SET_ROOM_TYPES](state, data) {
    state.roomTypes = data;
  },
};

export default {
  state,
  mutations,
  getters,
};
