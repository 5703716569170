//Mutations
export const SET_STARTDATES = "setstartDates";

const state = {
  startDates: [],
};

const getters = {
  startDates(state) {
    return state.startDates;
  },
};

const mutations = {
  [SET_STARTDATES](state, data) {
    const campusId = this.getters.campus.campusId;
    const dataFiltered = data.filter(startDate => {
      return startDate.campusId === campusId;
    });
    state.startDates = dataFiltered;
  },
};

export default {
  state,
  mutations,
  getters,
};
