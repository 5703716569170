// Actions
export const STORE_MAINTENANCE_RATES = "storeMaintenanceRates";

// Mutations
export const SET_MAINTENANCE_RATES = "setMaintenanceRates";

const state = {
  maintenanceRates: [],
};

const getters = {
  maintenanceRates(state) {
    return state.maintenanceRates;
  },
};

const actions = {
  [STORE_MAINTENANCE_RATES](context, formData) {
    context.commit(SET_MAINTENANCE_RATES, formData);
  },
};

const mutations = {
  [SET_MAINTENANCE_RATES](state, formData) {
    state.maintenanceRates = formData;
  },
};

export default { state, getters, actions, mutations };
