import ApiService from "@/core/services/api.service";

const ENDPOINT = "api/v1/notifications";

//Actions
export const GET_ALL_NOTIFICATIONS = "getAllNotifications";
export const MARK_AS_READ = "markAsRead";

//Mutations
export const SET_NOTIFICATIONS = "setNotifications";
export const SET_LOADING = "setLoading";
export const SET_EDITING = "setEditing";

const state = {
  notifications: [],
  isLoading: false,
  isEditing: false,
};

const getters = {
  notifications(state) {
    return state.notifications;
  },
};

const actions = {
  [GET_ALL_NOTIFICATIONS](context, config) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.query(ENDPOINT, config)
        .then(({ data }) => {
          context.commit(SET_NOTIFICATIONS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  [MARK_AS_READ](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post(ENDPOINT, data)
        .then(({ data }) => {
          context.commit(SET_NOTIFICATIONS, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};
const mutations = {
  [SET_NOTIFICATIONS](state, data) {
    state.notifications = data;
  },
  [MARK_AS_READ](state, data) {
    state.notifications = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
