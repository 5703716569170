import Vue from "vue";
import ApiService from "@/core/services/api.service";
import { SET_CAMPUS_PAGE } from "./campus_page.module";
//Actions
export const GET_ALL_CAMPUSES = "getAllCampuses";

export const STORE_CAMPUS = "storeCampus";
export const UPDATE_CAMPUS = "updateCampus";
export const UPDATE_CAMPUS_AVAILABILITY = "updateCampusAvailability";
export const UPDATE_CAMPUS_CALENDAR_STARTDATE = "updateCampusCalendarStartDate";
export const SET_CAMPUS_CALENDAR_STARTDATE = "setCampusCalendarStartDate";
export const SET_CAMPUS_AVAILABILITY = "setCampusAvailability";
export const SET_CAMPUS_SELECTED = "setCampusSelected";

export const UPDATE_HOLIDAYS = "updateHolidays";
export const DELETE_CAMPUS = "deleteCampus";
export const CANCEL_CAMPUS = "cancelCampus";

//Mutations
export const SET_CAMPUSES = "setCampuses";
export const SET_CAMPUS = "setCampus";
export const PUSH_NEW_CAMPUS = "pushNewCampus";
export const PUSH_UPDATE_CAMPUS = "pushUpdateCampus";
export const PUSH_UPDATE_HOLIDAYS = "pushUpdateHolidays";
export const CLEAN_CAMPUS = "cleanCampus";

export const UPDATE_TIMETABLE_TEMPLATE_CAMPUS = "updateTimetableTemplateCampus";
export const STORE_TIMETABLE_TEMPLATE_CAMPUS = "storeTimetableTemplateCampus";
export const REMOVE_PRODUCT_TIMETABLE_TEMPLATE_CAMPUS =
  "removeProductTimetableTemplateCampus";
export const UPDATE_COURSE_START_DATE_TEMPLATE_CAMPUS =
  "updateCourseSstartDateTemplateCampus";
export const REMOVE_ONE_CAMPUS = "removeOneCampus";
export const SET_LOADING = "setLoading";
export const SET_EDITING = "setEditing";

const state = {
  campuses: [],
  campus: {},
  isLoading: false,
  isEditing: false,
};

const getters = {
  campuses(state) {
    return state.campuses;
  },
  getCampus(state, rootState) {
    if (typeof state.campus === "undefined") return false;
    let currencies = rootState.Currencies.filter(currency => {
      if (currency.currencyId === state.campus.currencyId) return true;
      else return false;
    });
    let campus = state.campus;
    if (currencies.length > 0) {
      campus.symbol = currencies[0].symbol;
      campus.before = currencies[0].before;
    } else {
      campus.symbol = "";
      campus.before = true;
    }
    campus.date_format =
      campus.date_format_id === 1 ? "DD/MM/YYYY" : "MM/DD/YYYY";
    return campus;
  },
  campus(state, rootState) {
    let campus = {};
    if (state.campus) {
      campus = state.campus;
      let currencies = rootState.Currencies.filter(currency => {
        if (currency.currencyId === state.campus.currencyId) return true;
        else return false;
      });
      if (currencies.length > 0) {
        campus.symbol = currencies[0].symbol;
        campus.before = currencies[0].before;
      } else {
        campus.symbol = "";
        campus.before = true;
      }
    } else {
      campus.symbol = "";
      campus.before = true;
    }
    campus.date_format =
      campus.date_format_id === 1 ? "DD/MM/YYYY" : "MM/DD/YYYY";
    return campus;
  },
  isLoadingCampus(state) {
    return state.isLoading;
  },
  isEditingCampus(state) {
    return state.isEditing;
  },
};

const actions = {
  [GET_ALL_CAMPUSES](context, config) {
    context.commit("setIsVisibleLoader", true);
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.query("api/v1/campus", config)
        .then(({ data }) => {
          context.commit(SET_CAMPUSES, data.data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [STORE_CAMPUS](context, json) {
    ApiService.setHeader();
    context.commit(SET_LOADING, true);
    return new Promise(resolve => {
      ApiService.post("api/v1/campus", json)
        .then(data => {
          context.commit(PUSH_NEW_CAMPUS, data.data.campus);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [UPDATE_CAMPUS](context, data) {
    ApiService.setHeader();
    context.commit(SET_LOADING, true);
    return new Promise(resolve => {
      ApiService.put(`api/v1/campus/${data.campusId}`, data)
        .then(({ data }) => {
          context.commit(PUSH_UPDATE_CAMPUS, data.campus);
          context.commit(SET_CAMPUS_PAGE, data.campus);
          context.commit(SET_LOADING, false);
          context.commit(SET_EDITING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [UPDATE_CAMPUS](context, data) {
    ApiService.setHeader();
    context.commit(SET_LOADING, true);
    return new Promise(resolve => {
      ApiService.put(`api/v1/campus/${data.campusId}`, data)
        .then(({ data }) => {
          context.commit(PUSH_UPDATE_CAMPUS, data.campus);
          context.commit(SET_CAMPUS_PAGE, data.campus);
          context.commit(SET_LOADING, false);
          context.commit(SET_EDITING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [UPDATE_CAMPUS_AVAILABILITY](context, modifications) {
    ApiService.setHeader();
    context.commit(SET_LOADING, true);
    context.commit(SET_EDITING, true);
    return new Promise(resolve => {
      ApiService.put(
        `api/v1/campus/availability/${modifications.campusId}`,
        modifications
      )
        .then(({ data }) => {
          context.commit(SET_CAMPUS_AVAILABILITY, modifications);
          context.commit(SET_LOADING, false);
          context.commit(SET_EDITING, false);
          resolve(data);
          return data;
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [UPDATE_CAMPUS_CALENDAR_STARTDATE](context, modifications) {
    ApiService.setHeader();
    context.commit(SET_LOADING, true);
    context.commit(SET_EDITING, true);
    return new Promise(resolve => {
      ApiService.put(
        `api/v1/campus/startdate/${modifications.campusId}`,
        modifications
      )
        .then(({ data }) => {
          context.commit(SET_LOADING, false);
          context.commit(SET_EDITING, false);
          context.commit(SET_CAMPUS_CALENDAR_STARTDATE, modifications);
          resolve(data);
          return data;
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [UPDATE_HOLIDAYS](context, data) {
    ApiService.setHeader();
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put(`api/v1/holiday/${data.campusId}`, { holidays: data.data })
        .then(data => {
          context.commit(PUSH_UPDATE_HOLIDAYS, data.data.campus);
          context.commit(SET_LOADING, false);
          context.commit(SET_EDITING, false);
          resolve(data.data.campus);
        })
        .catch(error => {
          context.commit(SET_LOADING, false);
          reject(error);
        });
    });
  },

  [DELETE_CAMPUS](context, id) {
    ApiService.setHeader();
    context.commit(SET_LOADING, true);
    return new Promise(resolve => {
      ApiService.delete(`api/v1/campus/${id}`)
        .then(({ data }) => {
          context.commit(REMOVE_ONE_CAMPUS, data.campus);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [CANCEL_CAMPUS](context) {
    context.commit(SET_LOADING, true);
    return new Promise(() => {
      context.commit(CLEAN_CAMPUS);
      context.commit(SET_LOADING, false);
    }).catch(() => {
      context.commit(SET_LOADING, false);
    });
  },
  [SET_CAMPUS_SELECTED](context, campusId) {
    ApiService.setHeader();
    return new Promise(resolve => {
      ApiService.post("api/v1/login/campus", { campusId }).then(({ data }) => {
        context.commit(SET_CAMPUS, data.data.Campus[0]);

        const {
          CampusAccommodation,
          ProductStartDateTemplate,
          Market,
        } = data.variableData;

        context.commit("setAllAccommodation", CampusAccommodation);
        if (CampusAccommodation.length > 0)
          context.commit("setAccommodation", CampusAccommodation[0]);
        context.commit("setMarkets", Market);
        context.commit(
          "setProductStartDateTemplates",
          ProductStartDateTemplate
        );
        resolve(data);
      });
    });
  },
};
const mutations = {
  [SET_CAMPUSES](state, data) {
    state.campuses = data;
  },
  [SET_CAMPUS](state, data) {
    state.campus = data;
  },
  [SET_CAMPUS_AVAILABILITY](state, data) {
    state.campuses.forEach((campus, index) => {
      if (campus.campusId === data.campusId) {
        if (typeof data.available365 !== "undefined") {
          campus.available365 = data.available365;
          state.campuses.splice(index, 1, campus);
        }
      }
    });
    if (state.campus.campusId === data.campusId) {
      if (typeof data.available365 !== "undefined") {
        Vue.set(state.campus, "available365", data.available365);
      }
    }
  },
  [SET_CAMPUS_CALENDAR_STARTDATE](state, data) {
    state.campuses.forEach((campus, index) => {
      if (campus.campusId === data.campusId) {
        if (typeof data.courseStartDateTemplateId !== "undefined") {
          campus.courseStartDateTemplateId = data.courseStartDateTemplateId;
          state.campuses.splice(index, 1, campus);
        }
        if (typeof data.roomStartDateTemplateId !== "undefined") {
          campus.roomStartDateTemplateId = data.roomStartDateTemplateId;
          state.campuses.splice(index, 1, campus);
        }
      }
    });
    if (state.campus.campusId === data.campusId) {
      if (typeof data.courseStartDateTemplateId !== "undefined") {
        Vue.set(
          state.campus,
          "courseStartDateTemplateId",
          data.courseStartDateTemplateId
        );
      }
      if (typeof data.roomStartDateTemplateId !== "undefined") {
        Vue.set(
          state.campus,
          "roomStartDateTemplateId",
          data.roomStartDateTemplateId
        );
      }
    }
  },
  [PUSH_NEW_CAMPUS](state, data) {
    state.campuses.push(data);
    if (state.campuses.length === 1) {
      state.campus = data;
    }
  },
  [PUSH_UPDATE_CAMPUS](state, data) {
    let position;
    state.campuses.forEach((item, index) => {
      if (item.campusId === data.campusId) {
        position = index;
      }
    });
    Vue.set(state.campuses, position, data);
    if (state.campus.campusId === data.campusId) {
      state.campus = data;
    }
  },
  [UPDATE_COURSE_START_DATE_TEMPLATE_CAMPUS](state, data) {
    if (state.campus.campusId === data.campusId) {
      state.campus.start_date_templates.forEach(
        (start_date_template, index) => {
          if (
            start_date_template.productStartDateTemplateId ===
            data.productStartDateTemplateId
          ) {
            Vue.set(state.campus.start_date_templates, index, data);
          }
        }
      );
    }
    state.campuses.forEach((campus, index) => {
      if (campus.campusId === data.campusId) {
        campus.start_date_templates.forEach((start_date_template, index2) => {
          if (
            start_date_template.productStartDateTemplateId ===
            data.productStartDateTemplateId
          ) {
            Vue.set(state.campuses[index].start_date_templates, index2, data);
          }
        });
      }
    });
  },
  [UPDATE_TIMETABLE_TEMPLATE_CAMPUS](state, data) {
    if (parseInt(state.campus.campusId) === parseInt(data.campusId)) {
      state.campus.product_timetable_templates.forEach(
        (product_timetable_template, index) => {
          if (
            parseInt(product_timetable_template.productTimetableTemplateId) ===
            parseInt(data.productTimetableTemplateId)
          ) {
            Vue.set(state.campus.product_timetable_templates, index, data);
            Vue.set(
              state.campus.product_timetable_templates[index],
              "product_timetable_template_items",
              data.product_timetable_template_items
            );
          }
        }
      );
    }
    state.campuses.forEach((campus, index) => {
      if (parseInt(campus.campusId) === parseInt(data.campusId)) {
        if (campus.product_timetable_templates) {
          campus.product_timetable_templates.forEach(
            (product_timetable_template, index2) => {
              if (
                parseInt(
                  product_timetable_template.productTimetableTemplateId
                ) === parseInt(data.productTimetableTemplateId)
              ) {
                Vue.set(
                  state.campuses[index].product_timetable_templates,
                  index2,
                  data
                );
              }
            }
          );
        }
      }
    });
  },
  [REMOVE_PRODUCT_TIMETABLE_TEMPLATE_CAMPUS](state, data) {
    var index = -1;
    if (state.campus.campusId === data.campusId) {
      index = -1;
      state.campus.product_timetable_templates.forEach(
        (product_timetable_template, i) => {
          if (
            parseInt(product_timetable_template.productTimetableTemplateId) ===
            parseInt(data.productTimetableTemplateId)
          ) {
            index = i;
          }
        }
      );
      state.campus.product_timetable_templates.splice(index, 1);
    }
    state.campuses.forEach((sch, index1) => {
      if (parseInt(sch.campusId) === parseInt(data.campusId)) {
        index = -1;
        sch.product_timetable_templates.forEach(
          (product_timetable_template, index2) => {
            if (
              parseInt(
                product_timetable_template.productTimetableTemplateId
              ) === parseInt(data.productTimetableTemplateId)
            ) {
              index = index2;
            }
          }
        );
        state.campuses[index1].product_timetable_templates.splice(index, 1);
      }
    });
  },
  [STORE_TIMETABLE_TEMPLATE_CAMPUS](state, data) {
    if (state.campus.campusId === data.campusId) {
      if (typeof state.campus.product_timetable_templates !== "undefined")
        state.campus.product_timetable_templates.push(data);
    }
    state.campuses.forEach((sch, index) => {
      if (sch.campusId === data.campusId) {
        if (
          typeof state.campuses[index].product_timetable_templates !==
          "undefined"
        )
          state.campuses[index].product_timetable_templates.push(data);
      }
    });
  },
  [PUSH_UPDATE_HOLIDAYS](state, data) {
    var campus = Vue.util.extend({}, state.campus);
    campus.campus_holidays = data.campus_holidays;
    state.campus = campus;
    state.campuses.forEach((index, sc) => {
      campus.campus_holidays = data.campus_holidays;
      if (sc.campusId === campus.campusId) {
        state.campuses.splice(index, 1, campus);
      }
    });
    return campus;
  },
  [REMOVE_ONE_CAMPUS](state, data) {
    state.campuses.forEach((item, index) => {
      if (item.campusId === data.campusId) {
        state.campuses.splice(index, 1);
        //If we remove one campus, we should be sure that is not the current selected campus.
        if (state.campus.campusId === data.campusId) {
          if (state.campuses.length > 0) state.campus = state.campuses[0];
          else state.campus = null;
        }
      }
    });
  },
  [SET_LOADING](state, data) {
    state.isLoading = data;
  },
  [CLEAN_CAMPUS](state) {
    Vue.set(state.campus, {});
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
