import Vue from "vue";
import ApiService from "@/core/services/api.service";
//Actions
export const GET_ALL_CATEGORIES = "getAllCategories";
export const GET_CATEGORY = "getCategory";
export const TRANSLATE_ALL_CATEGORIES = "translateAllCategories";
export const STORE_CATEGORY = "storeCategory";
export const UPDATE_CATEGORY = "updateCategory";
export const DELETE_CATEGORY = "deleteCategory";
export const CANCEL_CATEGORY = "cancelCategory";

//Mutations
export const SET_CATEGORIES = "setCategories";
export const SET_CATEGORY = "setCategory";
export const SET_TRANSLATE_CATEGORIES = "setTranslateCategories";
export const PUSH_NEW_CATEGORY = "pushCategory";
export const PUSH_UPDATE_CATEGORY = "pushCategory";
export const REMOVE_ONE_CATEGORY = "pushCategory";
export const SET_LOADING = "setLoading";
export const SET_EDITING = "setEditing";
export const CLEAN_CATEGORY = "cleancategory";

const state = {
  categories: [],
  category: {},
  isLoading: false,
  isEditing: false,
};

const getters = {
  categories(state) {
    return state.categories?.sort((a, b) => {
      let compare = 0;
      if (a.order > b.order) {
        compare = 1;
      } else if (b.order > a.order) {
        compare = -1;
      }
      return compare;
    });
  },
  category(state) {
    return state.category;
  },
  isLoadingCategory(state) {
    return state.isLoading;
  },
  isEditingCategory(state) {
    return state.isEditing;
  },
};

const actions = {
  [GET_ALL_CATEGORIES](context) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.query("api/v1/category", {})
        .then(({ data }) => {
          context.commit(SET_CATEGORIES, data);
          context.dispatch(TRANSLATE_ALL_CATEGORIES, context);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_LOADING, false);
        });
    });
  },

  [GET_CATEGORY](context, id) {
    context.commit(SET_LOADING, true);
    return new Promise(resolve => {
      ApiService.get(`api/v1/category/${id}`)
        .then(({ data }) => {
          context.commit(SET_CATEGORY, data);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [TRANSLATE_ALL_CATEGORIES](context) {
    const languageIdSelected = context.getters.languageId;
    context.commit(SET_TRANSLATE_CATEGORIES, languageIdSelected);
  },
  [STORE_CATEGORY](context, data) {
    context.commit(SET_LOADING, true);
    return new Promise(resolve => {
      ApiService.post("api/v1/category", data)
        .then(({ data }) => {
          context.commit(PUSH_NEW_CATEGORY, data);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [UPDATE_CATEGORY](context, data) {
    context.commit(SET_LOADING, true);
    return new Promise(resolve => {
      ApiService.put(`api/v1/category/${data.id}`, data)
        .then(({ data }) => {
          context.commit(PUSH_UPDATE_CATEGORY, data);
          context.commit(SET_LOADING, false);
          context.commit(SET_EDITING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [DELETE_CATEGORY](context, id) {
    context.commit(SET_LOADING, true);
    return new Promise(resolve => {
      ApiService.delete(`api/v1/category/${id}`)
        .then(({ data }) => {
          context.commit(REMOVE_ONE_CATEGORY, data);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [CANCEL_CATEGORY](context) {
    context.commit(SET_LOADING, true);
    return new Promise(() => {
      context.commit(CLEAN_CATEGORY);
      context.commit(SET_LOADING, false);
    }).catch(() => {
      context.commit(SET_LOADING, false);
    });
  },
};
const mutations = {
  [SET_CATEGORIES](state, data) {
    state.categories = data;
  },
  [SET_CATEGORY](state, data) {
    state.category = data;
  },
  [SET_TRANSLATE_CATEGORIES](state, languageId) {
    const categoriesTranslated = state.categories.map(category => {
      const translate = category.category_languages.find(language => {
        return language.languageId === languageId;
      });

      category.nameTranslated = translate.name;
      return category;
    });
    state.categories = categoriesTranslated;
  },
  [PUSH_NEW_CATEGORY](state, data) {
    state.categories.push(data);
  },
  [PUSH_UPDATE_CATEGORY](state, data) {
    const position = state.categories.indexOf(state.category);
    Vue.set(state.categories, position, data);
  },
  [REMOVE_ONE_CATEGORY](state, data) {
    state.categories.splice(state.categories.indexOf(data), 1);
  },
  [SET_LOADING](state, data) {
    state.isLoading = data;
  },
  [SET_EDITING](state, data) {
    state.isEditing = data;
  },
  [CLEAN_CATEGORY](state) {
    Vue.set(state.category, {});
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
