import Vue from "vue";
import ApiService from "@/core/services/api.service";

export const ENDPOINT = "api/v1/promotions";
export const ENDPOINT_DETAILS = "api/v1/promotions-details";

//Actions
export const GET_PROMOTIONS = "getPromotions";
export const GET_PROMOTION = "getPromotion";
export const STORE_PROMOTION = "storePromotion";
export const STORE_PROMOTION_DETAIL = "storePromotionDetail";
export const UPDATE_PROMOTION = "updatePromotion";
export const DELETE_PROMOTION = "deletePromotion";
export const LOAD_ADDITIONAL_SETTINGS = "loadAdditionalSettings";
export const GET_ELIGIBLE_PRODUCTS = "getEligibleProducts";

//Mutations
export const SET_PROMOTIONS = "setPromotions";
export const SET_PROMOTIONS_UPDATED = "setPromotionsUpdated";
export const SET_PROMOTION = "setPromotion";
export const SET_ADDITIONAL_SETTINGS = "setAdditionalSettings";
export const ADD_PROMOTION = "addPromotion";
export const REMOVE_PROMOTION = "removePromotion";
export const SET_EDIT = "setEdit";
export const SET_DUPLICATE = "setDuplicate";
export const CLEAN_PROMOTION = "cleanPromotion";
export const SET_MESSAGE = "setMessage";
export const SET_ELIGIBLE_PRODUCTS = "setEligibleProducts";

const state = {
  promotions: [],
  promotion: {},
  additionalSettings: {},
  message: {},
  eligibleProducts: {},
};

const getters = {
  promotions(state) {
    return state.promotions;
  },
  totalPromotions(state) {
    return state.totalPromotions;
  },
  promotion(state) {
    return state.promotion;
  },
  isLoadingPromotion(state) {
    return state.isLoading;
  },
  isEditPromotion(state) {
    return state.isEdit;
  },
  isDuplicatePromotion(state) {
    return state.isDuplicate;
  },
  messagePromotion(state) {
    return state.message;
  },
  eligibleProducts(state) {
    return state.eligibleProducts;
  },
};

const actions = {
  [GET_PROMOTIONS](context, config) {
    context.commit("setIsVisibleLoader", true);
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      const campus = context.getters.campus;
      ApiService.query(`${ENDPOINT}?campusId=${campus.campusId}`, config)
        .then(({ data }) => {
          context.commit(SET_PROMOTIONS, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [GET_PROMOTION](context, id) {
    context.commit("setIsVisibleLoader", true);
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.query(`${ENDPOINT}/${id}`)
        .then(({ data }) => {
          context.commit(SET_PROMOTION, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [GET_ELIGIBLE_PRODUCTS](context, ids) {
    context.commit("setIsVisibleLoader", true);
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.query(`${ENDPOINT}/get_products_list?eligibleProducts=${ids}`)
        .then(({ data }) => {
          context.commit(SET_ELIGIBLE_PRODUCTS, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [STORE_PROMOTION](context, formData) {
    const campusId = context.getters.campus.campusId;
    const dataSend = {
      ...formData,
      campusId,
    };
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      context.commit("setIsVisibleLoader", true);
      context.commit(SET_MESSAGE, {});
      ApiService.post(ENDPOINT, dataSend)
        .then(({ data }) => {
          context.commit(ADD_PROMOTION, data);
          context.commit(SET_EDIT, true);
          context.commit("setIsVisibleLoader", false);
          context.commit(SET_MESSAGE, {
            status: 200,
            message: "Promotion registered successfully.",
          });
          resolve(data);
        })
        .catch(response => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [STORE_PROMOTION_DETAIL](context, data) {
    ApiService.setHeader();
    context.commit("setIsVisibleLoader", true);
    return new Promise(resolve => {
      ApiService.post(ENDPOINT_DETAILS, data)
        .then(({ data }) => {
          context.dispatch(GET_PROMOTION, data.promotionId);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(() => {
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [UPDATE_PROMOTION](context, formData) {
    const campusId = context.getters.campus.campusId;
    const dataSend = {
      ...formData,
      campusId,
    };
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      context.commit("setIsVisibleLoader", true);
      ApiService.put(`${ENDPOINT}/${formData.promotionId}`, dataSend)
        .then(({ data }) => {
          context.commit(SET_PROMOTION, data);
          context.commit("setIsVisibleLoader", false);
          context.commit(SET_MESSAGE, {
            status: 200,
            message: "Promotion updated successfully.",
          });
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [DELETE_PROMOTION](context, dataForm) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      context.commit("setIsVisibleLoader", true);
      ApiService.delete(`${ENDPOINT}/${dataForm.promotionId}`, dataForm)
        .then(({ data }) => {
          context.commit(REMOVE_PROMOTION, dataForm);
          context.commit(SET_MESSAGE, {
            status: 200,
            message: "Promotion deleted successfully.",
          });
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [LOAD_ADDITIONAL_SETTINGS](context, data) {
    context.commit(SET_ADDITIONAL_SETTINGS, data);
  },
};

const mutations = {
  [SET_PROMOTIONS](state, data) {
    state.promotions = data;
  },
  [SET_PROMOTION](state, data) {
    state.promotion = data;
  },
  [SET_PROMOTIONS_UPDATED](state, data) {
    const position = state.promotions.indexOf(state.promotion);
    Vue.set(state.promotions, position, data);
  },
  [ADD_PROMOTION](state, data) {
    state.promotions.push(data);
  },
  [REMOVE_PROMOTION](state, data) {
    const promotionFinded = state.promotions.find(promotion => {
      if (promotion.productId === data.productId) {
        return true;
      }
    });
    const position = state.promotions.indexOf(promotionFinded);
    state.promotions.splice(position, 1);
  },
  [SET_EDIT](state, data) {
    state.isEdit = data;
  },
  [SET_DUPLICATE](state, data) {
    state.isDuplicate = data;
  },
  [CLEAN_PROMOTION](state) {
    Vue.set(state.promotion, {});
  },
  [SET_ADDITIONAL_SETTINGS](state, data) {
    state.additionalSettings = data;
  },
  [SET_MESSAGE](state, data) {
    state.message = data;
  },
  [SET_ELIGIBLE_PRODUCTS](state, data) {
    state.eligibleProducts = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
