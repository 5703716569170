// Actions
export const STORE_APARTMENT = "storeApartment";

// Mutations
export const SET_APARTMENT = "setApartment";

const state = {
  apartment: {},
};

const getters = {
  apartment(state) {
    return state.apartment;
  },
};

const actions = {
  [STORE_APARTMENT](context, formData) {
    context.commit(SET_APARTMENT, formData);
  },
};

const mutations = {
  [SET_APARTMENT](state, formData) {
    state.apartment = formData;
  },
};

export default { state, getters, actions, mutations };
