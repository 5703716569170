export default {
  TITLE: "Coupons",
  TITLE2: "Search Courses",
  TITLE3: "Courses Selected",
  KEY: "Key",
  DISCOUNT_TYPE: "Discount Type",
  DISCOUNT: "Discount",
  PERCENTAGE: "Percentage",
  PERMANENT: "Permanent",
  DATE_START: "Start date",
  DATE_END: "End date",
  DATE_START_COURSE: "Course start date",
  DATE_START_END: "Course end date",
  AMOUNT_MINIMUM: "Minimum amount",
  LIMIT: "Limit",
  COURSES: "Courses",
  TEXT: "Text",
  SEARCH_BY: "Search By",
  COURSE_NAME: "Course Name",
  SEARCH: "Search",
  MIN_WEAK: "Min Week",
  MIN_LEASON: "Min Leason",
  COURSE_CATEGORY: "Course Category",
};
