import ApiService from "@/core/services/api.service";

//Actions
export const GET_ALL_COUPONS = "getAllCoupons";
export const GET_COUPON = "getCoupon";
export const STORE_COUPON = "storeCoupon";
export const UPDATE_COUPON = "updateCoupon";
export const DELETE_COUPON = "deleteCoupon";

//Mutations
export const SET_COUPONS = "setCoupons";
export const SET_COUPON = "setCoupon";
export const SET_NEW_COUPON = "setNewCoupon";
export const SET_UPDATE_COUPON = "setUpdateCoupon";
export const SET_DELETE_COUPON = "setDeleteCoupon";
export const SET_LOADING = "setLoading";

const PATH_COUPON = "api/v1/coupon";

const state = {
  coupons: [],
  coupon: [],
};

const getters = {
  Coupons(state) {
    return state.coupons;
  },
  Coupon(state) {
    return state.coupon;
  },
};

const actions = {
  [GET_ALL_COUPONS](context) {
    context.commit("setIsVisibleLoader", true);
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(PATH_COUPON)
        .then(({ data }) => {
          context.commit(SET_COUPONS, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },

  [GET_COUPON](context, id) {
    context.commit(SET_LOADING, true);
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`${PATH_COUPON}/${id}`)
        .then(({ data }) => {
          context.commit(SET_COUPON, data);
          context.commit(SET_LOADING, false);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },

  [STORE_COUPON](context, json) {
    ApiService.setHeader();
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post(PATH_COUPON, json)
        .then(data => {
          if (typeof data.data !== "undefined") {
            context.commit(SET_NEW_COUPON, data.data.coupon);
            context.commit(SET_LOADING, false);
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_LOADING, false);
        });
    });
  },

  [UPDATE_COUPON](context, data) {
    ApiService.setHeader();
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put(`${PATH_COUPON}/${data.couponId}`, data)
        .then(({ data }) => {
          context.commit(SET_UPDATE_COUPON, data);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_LOADING, false);
        });
    });
  },

  [DELETE_COUPON](context, id) {
    ApiService.setHeader();
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.delete(`${PATH_COUPON}/${id}`)
        .then(data => {
          context.commit(SET_DELETE_COUPON, id);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_LOADING, false);
        });
    });
  },
};

const mutations = {
  [SET_COUPONS](state, data) {
    state.coupons = data;
  },

  [SET_COUPON](state, data) {
    state.coupon = data;
  },

  [SET_NEW_COUPON](state, data) {
    state.coupons.push(data);
  },

  [SET_UPDATE_COUPON](state, data) {
    const { coupon } = data;
    let position;
    state.coupons.forEach((item, index) => {
      if (Number(item.couponId) === Number(coupon.couponId)) {
        position = index;
      }
    });
    state.coupons[position] = coupon;
    state.coupon = coupon;
  },

  [SET_DELETE_COUPON](state, id) {
    state.coupons.forEach((item, index) => {
      if (Number(item.couponId) === Number(id)) {
        state.coupons.splice(index, 1);
      }
    });
  },

  [SET_LOADING](state, data) {
    state.isLoading = data;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
