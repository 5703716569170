// Actions
export const STORE_BEDS = "storeBed";

// Mutations
export const SET_BEDS = "setBed";

const state = {
  beds: [],
};

const getters = {
  beds(state) {
    return state.beds;
  },
};

const actions = {
  [STORE_BEDS](context, formData) {
    context.commit(SET_BEDS, formData);
  },
};

const mutations = {
  [SET_BEDS](state, formData) {
    state.beds = formData;
  },
};

export default { state, getters, actions, mutations };
