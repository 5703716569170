// Actions
export const STORE_ROOM_INFO = "storeRoomInfo";

// Mutations
export const SET_ROOM_INFO = "setRoomInfo";

const state = {
  roomInfo: {},
};

const getters = {
  roomInfo(state) {
    return state.roomInfo;
  },
};

const actions = {
  [STORE_ROOM_INFO](context, formData) {
    context.commit(SET_ROOM_INFO, formData);
  },
};

const mutations = {
  [SET_ROOM_INFO](state, formData) {
    state.roomInfo = formData;
  },
};

export default { state, getters, actions, mutations };
