import ApiService from "@/core/services/api.service";

const path_review = "api/v1/campus/review";
//Actions
export const GET_ALL_REVIEWS = "getAllReviews";
export const GET_REVIEW = "getReview";
export const STORE_REVIEW = "storeReview";
export const UPDATE_REVIEW = "updateReview";
export const GET_ALL_REVIEWS_CUSTOM = "getAllReviewsCustom";
export const UPDATE_REVIEW_AGREE = "updateReviewAgree";
export const UPDATE_REVIEW_DENY = "updateReviewDeny";
export const UPDATE_STATUS_REVIEW = "updateStatusReview";

//Mutations
export const SET_REVIEWS = "setReviews";
export const SET_REVIEW = "setReview";
export const PUSH_NEW_REVIEW = "pushReview";
export const SET_LIST_REVIEW_UPDATED = "removeReviewUpdated";

const state = {
  reviews: [],
};

const getters = {
  reviews(state) {
    state.reviews.map(item => {
      if (item.review) {
        item.reviewTable = String(item.review).slice(0, 20) + "...";
      }
      return item;
    });
    return state.reviews;
  },
};

const actions = {
  [GET_ALL_REVIEWS](context, id) {
    context.commit("setIsVisibleLoader", true);
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`${path_review}/${id}`)
        .then(({ data }) => {
          context.commit(SET_REVIEWS, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },

  [GET_REVIEW](context, token) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`${path_review}/get/${token}`)
        .then(({ data }) => {
          context.commit(SET_REVIEW, data);
          resolve(data);
          return data;
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  [STORE_REVIEW](context, data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`${path_review}/add`, data)
        .then(({ data }) => {
          context.commit(PUSH_NEW_REVIEW, data);
          resolve(data);
        })
        .catch(response => {
          reject(response);
        });
    });
  },

  [UPDATE_REVIEW](context, data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(`${path_review}/update/${data.campusId}`, data)
        .then(data => {
          resolve(data);
        })
        .catch(response => {
          reject(response);
        });
    });
  },
  [UPDATE_STATUS_REVIEW](context, data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(`${path_review}/update-status`, data)
        .then(data => {
          resolve(data);
        })
        .catch(response => {
          reject(response);
        });
    });
  },  
  [GET_ALL_REVIEWS_CUSTOM](context, dataForm) {
    ApiService.setHeader();
    context.commit("setIsVisibleLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.post(`${path_review}/list/status`, dataForm)
        .then(({ data }) => {
          context.commit(SET_REVIEWS, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(response => {
          context.commit("setIsVisibleLoader", false);
          reject(response);
        });
    });
  },
  [UPDATE_REVIEW_AGREE](context, dataForm) {
    ApiService.setHeader();
    context.commit("setIsVisibleLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.post(`${path_review}/agree`, { id: dataForm })
        .then(({ data }) => {
          resolve(data);
          context.commit(SET_LIST_REVIEW_UPDATED, dataForm);
          context.commit("setIsVisibleLoader", false);
        })
        .catch(response => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [UPDATE_REVIEW_DENY](context, dataForm) {
    ApiService.setHeader();
    context.commit("setIsVisibleLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.post(`${path_review}/deny`, { id: dataForm })
        .then(({ data }) => {
          resolve(data);
          context.commit(SET_LIST_REVIEW_UPDATED, dataForm);
          context.commit("setIsVisibleLoader", false);
        })
        .catch(response => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
};
const mutations = {
  [SET_REVIEWS](state, data) {
    state.reviews = data;
  },
  [SET_REVIEW](state, data) {
    state.review = data;
  },
  [PUSH_NEW_REVIEW](state, data) {
    state.reviews = data;
  },
  [SET_LIST_REVIEW_UPDATED](state, id) {
    const reviews = state.reviews?.filter(item => {
      return item.campusReviewId !== id;
    });
    state.reviews = reviews;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
