import Vue from "vue";
import ApiService from "@/core/services/api.service";
//Actions
export const GET_ALL_LANGUAGES = "getAllLanguages";
export const GET_LANGUAGE = "getLanguage";
export const TRANSLATE_ALL_LANGUAGES = "translateAllLanguages";
export const STORE_LANGUAGE = "storeLanguage";
export const UPDATE_LANGUAGE = "updateLanguage";
export const DELETE_LANGUAGE = "deleteLanguage";
export const CANCEL_LANGUAGE = "cancelLanguage";

//Mutations
export const SET_LANGUAGES = "setLanguages";
export const SET_LANGUAGE = "setLanguage";
export const SET_TRANSLATE_LANGUAGES = "setTranslateLanguages";
export const PUSH_NEW_LANGUAGE = "pushLanguage";
export const PUSH_UPDATE_LANGUAGE = "pushLanguage";
export const REMOVE_ONE_LANGUAGE = "pushLanguage";
export const SET_LOADING = "setLoading";
export const SET_EDITING = "setEditing";
export const CLEAN_LANGUAGE = "cleanlanguage";

const state = {
  languages: [],
  translatedLanguages: [],
  language: {},
  languageId: 1,
  isLoading: false,
  isEditing: false,
};

const getters = {
  languages(state) {
    return state.languages?.map(language => ({
      ...language,
      name: language.translations[0].name,
    }));
  },
  languagesWithCourses(state) {
    return state.languages
      ?.filter(language => language.withCourses)
      .map(language => ({ ...language, name: language.translations[0].name }));
  },
  language(state) {
    return state.language;
  },
  languageId(state) {
    return state.languageId;
  },
  isLoadingLanguage(state) {
    return state.isLoading;
  },
  isEditingLanguage(state) {
    return state.isEditing;
  },
};

const actions = {
  [GET_ALL_LANGUAGES](context) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.query("api/v1/language", {})
        .then(({ data }) => {
          context.commit(SET_LANGUAGES, data);
          context.commit(SET_LOADING, false);
          context.dispatch(TRANSLATE_ALL_LANGUAGES);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_LOADING, false);
        });
    });
  },

  [GET_LANGUAGE](context, id) {
    context.commit(SET_LOADING, true);
    return new Promise(resolve => {
      ApiService.get(`api/v1/language/${id}`)
        .then(({ data }) => {
          context.commit(SET_LANGUAGE, data);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [TRANSLATE_ALL_LANGUAGES](context) {
    context.commit(SET_TRANSLATE_LANGUAGES);
  },

  [STORE_LANGUAGE](context, data) {
    context.commit(SET_LOADING, true);
    return new Promise(resolve => {
      ApiService.post("api/v1/language", data)
        .then(({ data }) => {
          context.commit(PUSH_NEW_LANGUAGE, data);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [UPDATE_LANGUAGE](context, data) {
    context.commit(SET_LOADING, true);
    return new Promise(resolve => {
      ApiService.put(`api/v1/language/${data.id}`, data)
        .then(({ data }) => {
          context.commit(PUSH_UPDATE_LANGUAGE, data);
          context.commit(SET_LOADING, false);
          context.commit(SET_EDITING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [DELETE_LANGUAGE](context, id) {
    context.commit(SET_LOADING, true);
    return new Promise(resolve => {
      ApiService.delete(`api/v1/language/${id}`)
        .then(({ data }) => {
          context.commit(REMOVE_ONE_LANGUAGE, data);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [CANCEL_LANGUAGE](context) {
    context.commit(SET_LOADING, true);
    return new Promise(() => {
      context.commit(CLEAN_LANGUAGE);
      context.commit(SET_LOADING, false);
    }).catch(() => {
      context.commit(SET_LOADING, false);
    });
  },
};
const mutations = {
  [SET_LANGUAGES](state, data) {
    state.languages = data;
  },
  [SET_LANGUAGE](state, data) {
    state.language = data;
  },
  [SET_TRANSLATE_LANGUAGES](state) {
    const languagesTranslated = state.languages.map(language => {
      const translate = language.translations.find(translation => {
        return translation.languageId === translation.languageTranslatedId;
      });

      language.nameTranslated = translate.name;
      return language;
    });
    state.languages = languagesTranslated;
  },
  [PUSH_NEW_LANGUAGE](state, data) {
    state.languages.push(data);
  },
  [PUSH_UPDATE_LANGUAGE](state, data) {
    const position = state.languages.indexOf(state.language);
    Vue.set(state.languages, position, data);
  },
  [REMOVE_ONE_LANGUAGE](state, data) {
    state.languages.splice(state.languages.indexOf(data), 1);
  },
  [SET_LOADING](state, data) {
    state.isLoading = data;
  },
  [SET_EDITING](state, data) {
    state.isEditing = data;
  },
  [CLEAN_LANGUAGE](state) {
    Vue.set(state.language, {});
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
