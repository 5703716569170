//Mutations
export const SET_SERVICE_TYPES = "setServiceTypes";

const state = {
  serviceTypes: [],
  serviceType: {},
  isLoading: false,
  isEditing: false,
};

const getters = {
  serviceTypes(state) {
    return state.serviceTypes;
  },
};

const actions = {};
const mutations = {
  [SET_SERVICE_TYPES](state, data) {
    state.serviceTypes = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
