import GENERAL from "./general";
import MESSAGE from "./messages";
import VALIDATION from "./validation";
import PRODUCTS_ADDONS from "./pages/products_addons";
import FEE from "./pages/fee";
import PRICING from "./pages/pricing";
import SETTINGS from "./pages/settings";
import CAMPUS from "./pages/campus";
import PROMOTIONS from "./pages/promotions";
import TIMETABLE from "./pages/timetable";
import START_DATE from "./pages/startdate";
import CALENDAR from "./pages/calendar";
import PACKAGES from "./pages/packages";
import MARKETS from "./pages/markets";
import LODGING from "./pages/lodging";
import FINANCIAL from "./pages/financial";
import HOME from "./pages/home";
import COMMISSIONS from "./pages/commissions";
import REVIEWS from "./pages/reviews";
import LOGS from "./pages/logs";
import MESSAGES from "./pages/messages";
import TRANSLATE from "./pages/translate";
import APPLICATION from "./pages/application";
import SUPERADMIN from "./pages/superadmin";
import COUPONS from "./pages/coupons";
import SIGNUP from "./pages/signup";

const locale = {
  GENERAL,
  MESSAGE,
  VALIDATION,
  PRODUCTS_ADDONS,
  LOGS,
  PRICING,
  SETTINGS,
  CAMPUS,
  FEE,
  PROMOTIONS,
  TIMETABLE,
  START_DATE,
  CALENDAR,
  PACKAGES,
  MARKETS,
  LODGING,
  FINANCIAL,
  HOME,
  COMMISSIONS,
  REVIEWS,
  MESSAGES,
  TRANSLATE,
  APPLICATION,
  SUPERADMIN,
  COUPONS,
  SIGNUP,
};

export { locale };
