export default {
  ERROR: {
    TITLE_NEW: "Error!",
    DESCRIPTION_NEW: "Please verify the fields",
    FILE_NEW: "Document already exists.",
    FILE_NEW_2: "The file already exists.",
    AT_LEAST_ONE_PRICE: "Please, fill at least one price",
    NO_SELECT: "Please, select an option to continue",
  },
  SUCCESS: {
    TITLE_NEW: "Success!",
    DESCRIPTION_NEW: "The information was saved correctly",
  },
  UPDATE: {
    TITLE_NEW: "Success!",
    DESCRIPTION_NEW: "The information has update",
  },
  DELETE: {
    TITLE_NEW: "Success!",
    DESCRIPTION_NEW: "The record was successfully deleted",
    MODAL_TITLE: "Are you sure to delete this record?",
    MODAL_NO: "What? NO!",
    MODAL_YES: "Yes, sure.",
  },
  DISCONNECT: {
    MODAL_TITLE: "Are you sure to disconnect of company?",
  },
  EMAIL: {
    SENDED: "Email sent successfully",
    SENDED_SUCCESS: "Email sent",
  },
  INFORMATION: {
    PREVIEW_BTN: "show Preview",
    INFO:
      "Only the saved information will display if you make any changes, please save and use the preview option again",
  },
  NOTIFY_ERROR: {
    MODAL_TITLE: "Are you sure to notify the error in product?",
  },
  PUBLISH: {
    MODAL_TITLE: "Are you sure to publish the product?",
  },
};
