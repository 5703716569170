import Vue from "vue";
import ApiService from "@/core/services/api.service";

// PATCH
const patch_product = "api/v1/product-pricing-template";
const patch_product_pricing = "/v1/product-course";
const patch_product_pricing_market = "api/v1/product-pricing-template-market";

//Actions
export const GET_ALL_PRODUCT_PRICINGS = "getAllProductPricings";
export const GET_PRODUCT_PRICING = "getProductPricing";
export const STORE_PRODUCT_PRICING = "storeProductPricing";
export const UPDATE_PRODUCT_PRICING = "updateProductPricing";
export const DELETE_PRODUCT_PRICING = "deleteProductPricing";
export const DELETE_ITEM_PRODUCT_PRICING = "deleteItemProductPricing";
export const CANCEL_PRODUCT_PRICING = "cancelProductPricing";
export const GET_EDITING_PRODUCT_PRICING = "getEditing";
export const GET_RESET_VALUES = "getResetValues";
export const GET_TYPE = "getType";
export const RESET_PRICINGS = "resetPricings";
export const SAVE_MARKET_PRICING = "saveMarketPricing";
export const GET_MARKET_PRICING = "getMarketPricing";
export const DELETE_MARKET_PRICING = "deleteMarketPricing";

//Mutations
export const SET_PRODUCT_PRICINGS = "setProductPricings";
export const SET_PRODUCT_PRICING = "setProductPricing";
export const PUSH_NEW_PRODUCT_PRICING = "pushProductPricing";
export const PUSH_UPDATE_PRODUCT_PRICING = "updateProductPricing";
export const REMOVE_ONE_PRODUCT_PRICING = "deleteProductPricing";
export const REMOVE_ITEM_PRODUCT_PRICING = "deleteItemProductPricing";
export const SET_LOADING = "setLoading";
export const SET_EDITING = "setEditing";
export const CLEAN_PRODUCT_PRICING = "cleanProductPricing";
export const SET_RESET_VALUES = "setResetValues";
export const SET_TYPE = "setType";
export const SET_MARKET_PRICING = "setMarketPricing";

const state = {
  productPricings: [],
  productPricing: {},
  isLoading: false,
  isEditing: false,
  type: null, //simple, flexible, fixed, onetime
  flagSelect: false,
  marketsPricing: [],
};

const getters = {
  productPricings(state) {
    return state.productPricings;
  },
  productPricing(state) {
    return state.productPricing;
  },
  isLoadingProductPricing(state) {
    return state.isLoading;
  },
  isEditingProductPricing(state) {
    return state.isEditing;
  },
  isType(state) {
    return state.type;
  },
  marketPricing(state) {
    return state.marketsPricing;
  },
};

const actions = {
  [GET_ALL_PRODUCT_PRICINGS](context) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.get(patch_product)
        .then(({ data }) => {
          context.commit(SET_PRODUCT_PRICINGS, data);
          context.commit(SET_EDITING, false);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_LOADING, false);
        });
    });
  },

  [GET_PRODUCT_PRICING](context, value) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.get(patch_product + "/" + parseInt(value))
        .then(({ data }) => {
          context.commit(SET_PRODUCT_PRICING, data);
          context.commit(SET_EDITING, true);
          context.commit(SET_TYPE, data.productPricingTemplateRuleTypeId);
          context.commit(SET_LOADING, false);
          //productPricingTemplate = data;
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_LOADING, false);
        });
    });
    /*
    var productPricingTemplate = {};
    context.commit(SET_LOADING, true);
    context.state.productPricings.forEach(obj => {
      if (parseInt(obj.productPricingTemplateId) === parseInt(value)) {
        context.commit(SET_PRODUCT_PRICING, obj);
        context.commit(SET_EDITING, true);
        context.commit(SET_TYPE, obj.productPricingTemplateRuleTypeId);
        context.commit(SET_LOADING, false);
        productPricingTemplate = obj;
      }
    });
    return productPricingTemplate;
    */
  },

  [STORE_PRODUCT_PRICING](context, data) {
    context.commit(SET_LOADING, true);
    const productPricingTemplate = {
      name: data.name,
      productPricingTemplateRuleTypeId: context.state.type,
      campusId: data.campusId,
      isTiered: data.isTiered,
      isReady: data.isReady,
      product_pricings: data.product_pricings,
    };

    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(patch_product, productPricingTemplate)
        .then(({ data }) => {
          context.commit(PUSH_NEW_PRODUCT_PRICING, data.productPricingTemplate);
          context.commit(SET_LOADING, false);
          resolve(data.productPricingTemplate);
        })
        .catch(error => {
          context.commit(SET_LOADING, false);
          reject(error);
        });
    });
  },

  [UPDATE_PRODUCT_PRICING](context, data) {
    context.commit(SET_LOADING, true);
    const productPricingTemplateUpdate = {
      ...data,
      productPricingTemplateRuleTypeId: context.state.type,
      campusId: data.campusId,
    };
    return new Promise((resolve, reject) => {
      ApiService.update(
        patch_product,
        data.productPricingTemplateId,
        productPricingTemplateUpdate
      )
        .then(({ data }) => {
          context.commit(
            PUSH_UPDATE_PRODUCT_PRICING,
            data.productPricingTemplate
          );
          context.commit(SET_PRODUCT_PRICING, data.productPricingTemplate);
          context.commit(SET_LOADING, false);
          context.commit(SET_EDITING, true);
          resolve(data.productPricingTemplate);
        })
        .catch(error => {
          context.commit(SET_LOADING, false);
          reject(error);
        });
    });
  },

  [DELETE_PRODUCT_PRICING](context, id) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.delete(`${patch_product}/${id}`)
        .then(({ data }) => {
          context.commit(REMOVE_ONE_PRODUCT_PRICING, data);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(error => {
          context.commit(SET_LOADING, false);
          reject(error);
        });
    });
  },

  [DELETE_ITEM_PRODUCT_PRICING](context, id, index) {
    context.commit(SET_LOADING, true);
    return new Promise(resolve => {
      ApiService.delete(`${patch_product_pricing}/${id}`)
        .then(({ data }) => {
          if (data.success) {
            context.commit(REMOVE_ITEM_PRODUCT_PRICING, index);
          }
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [CANCEL_PRODUCT_PRICING](context) {
    context.commit(SET_LOADING, true);
  },

  [GET_EDITING_PRODUCT_PRICING](context, value) {
    context.commit(SET_EDITING, value);
  },

  [GET_RESET_VALUES](context) {
    context.commit(SET_RESET_VALUES);
  },

  [GET_TYPE](context, value) {
    context.commit(SET_TYPE, value);
  },

  [RESET_PRICINGS](context, dataForm) {
    context.commit(RESET_PRICINGS, dataForm);
  },
  [SAVE_MARKET_PRICING](context, formData) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(`${patch_product_pricing_market}`, formData)
        .then(({ data }) => {
          context.dispatch(
            GET_MARKET_PRICING,
            formData.productPricingTemplateFatherId
          );
          context.dispatch(
            GET_PRODUCT_PRICING,
            formData.productPricingTemplateFatherId
          );
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(error => {
          context.commit(SET_LOADING, false);
          reject(error);
        });
    });
  },
  [GET_MARKET_PRICING](context, id) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.query(`${patch_product_pricing_market}/${id}`)
        .then(({ data }) => {
          context.commit(SET_MARKET_PRICING, data);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(error => {
          context.commit(SET_LOADING, false);
          reject(error);
        });
    });
  },
  [DELETE_MARKET_PRICING](context, formData) {
    const productPricingTemplateFatherId =
      formData.productPricingTemplateFatherId;
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `${patch_product_pricing_market}/${formData.productPricingTemplateId}`
      )
        .then(({ data }) => {
          context.commit(SET_LOADING, false);
          context.dispatch(GET_MARKET_PRICING, productPricingTemplateFatherId);
          resolve(data);
        })
        .catch(error => {
          context.commit(SET_LOADING, false);
          reject(error);
        });
    });
  },
};
const mutations = {
  [SET_PRODUCT_PRICINGS](state, data) {
    let data2 = Vue.util.extend([], data);
    state.productPricings = data2;
  },
  [SET_PRODUCT_PRICING](state, data) {
    state.productPricing = data;

    Vue.set(state, "productPricing", data);
  },
  [PUSH_NEW_PRODUCT_PRICING](state, data) {
    state.productPricings.push(data);
  },
  [PUSH_UPDATE_PRODUCT_PRICING](state, data) {
    const position = state.productPricings.indexOf(state.productPricing);
    Vue.set(state.productPricings, position, data);
  },
  [REMOVE_ONE_PRODUCT_PRICING](state, data) {
    state.productPricings.splice(state.productPricings.indexOf(data), 1);
  },
  [REMOVE_ITEM_PRODUCT_PRICING](state, data) {
    state.productPricing.product_pricings.splice(
      state.productPricing.product_pricings.indexOf(data),
      1
    );
  },
  [SET_LOADING](state, data) {
    state.isLoading = data;
  },
  [SET_EDITING](state, data) {
    state.isEditing = data;
  },
  [CLEAN_PRODUCT_PRICING](state) {
    Vue.set(state.productPricing, {});
  },
  [SET_RESET_VALUES](state) {
    state.flagSelect = false;
  },
  [SET_TYPE](state, value) {
    state.type = value;
    state.flagSelect = true;
  },
  [RESET_PRICINGS](state, dataForm) {
    state.productPricing.product_pricings = [];
    dataForm.forEach(priceRow => {
      const priceRowNew = {
        productPricingId: priceRow.productPricingId,
        amount: parseFloat(priceRow.amount),
        amountIsPerDuration: priceRow.amountIsPerDuration,
        durationTypeId: priceRow.durationTypeId,
        maxDuration: priceRow.maxDuration,
        minDuration: priceRow.minDuration,
        position: priceRow.position,
        start: priceRow.start ? priceRow.start : null,
        end: priceRow.end ? priceRow.end : null,
      };
      state.productPricing.product_pricings.push(priceRowNew);
    });
  },
  [SET_MARKET_PRICING](state, data) {
    state.marketsPricing = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
