import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/restricted-area",
          name: "restricted-area",
          component: () => import("@/view/pages/RestrictedArea.vue"),
        },
        {
          path: "/",
          redirect: "/home",
          component: () => import("@/view/layout/LayoutVuetify"),
          children: [
            {
              path: "/home",
              name: "home",
              component: () => import("@/view/pages/Home.vue"),
            },
            {
              path: "/products/course",
              name: "product-course-table",
              component: () =>
                import("@/view/pages/productAddon/courses/Table.vue"),
              meta: {
                rol: "products",
              },
            },
            {
              path: "/products/course/:operation",
              name: "product-course-new",
              component: () =>
                import("@/view/pages/productAddon/courses/Form.vue"),
              meta: {
                rol: "products",
              },
            },
            {
              path: "/products/course/:operation/:index",
              name: "product-course-edit",
              component: () =>
                import("@/view/pages/productAddon/courses/Form.vue"),
              meta: {
                rol: "products",
              },
            },
            {
              path: "products/room",
              name: "product-room-table",
              component: () =>
                import("@/view/pages/productAddon/rooms/Table.vue"),
              meta: {
                rol: "products",
              },
            },
            {
              path: "/products/room/:operation",
              name: "product-room-new",
              component: () =>
                import("@/view/pages/productAddon/rooms/Form.vue"),
              meta: {
                rol: "products",
              },
            },
            {
              path: "/products/room/:operation/:index",
              name: "product-room-edit",
              component: () =>
                import("@/view/pages/productAddon/rooms/Form.vue"),
              meta: {
                rol: "products",
              },
            },
            {
              path: "products/service",
              name: "product-service-table",
              component: () =>
                import("@/view/pages/productAddon/services/Table.vue"),
              meta: {
                rol: "products",
              },
            },
            {
              path: "/products/service/:operation",
              name: "product-service-new",
              component: () =>
                import("@/view/pages/productAddon/services/Form.vue"),
              meta: {
                rol: "products",
              },
            },
            {
              path: "/products/service/:operation/:index",
              name: "product-service-edit",
              component: () =>
                import("@/view/pages/productAddon/services/Form.vue"),
              meta: {
                rol: "products",
              },
            },
            {
              path: "/configure/pricing",
              name: "pricing",
              component: () => import("@/view/pages/configure/Pricing.vue"),
              meta: {
                rol: "general",
              },
            },
            {
              path: "/configure/pricing/new",
              name: "pricing-new",
              component: () => import("@/view/pages/configure/pricing/New.vue"),
              meta: {
                rol: "general",
              },
            },
            {
              path: "/configure/pricing/template/:id",
              name: "pricing-edit",
              component: () =>
                import("@/view/pages/configure/pricing/Edit.vue"),
              meta: {
                rol: "general",
              },
            },
            {
              path: "/configure/fees",
              name: "fees",
              component: () => import("@/view/pages/configure/fees/Table.vue"),
              meta: {
                rol: "general",
              },
            },
            {
              path: "/configure/fees/:operation",
              name: "fees-form",
              component: () => import("@/view/pages/configure/fees/Form.vue"),
              meta: {
                rol: "general",
              },
            },
            {
              path: "/configure/fees/:operation/:index",
              name: "fees-form-edit",
              component: () => import("@/view/pages/configure/fees/Form.vue"),
              meta: {
                rol: "general",
              },
            },
            {
              path: "/settings/profile",
              name: "profile-settings",
              component: () => import("@/view/pages/settings/Profile.vue"),
            },
            {
              path: "/settings/company",
              name: "company-settings",
              component: () => import("@/view/pages/settings/Company.vue"),
              meta: {
                rol: "general",
              },
            },
            {
              path: "/settings/teams",
              name: "teams-settings",
              component: () => import("@/view/pages/settings/Teams.vue"),
              meta: {
                rol: "general",
              },
            },
            {
              path: "/campus",
              name: "campus",
              component: () => import("@/view/pages/campus/Table.vue"),
              meta: {
                rol: "general",
              },
            },
            {
              path: "/campus/new",
              name: "campus-new",
              component: () => import("@/view/pages/campus/New.vue"),
              meta: {
                rol: "general",
              },
            },
            {
              path: "/campus/edit/:id",
              name: "campus-edit",
              component: () => import("@/view/pages/campus/Edit.vue"),
              meta: {
                rol: "general",
              },
            },
            {
              path: "/configure/calendar",
              name: "calendar",
              component: () => import("@/view/pages/configure/Calendar.vue"),
              meta: {
                rol: "general",
              },
            },
            {
              path: "/configure/timetables",
              name: "timetables",
              component: () => import("@/view/pages/configure/Timetables.vue"),
              meta: {
                rol: "general",
              },
            },
            {
              path: "/configure/timetables/edit/:id",
              name: "timetables/edit",
              component: () =>
                import("@/view/pages/configure/timetable/Edit.vue"),
              meta: {
                rol: "general",
              },
            },
            {
              path: "/configure/timetables/new/",
              name: "timetables/new",
              component: () =>
                import("@/view/pages/configure/timetable/New.vue"),
              meta: {
                rol: "general",
              },
            },
            {
              path: "/configure/markets",
              name: "markets",
              component: () =>
                import("@/view/pages/configure/Markets/Table.vue"),
            },
            {
              path: "/configure/markets/new",
              name: "new-markets",
              component: () => import("@/view/pages/configure/Markets/New.vue"),
            },
            {
              path: "/configure/markets/edit/:id",
              name: "edit-markets",
              component: () =>
                import("@/view/pages/configure/Markets/Edit.vue"),
            },
            {
              path: "/configure/basic-template",
              name: "basic-template",
              component: () => import("@/view/pages/BasicTemplate.vue"),
            },
            {
              path: "/configure/reviews",
              name: "reviews",
              component: () => import("@/view/pages/reviews/New.vue"),
            },
            {
              path: "/messages",
              name: "messages-center",
              component: () => import("@/view/pages/messages/Inbox.vue"),
            },
            {
              path: "/messages/:id",
              name: "messages-read",
              component: () => import("@/view/pages/messages/Read.vue"),
            },
            {
              path: "/promotions",
              name: "promotions",
              component: () => import("@/view/pages/promotions/Index"),
              meta: {
                rol: "products",
              },
            },
            {
              path: "/promotions/new",
              name: "promotions-new",
              component: () => import("@/view/pages/promotions/New.vue"),
              meta: {
                rol: "products",
              },
            },
            {
              path: "/promotions/edit/:id",
              name: "promotions-edit",
              component: () => import("@/view/pages/promotions/Edit.vue"),
              meta: {
                rol: "products",
              },
            },
            {
              path: "/packages",
              name: "packages",
              component: () => import("@/view/pages/packages/Index"),
              meta: {
                rol: "products",
              },
            },
            {
              path: "/packages/new",
              name: "packages-new",
              component: () => import("@/view/pages/packages/New.vue"),
              meta: {
                rol: "products",
              },
            },
            {
              path: "/packages/edit/:id",
              name: "packages-edit",
              component: () => import("@/view/pages/packages/Edit.vue"),
              meta: {
                rol: "products",
              },
            },
            {
              path: "/lodging",
              name: "lodging",
              component: () => import("@/view/pages/lodging/Table.vue"),
              meta: {
                rol: "general",
              },
            },
            {
              path: "/lodging/new",
              name: "lodging-new",
              component: () => import("@/view/pages/lodging/New.vue"),
              meta: {
                rol: "general",
              },
            },
            {
              path: "/lodging/edit/:id",
              name: "lodging-edit",
              component: () => import("@/view/pages/lodging/Edit.vue"),
              meta: {
                rol: "general",
              },
            },
            {
              path: "/applications",
              name: "applications",
              component: () => import("@/view/pages/application/Table.vue"),
              meta: {
                rol: "reservation",
              },
            },
            {
              path: "/applications/:id",
              name: "applications-form",
              component: () => import("@/view/pages/application/Form.vue"),
              meta: {
                rol: "reservation",
              },
            },
            {
              path: "/admin/company",
              name: "admin-company",
              component: () =>
                import("@/view/pages/superadmin/company/Index.vue"),
            },
            {
              path: "/admin/product",
              name: "admin-product",
              component: () =>
                import("@/view/pages/superadmin/product/Index.vue"),
            },
            {
              path: "/admin/review",
              name: "admin-review",
              component: () =>
                import("@/view/pages/superadmin/reviews/Index.vue"),
            },
            {
              path: "/admin/log",
              name: "admin-log",
              component: () =>
                import("@/view/pages/superadmin/logs/Index.vue"),
            },
            {
              path: "/admin/coupons",
              name: "coupons",
              component: () => import("@/view/pages/coupons/Table.vue"),
            },
            {
              path: "/admin/coupon/new",
              name: "coupons-new",
              component: () => import("@/view/pages/coupons/New.vue"),
            },
            {
              path: "/admin/coupon/edit/:id",
              name: "coupons-edit",
              component: () => import("@/view/pages/coupons/Edit.vue"),
            },
            {
              path: "/admin/feature-product",
              name: "feature-product",
              component: () =>
                import("@/view/pages/superadmin/feature_product/Index.vue"),
            },
            {
              path: "/admin/application",
              name: "admin-application",
              component: () =>
                import("@/view/pages/superadmin/application/Index.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue"),
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue"),
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue"),
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue"),
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue"),
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login"),
        },
        {
          path: "/register",
          redirect: "/register",
          component: () => import("@/view/layout/LayoutVuetify"),
          children: [
            {
              path: "/register",
              name: "register",
              component: () => import("@/view/pages/auth/Register"),
            },
          ],
        },
        {
          name: "success",
          path: "/success",
          component: () => import("@/view/pages/auth/Message"),
        },
        {
          name: "created",
          path: "/created",
          component: () => import("@/view/pages/auth/MessageCreated"),
        },
        {
          name: "reset",
          path: "/reset-password/:id/:token",
          component: () => import("@/view/pages/auth/ResetPassword"),
        },
        {
          name: "remember",
          path: "/remember-password",
          component: () => import("@/view/pages/auth/RememberPaswword"),
        },
      ],
    },
    {
      path: "/wizard/:token",
      redirect: "/wizard/:token",
      component: () => import("@/view/layout/LayoutVuetify"),
      children: [
        {
          path: "/wizard/:token",
          name: "wizard",
          component: () => import("@/view/pages/wizard/Form.vue"),
        },
      ],
    },
    {
      path: "/review/:token",
      redirect: "/review/:token",
      component: () => import("@/view/layout/LayoutVuetify"),
      children: [
        {
          path: "/review/:token",
          name: "review-edit",
          component: () => import("@/view/pages/reviews/index.vue"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
  ],
});
