export default {
  USER: {
    MAIN: {
      TITLE: "User Profile",
      MESSAGES: "messages",
      SING_OUT: "Sign out",
      PROFILE: "My Profile",
      BUSSINESS: "Company",
      TEAM: "Team",
      CAMPUS: "Campus",
      DES_MAIN: "settings and more",
      ACCOMMODATION: "Lodging",
    },
    PERSONAL: {
      TITLE1: "Personal Information",
      TITLE2: "Change Password",
      TITLE3: "Notifications",
      BTN: {
        UPLOAD: "Upload Photo",
        CUSTOMIZE: "Customize",
        REMOVE_CURRENT: "Remove current picture",
      },
      FIELDS: {
        FNAME: "First Name",
        LNAME: "Last Name",
        EMAIL: "Email Address",
        POSITION: "Position in the company",
        PICTURE: "Profile Picture",
        PHONE: "Phone",
        ACCEPTED: "Accepted formats: JPEG, JPG, GIF, PNG. Under 2mb",
        NEW_PASS: "New Password",
        PASSWORD: "Password",
        CO_NEW_PASS: "Confirm New Password",
        CURRENT_PASS: "Current Password",
        NOTIFICATIONS: "Email me when a new application has been submitted",
        G_PASS: "Good password example:",
        B_PASS: "Bad password example:",
        EXAMPLE1: "Test!!@2022",
        EXAMPLE2: "123456",
      },
      PLACHEHOLDER: {
        NEW_PASS: "Enter a new password",
        CO_NEW_PASS: "Enter your new password again",
        CURRENT_PASS: "Current password",
      },
    },
    BUSSINESS: {
      INFO_BTN_SAVE: "Please accept the terms and conditions ",
      TITLE1: "General information",
      TITLE2: "General terms and conditions of Ynsitu",
      TITLE_TERMS: "TERMS AND CONDITIONS",
      TITLE2_INFO1: "You must first fill in the identification fields",
      TITLE2_INFO2:
        "If you want to crate an account for each campus, leave this blank and fill in this information on the campus",
      ACCEPT: "View terms and conditions of Ynsitu",
      ACCEPT2: "Accept:",
      TITLE3: "Cancellation policy",
      TITLE4:
        "Enter the bank details to receive payments for bookings made in YNSITU.",
      POLICY_CANCELLATION:
        "Number of days, prior to the course start date when cancellation is permitted free of cost",
      POLICY: "Free cancellation terms",
      CONDITIONS: "Please set up your cancellation policy",
      CONDITIONS_ALERT: "Please read all the  terms and conditions to continue",
      COPY: "Get the same information of the company",
      POSTPONE_POLICY:
        "How many months do you allow to postpone the beginning of service maintaining the original price and without any type of extra charge?",
      FIELDS: {
        TYPE: "Type Of Companies",
        ID: "VAT ID / Company Registration / ID",
        PERSON_N: "Natural person",
        PERSON_L: "Legal person",
        COMMERCIAL: "Trading name",
        LEGAL_NAME: "Legal Name",
        FIRST_NAME: "First Name",
        LAST_NAME: "Last Name",
        STREET_ADDRESS: "Full Address",
        PHONE: "Phone Number",
        EMAIL: "Email Address",
        WEBSITE: "Website",
        LOGO: "Upload Logo",
        IBAN: "IBAN / Account Number",
        BIC: "BIC / SWIFT",
        ABA: "ABA / Transit number / BSB / other",
        SET_PRICING: "Set pricing transition rules",
        WHAT_HAPPENS:
          "What happens when the study period overlaps the expiration date of the current fee?",
        WHAT_HAPPENS_DES:
          "When the study period overlaps the expiration date, let us know what should we do.",
        WHAT_HAPPENS_OPT1:
          "Use pricing based on Booking Date throughout the whole study duration.",
        WHAT_HAPPENS_OPT2:
          "Use pricing based on Start Date throughout the whole study duration.",
        WHAT_HAPPENS_OPT3: "Switch to a new fee when the current one expires",
      },
      OPTIONS: {
        OPTION1: "School Company",
        OPTION2: "Lodging Provider",
        OPTION3: "Homestay",
        OPTION4: "Home Tuition",
      },
    },
    TEAM: {
      TITLE1: "Invite People",
      TITLE2: "Manage Team Members",
      TITLE3: "Indicate in which areas you want to grant permissions",
      TITLE4: "Campus",
      TITLE5: "Indicate at what level this person has permissions",
      TITLE6: "Enter the details of the person you want to invite.",
      TYPE_USER_1: "At company level",
      TYPE_USER_2_SCHOOL: "At campus level",
      TYPE_USER_2_HOMESTAY: "At lodgings level",
      SELECT_CAMPUS: "Select campus",
      NOT_CAMPUS: "there are no campuses",
      CREATE_CAMPUS: "Create Campus",
      BTN: {
        INVITE: "Invite",
        RE_SEND: "Resend invitation",
      },
      ROLES: {
        OPT1: "All",
        OPT2: "General Settings (Company, Team, Campus, Lodgings)",
        OPT3: "Bookings",
        OPT4: "Products, Pricing and Promotions",
        OPT5: "Financial",
        OPT6: "General Settings (Company, Team, Lodgings)",
      },
    },
  },
};
