export default {
  QUICKSTART: {
    TITLE: "Quickstart",
    TITLE2: "Do you want to skip this step?",
    DESCRIPTION:
      "You can complete it in the corresponding section of the applications menu",
    BTN_ACCEPT: "Accept",
    BTN_CANCEL: "Quickstart",
    BTN_SKIP: "Skip",
    NO: "What? NO!",
    YES: "Yes, sure.",
    REQUIRED: "Required",
  },
  PROFILE: {
    TITLE: "Profile",
    DESCRIPTION: "",
    BTN: "Complete Information",
    EMAIL: "Email:",
    PHONE: "Company Phone:",
    COMPANY: "Company Name:",
  },
  COMPANY: {
    TITLE: "Company",
    DESCRIPTION: "",
    BTN: "Complete Information",
  },
  CAMPUS: {
    TITLE: "Campus",
    DESCRIPTION: "Create each of the campuses offered by your company",
    BTN: "Manage Campus",
  },
  LODGING: {
    TITLE: "Lodging",
    DESCRIPTION:
      "Create each of the accommodation options offered by your company",
    DESCRIPTION1: "Complete your home information",
    DESCRIPTION2:
      "Create the accommodation options that you want to offer for your courses",
    BTN: "Manage Lodging",
  },
  TEAM: {
    TITLE: "Team",
    DESCRIPTION1:
      "Create users for your team members, give them permissions and assign them, if you wish, to a specific campus",
    DESCRIPTION2:
      "Create users for your team members, give them permissions and assign them, if you wish, to a specific lodging",
    BTN: "Manage Team",
  },
  PRICING: {
    TITLE: "Pricing Template",
    DESCRIPTION:
      "Create price templates that you can use in several products / services . If you prefer, you can create them later or skip this step and create prices for each of your products and services.",
    BTN: "Manage Pricing",
  },
  FEES: {
    TITLE: "Fees",
    DESCRIPTION:
      "Create your products/services fees. If you prefer, you can create them later and skip this step now",
    BTN: "Manage Fees",
  },
  TIMETABLES: {
    TITLE: "Timetables",
    DESCRIPTION:
      "Create the schedules or timetables for your courses. If you prefer, you can create them later by entering each of your courses and skipping this step now",
    BTN: "Manage Timetables",
  },
  CALENDAR: {
    TITLE: "Calendar",
    DESCRIPTION1:
      "Create the calendar for each campus indicating the holidays and the start dates of your courses and accommodation options.",
    DESCRIPTION2:
      "Create the calendar for each accommodation option, indicating the holidays and arrival dates.",
    DESCRIPTION3:
      "Create your calendar indicating the holidays and the entry dates for your accommodation options",
    DESCRIPTION4:
      "Create your calendar indicating the holidays and the start dates of your courses.",
    BTN: "Manage Calendar",
  },
  MARKETS: {
    TITLE: "Markets",
    DESCRIPTION:
      "Create custom markets to facilitate the use of rationalized prices and / or promotions on your products and services",
    BTN: "Manage Markets",
  },
  COURSES: {
    TITLE: "Courses",
    DESCRIPTION1: "Create the courses taught on each of your campuses",
    DESCRIPTION2: "Create your courses",
    BTN: "Manage Courses",
  },
  ROOMS: {
    TITLE: "Rooms",
    DESCRIPTION1:
      "Create the rooms available in each of your accommodation options",
    DESCRIPTION2: "Create the available guest rooms in your home",
    BTN: "Manage Rooms",
  },
  SERVICES: {
    TITLE: "Services",
    DESCRIPTION1:
      "Create the optional services that you want to offer. These can be associated to your courses  (certification exams, etc.),  your campuses (leisure activities, etc.) and/or your accommodation options (airport transfers, etc.)",
    DESCRIPTION2:
      "Create the optional services that you want to offer associated to your accommodation options (leisure activities, airport transfers, etc.)",
    DESCRIPTION3:
      "Create the additional services that you want to offer (leisure activities, airport transfers, etc.)",
    BTN: "Manage Services",
  },
  PROMOTIONS: {
    TITLE: "Promotions",
    TITLE2: "# Active Promotions",
    DESCRIPTION:
      "Create specific offers and promotions for your products and / or services. You can create global offers or assign them to specific markets",
    BTN: "Manage Promotions",
    NAME: "Name",
    CREATE_DATE: "Create Date",
    INIT_DATE: "Start Date",
    NO_NAME: "(No Name)",
  },
  PACKAGE: {
    TITLE: "Packages",
    DESCRIPTION:
      "Create packages combining courses with accommodation options and other services",
    BTN: "Manage Packages",
  },
  APPLICATIONS: {
    TITLE: "Pending Applications",
    DATE: "Booking Date",
    DATE_INI: "Start Date",
  },
  CHARGES: {
    TITLE: "Charges",
    TITLE2: "# Pending Charges",
    ID: "Id",
    CLIENT: "Client Name",
    AMOUNT: "amount",
    PAYDAY: "Payment date",
  },
  HOMETUITION: {
    TITLE: "Home Tuition",
    DESCRIPTION: "Enter your home information",
    BTN: "",
  },
  COMMENTS: {
    TITLE: "Customer reviews",
    DESCRIPTION:
      "Providers with ratings and reviews in their profiles manage to improve their sales on our platform by 63%. " +
      "You can invite recent students or customers to rate your products and services trough this tool. Their reviews will appear on your public profile. This tool will only be available  for them during 30 days after their registration on the platform. ",
    BTN: "Request feedback",
  },
};
