//Mutations
export const SET_DURATION_TYPES = "setDurationTypes";

const state = {
  durationTypes: [],
};

const getters = {
  DurationTypes(state) {
    return state.durationTypes;
  },
};

const mutations = {
  [SET_DURATION_TYPES](state, data) {
    state.durationTypes = data;
  },
};

export default {
  state,
  mutations,
  getters,
};
