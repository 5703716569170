import Vue from "vue";
import ApiService from "@/core/services/api.service";

export const GET_CAMPUS = "campus";
export const SET_CAMPUS_PAGE = "setCampusPage";
export const CLEAR_CAMPUS_PAGE = "cleancampusPage";

const state = {
  campusPage: {},
};

const getters = {
  campusPage(state) {
    return state.campusPage;
  },
};

const actions = {
  [GET_CAMPUS](context, id) {
    ApiService.setHeader();
    context.commit("setIsVisibleLoader", true);
    context.commit(CLEAR_CAMPUS_PAGE);
    return new Promise(() => {
      ApiService.post("api/v1/login/campus", { campusId: id }).then(
        ({ data }) => {
          context.commit(SET_CAMPUS_PAGE, data.data.Campus[0]);
          context.commit("setIsVisibleLoader", false);
        }
      );
    });
  },
};

const mutations = {
  [SET_CAMPUS_PAGE](state, data) {
    state.campusPage = data;
  },
  [CLEAR_CAMPUS_PAGE](state) {
    Vue.set(state.campusPage, {});
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
