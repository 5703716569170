export default {
  // GENERAL
  PAGE: "Lodging",
  UNIT: "Unit",
  TYPE: "Type",
  DISTANCE: "Distance",
  DURATION_TYPE: "Duration Type",
  QUANTITY: "Quantity",
  OTHER: "Other",
  SAVE_NEW: "Save And Create New",
  SAVE_EDIT: "Save And Continue Editing",
  NEW_ROOM: "Create New Room",
  PERSON_LABEL: "Person",
  ROOMS: "Rooms",
  ROOM_LIST: "Rooming List",
  FRECUENCY: "Frecuency",
  EVERY: "Every",
  INFO20:
    "If you do not want your address to be shown, indicate the area in which it is located",
  // LIST
  GENDER: {
    OPT1: "Male",
    OPT2: "Female",
    OPT3: "Both",
  },
  PERSON: {
    PERSON_OPT1: "Parent",
    PERSON_OPT2: "Children",
    PERSON_OPT3: "Grandparent",
    PERSON_OPT4: "Other family member",
    PERSON_OPT5: "Other person",
  },
  SEX: {
    MALE: "Male",
    FEMALE: "Female",
    OTHER: "Other",
  },
  RELIGION_LIST: {
    RELIGION_OPT1: "Islamism",
    RELIGION_OPT2: "Christianity",
    RELIGION_OPT3: "Hinduism",
    RELIGION_OPT4: "Buddhism",
    RELIGION_OPT5: "Judaism",
    RELIGION_OPT6: "Shintoism",
    RELIGION_OPT7: "Agnostic/Non Practicing",
  },
  SOCIAL: {
    SOCIAL_OPT1: "Guest can participate in family life if desired",
    SOCIAL_OPT2:
      "The guest is independent and does not participate in family life",
  },
  PETS: {
    PET_OPT1: "Dog",
    PET_OPT2: "Cat",
    PET_OPT3: "Rabbit",
    PET_OPT4: "Fish",
    PET_OPT5: "Turtle",
    PET_OPT6: "Hamster",
    PET_OPT7: "Bird",
    PET_OPT8: "Snake",
    PET_ALL: "All",
  },
  // BASIC INFORMATION
  TITLE: "Basic Information",
  SUB_TITLE:
    "It is not mandatory to fill in this information, but it is strongly recommended that you fill it in to have more chances of getting a sale",
  TITLE2: "Address Information",
  TITLE3: "Select your zone/area",
  TITLE4: "Company Information",
  NAME: "Name",
  CATEGORY: "Category",
  ADDRESS: "Address",
  CITY: "City, Country",
  ZONE_OPT1: "Distance (min. and max.) to the school",
  ZONE_OPT2: "Distance (min. and max.) to the city centre",
  ZONE_OPT3: "District or neighborhood",
  MIN_DISTANCE: "Minimum distance",
  MAX_DISTANCE: "Maximum distance",
  DISTRIC: "District",
  MINIMUM_AGE: "Minimum Age",
  MAXIMUM_AGE: "Maximum Age",
  ALLOWED_GENRES: "Allowed genres",
  CURRENCY: "Currency",
  // DESCRIPTION
  TITLE5: "Description",
  ENVIROMENT_DESCRIPTION: "Description of the area",
  ACCOMMODATION_DESCRIPTION: "Lodging description",
  // PUBLIC TRANSPORT
  TITLE6: "Public transport",
  // GALLERY
  TITLE7: "General Photos of the Lodging",
  INFO1: "Maximum 12 files",
  // LODGING INFORMATION
  TITLE8: "Lodging Information",
  TITLE9: "Cancellation policy",
  TITLE10: "Facilities",
  TITLE11: "Facilities (Security & Safety)",
  SMOKE: "Smoking allowed",
  LAUNDRY: "Laundry Service",
  // APARTMENT INFORMATION
  TITLE12: "Homestay Information",
  TITLE13: "Apartments Information",
  SURFACE: "Useful surface",
  MESURE: "Measure Type",
  LIVING_ROOM: "Living Room",
  KITCHEN: "Kitchen",
  LOUNGE_KITCHEN: "Kitchen-sitting room",
  SHOWER: "Shower",
  BATHTUB: "Bathtub",
  BATHROOMS1: "Shared bathroom with shower & bath",
  BATHROOMS2: "Shared bathroom with shower",
  BATHROOMS3: "Shared bathroom with bath",
  BEDROOMS: "Bedrooms",
  // PEOPLE LIVING IN THE HOUSE
  TITLE14: "Family description",
  TITLE15: "People living in the house",
  TITLE16: "Family Hobbies",
  TITLE17: "Family Photo Gallery",
  TITLE18: "Pets",
  TITLE19:
    "The student is invited to participate in the social life of the family",
  TITLE20: "Guests",
  GENDER2: "Gender",
  BIRTHDAY: "Birthday",
  OCCUPATION: "Occupation",
  NATIONALITIES: "Nationality",
  ANCESTRY: "Ancestry",
  MOTHER_TONGUE: "Mother tongue",
  OTHER_TONGUE: "Other languages spoken",
  RELIGION: "Religion",
  RELIGION_OTHER: "Other religion",
  HOBBIES: "Hobbies",
  FAMILY_DESCRIPTION: "please describe briefly your family (hobbies, etc)",
  ALLOWED_PET: "Pets allowed",
  DURATION: {
    MIN_DURATION: "Minimum duration",
    MIN_DURATION_TYPE: "Minimum duration type",
    MIN_SELLING_DURATION: "Minimum duration sold",
    MIN_SELLING_DURATION_TYPE: "Minimum duration type sold",
  },
  TITLE21: "Enter the exact address, or one of the nearest locations available",
  TITLE22: "Select one of the available locations",
  TITLE23: "Distance to the nearest public transport stop",
  TITLE24: "International Students Number",
  TITLE25: "Courses for this Lodging",
  COURSES: "Courses",
  HELP_MINIMUM_AGE: "Minimum age accepted in your lodging.",
  HELP_ADDRESS: "Your address must be listed in Google maps.",
  HELP_LAUNDRY_SERVICE: "How many times per day / week / month.",
  HELP_CLEANING_SERVICE: "How many times per day / week / month.",
  HELP_BED_LINEN: "How many times per day / week / month.",
  MIN_DURATION_INFO: "Minimum duration of the lodging per week/month.",
  MIN_DURATION_SOLD_INFO:
    "Minimum duration of the sold lodging per week/month.",
};
