import Vue from "vue";
import ApiService from "@/core/services/api.service";

//Actions
export const GET_COURSES = "getCourses";
export const GET_ADMIN_COURSES = "getAdminCourses";
export const GET_COURSE = "getCourse";
export const STORE_COURSE = "storeCourse";
export const UPDATE_COURSE = "updateCourse";
export const DELETE_COURSE = "deleteCourse";
export const LOAD_ADDITIONAL_SETTINGS = "loadAdditionalSettings";
export const GET_COURSES_SERVER_SIDE = "getCoursesServerSide";
export const UPDATE_COURSE_FEATURED = "updateCourseFeatured";
export const GET_COURSE_FEATURED = "getCourseFeatured";
export const FORCE_DELETE_COURSE = "forceDeleteCourse";

//Mutations
export const SET_COURSES = "setCourses";
export const SET_ADMIN_COURSES = "setAdminCourses";
export const SET_COURSES_UPDATED = "setCoursesUpdated";
export const SET_COURSE = "setCourse";
export const SET_ADDITIONAL_SETTINGS = "setAdditionalSettings";
export const ADD_COURSE = "addCourse";
export const REMOVE_COURSE = "removeCourse";
export const SET_EDIT = "setEdit";
export const SET_DUPLICATE = "setDuplicate";
export const CLEAN_COURSE = "cleanCourse";
export const SET_MESSAGE = "setMessage";
export const SET_FEATURED_COURSE = "setFeaturedCourse";

const state = {
  courses: [],
  adminCourses: [],
  course: {},
  additionalSettings: {},
  message: {},
};

const getters = {
  courses(state) {
    return state.courses;
  },
  adminCourses(state) {
    return state.adminCourses;
  },
  totalCourses(state) {
    return state.totalCourses;
  },
  course(state) {
    return state.course;
  },
  isLoadingCourse(state) {
    return state.isLoading;
  },
  isEditCourse(state) {
    return state.isEdit;
  },
  isDuplicateCourse(state) {
    return state.isDuplicate;
  },
  messageCourse(state) {
    return state.message;
  },
};

const actions = {
  [GET_COURSES](context, config) {
    context.commit("setIsVisibleLoader", true);
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      let campusId;
      let route = "api/v1/product-course";
      if (config && config.campusId) {
        campusId = config.campusId;
      } else {
        const campus = context.getters.campus;
        campusId = campus.campusId;
      }
      route = route + `?campusId=${campusId}`;
      ApiService.query(route, config)
        .then(({ data }) => {
          context.commit(SET_COURSES, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [GET_ADMIN_COURSES](context, query) {
    context.commit("setIsVisibleLoader", true);
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`/api/v1/product/search-courses?${query}`)
        .then(({ data }) => {
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [GET_COURSE](context, id) {
    context.commit("setIsVisibleLoader", true);
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.query(`api/v1/product-course/${id}`)
        .then(({ data }) => {
          context.commit("setVisibility", data.product.isReady);
          context.commit(SET_COURSE, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [STORE_COURSE](context, formData) {
    formData.product.campusId = context.getters.campus.campusId;
    formData.productPriceTemplateId = context.getters.productPricing;
    if (formData.productPriceTemplateId.length <= 0) {
      formData.productPriceTemplateId = undefined;
    }
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      context.commit("setIsVisibleLoader", true);
      context.commit(SET_MESSAGE, {});
      ApiService.post("api/v1/product-course", formData)
        .then(({ data }) => {
          context.commit(ADD_COURSE, data);
          context.commit(SET_EDIT, true);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(response => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [UPDATE_COURSE](context, { dataForm, productId }) {
    dataForm.product.campusId = context.getters.campus.campusId;
    dataForm.productPriceTemplateId = context.getters.productPricing;
    if (dataForm.productPriceTemplateId.length <= 0) {
      dataForm.productPriceTemplateId = undefined;
    }
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      context.commit("setIsVisibleLoader", true);
      ApiService.put(`api/v1/product-course/${productId}`, dataForm)
        .then(({ data }) => {
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [DELETE_COURSE](context, dataForm) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      context.commit("setIsVisibleLoader", true);
      ApiService.delete(`api/v1/product-course/${dataForm}`, dataForm)
        .then(({ data }) => {
          context.dispatch("getCourses");
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(response => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [LOAD_ADDITIONAL_SETTINGS](context, data) {
    context.commit(SET_ADDITIONAL_SETTINGS, data);
  },
  [GET_COURSES_SERVER_SIDE](context) {
    const courses = context.getters.courses;
    if (courses.length <= 0) {
      context.commit("setIsVisibleLoader", true);
    }
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      let route = "api/v1/featured-product";
      ApiService.query(route)
        .then(({ data }) => {
          context.commit(SET_COURSES, data);
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [UPDATE_COURSE_FEATURED](context, { countries, productId }) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      context.commit("setIsVisibleLoader", true);
      ApiService.put(`api/v1/featured-product/${productId}`, { countries })
        .then(({ data }) => {
          context.commit("setIsVisibleLoader", false);
          context.commit(SET_FEATURED_COURSE, {
            productId,
            countries,
          });

          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  [GET_COURSE_FEATURED](context, productId) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      let route = `api/v1/featured-product/${productId}`;
      ApiService.query(route)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [FORCE_DELETE_COURSE](context, dataForm) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      context.commit("setIsVisibleLoader", true);
      ApiService.delete(
        `api/v1/product-course/delete/force/${dataForm}`,
        dataForm
      )
        .then(({ data }) => {
          context.dispatch("getCourses");
          context.commit("setIsVisibleLoader", false);
          resolve(data);
        })
        .catch(response => {
          reject(response);
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
};

const mutations = {
  [SET_COURSES](state, data) {
    state.courses = data;
  },
  [SET_ADMIN_COURSES](state, data) {
    data.forEach(course => {
      let auxName = `${course.name} (${course.productId} - ${course.campus})`;
      let newData = {
        ...course,
        couponName: auxName,
      };

      state.adminCourses.push(newData);
    });
  },
  [SET_COURSE](state, data) {
    state.course = data;
  },
  [SET_COURSES_UPDATED](state, data) {
    const position = state.courses.indexOf(state.course);
    Vue.set(state.courses, position, data);
  },
  [ADD_COURSE](state, data) {
    state.courses.push(data);
  },
  [REMOVE_COURSE](state, data) {
    const courseFinded = state.courses.filter(course => {
      if (course.productId === data.productId) {
        return course;
      }
    });
    const position = state.courses.indexOf(courseFinded[0]);
    state.courses.splice(position, 1);
  },
  [SET_EDIT](state, data) {
    state.isEdit = data;
  },
  [SET_DUPLICATE](state, data) {
    state.isDuplicate = data;
  },
  [CLEAN_COURSE](state) {
    Vue.set(state.course, {});
  },
  [SET_ADDITIONAL_SETTINGS](state, data) {
    state.additionalSettings = data;
  },
  [SET_MESSAGE](state, data) {
    state.message = data;
  },
  [SET_FEATURED_COURSE](state, { productId, countries }) {
    const course = state.courses?.filter(item => {
      return item.productId === productId;
    });
    const indexOfCourse = state.courses.indexOf(course[0]);

    const allCountries = countries["africa"].concat(
      countries["asia"],
      countries["europe"],
      countries["north_america"],
      countries["oceania"],
      countries["south_america"]
    );
    const newCourse = state.courses[indexOfCourse];
    newCourse.countriesFeatured = allCountries.length;
    Vue.set(state.courses, indexOfCourse, newCourse);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
