import ApiService from "@/core/services/api.service";
//Actions
export const GET_GALLERY = "getGallery";
export const STORE_GALLERY = "storeGallery";
export const DELETE_GALLERY = "deleteGallery";
export const HIGHLIGHT_IMAGE = "highlightImage";

//Mutations
export const PUSH_ALL_GALLERY = "pushAllGallery";
export const PUSH_GALLERY = "pushGallery";
export const REMOVE_ONE_GALLERY = "removeGallery";
export const CHANGE_HIGHLIGHT_IMAGE_GALLERY = "changeHighlightImageGallery";

const state = {
  gallery: [],
  isLoading: false,
  isEditing: false,
};

const getters = {
  getGallery(state) {
    return state.gallery;
  },
};

const actions = {
  [GET_GALLERY](context, files) {
    context.commit(PUSH_ALL_GALLERY, files);
  },

  [STORE_GALLERY](context, files) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.postFile("api/v1/campus", files)
        .then(({ data }) => {
          context.commit(PUSH_GALLERY, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  [DELETE_GALLERY](context, index) {
    context.commit(REMOVE_ONE_GALLERY, index);
  },
  [HIGHLIGHT_IMAGE](context, files) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("api/v1/gallery/highlight", files)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};

const mutations = {
  [PUSH_ALL_GALLERY](state, data) {
    state.gallery = data;
  },

  [PUSH_GALLERY](state, data) {
    state.gallery.push(data);
  },

  [REMOVE_ONE_GALLERY](state, index) {
    state.gallery.splice(index, 1);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
