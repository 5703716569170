export default {
  HOLIDAY_NAME: "Holiday name",
  SELECT_DATE: "Select Date",
  HOLIDAY_DATE: "Holiday date",
  REPEAT_ANNUALLY: "Repeat annually",
  SELECT_START_DATE: "Select Start Date",
  START_DATE_NAME: "Start Date",
  END_DATE_NAME: "End Date",
  START_TIME_NAME: "Start Time",
  END_TIME_NAME: "End Time",
  AVAILABLE: "Available all 365 days",
  EDIT_HOLIDAYS: "Edit Holidays",
  ADD_HOLIDAY: "Add Holiday",
  EDIT_COURSE_START_DATES: "Edit Course Start Dates",
  EDIT_ROOM_START_DATES: "Edit Accommodation Start Dates",
  YEAR: "Year",
  MONTH: "Month",
  COURSE_START_DATE: "Course Start Date",
  ROOM_START_DATE: "Accommodation Start Date",
  CALENDAR: "Calendar",
};
