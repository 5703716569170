// Actions
export const STORE_VISIBILITY = "storeVisibility";

// Mutations
export const SET_VISIBILITY = "setVisibility";

const state = {
  isReady: false,
};

const getters = {
  visibility(state) {
    return state.isReady;
  },
};

const actions = {
  [STORE_VISIBILITY](context, formData) {
    context.commit(SET_VISIBILITY, formData);
  },
};

const mutations = {
  [SET_VISIBILITY](state, formData) {
    let isReady = formData;
    if (isReady === 1) {
      isReady = true;
    } else if (isReady === 0) {
      isReady = false;
    }
    state.isReady = isReady;
  },
};

export default { state, getters, actions, mutations };
