//EN
export default {
  TITLE: "Packages",
  NEW: "New",
  EDIT: "Edit",
  DELETE: "Delete",
  UPDATE: "Update",
  SAVE: "Save",
  CLEAR: "Clear",
  MESSAGE: {
    SUCCESS_STORAGE: "Package registered successfully.",
    SUCCESS_UPDATE: "Package updated successfully.",
    SUCCESS_DELETE: "Package deleted successfully.",
  },
  TABLE: {
    NAME: "Name",
    CREATED: "Created",
    LAST_UPDATED: "Last updated",
    ACTIONS: "Actions",
  },
  FORM: {
    INFO_TITLE: "Package Information",
    NAME_FIELD: "Name",
    DESCRIPTION_FIELD: "Description",
    PACKAGE_DURATION_TITLE: "Package Duration",
    PACKAGE_DURATION_START_DATE_TEMPLATE_TITLE: "Edit Course Start Dates",
    PACKAGE_DURATION_TYPE_FIELD: "Package Duration Type",
    PACKAGE_DURATION_FIXED_OPTION: "Fixed",
    PACKAGE_DURATION_VARIABLE_OPTION: "Variable",
    PACKAGE_DURATION_APPLY_OPTION: "Enter the Dates and Duration of the Course",
    WEEKLY_CONTROL_DATES_TITLE: "Limit Availability by Date",
    WEEKLY_CONTROL_DATES_HELP:
      "Courses must start and end within this start dates",
    WEEKLY_CONTROL_FROM_DATE: "First Date",
    WEEKLY_CONTROL_TO_DATE: "Last Date",
    WEEKLY_CONTROL_MIN_DURATION_FIELD: "Minimum Duration",
    WEEKLY_CONTROL_MAX_DURATION_FIELD: "Maximum Duration",
    WEEKLY_CONTROL_DURATION_TYPE_FIELD: "Duration Type",
    WEEKLY_CONTROL_SCHOOL_CALENDAR: "School calendar",
    WEEKLY_CONTROL_CUSTOM_CALENDAR: "Custom calendar",
    RANGE_CONTROL_FROM_DATE: "First Date",
    RANGE_CONTROL_TO_DATE: "Last Date",
    RANGE_CONTROL_AMOUNT: "Price",
    RANGE_CONTROL_ADD: "Add",
    RANGE_CONTROL_DURATION_LABEL: "Duration",
    RANGE_CONTROL_DAYS_LABEL: "Days",
    RANGE_CONTROL_WEEKS_LABEL: "Weeks",
    PACKAGE_OPTIONS_TITLE: "Package Components",
    PACKAGE_OPTION_ADD: "Add Components",
    PACKAGE_OPTION_REQUIRED_MESSAGE: "Package Components are required",
    MARKETS_COUNTRY_SELECTOR_TITLE: "Market in which Package can be offered",
    MODAL: {
      TITLE_ADD: "New Package Component",
      TITLE_EDIT: "Edit Package Component",
      SAVE: "Save",
      OPTION_NAME: "Option Name",
      COURSE_SECTION_TITLE: "Add Course",
      COURSE_SECTION_NEW_COURSE:
        "If you want to add a course to a package, the course must be creared first. If you haven't created the course yet, you can do it in Courses.",
      ROOM_SECTION_TITLE: "Add Lodging",
      ROOM_SECTION_NEW_ROOM:
        "If you want to add a lodging to a package, the lodging must be created first. If you haven't created it yet, you can do it in Lodgings.",
      ROOM_SECTION_DURATION_TITLE: "Lodging duration",
      ROOM_RADIO_USE_DURATION: "Same as Course",
      PRICING_RADIO_ENTER_CUSTOM: "Custom Duration",
      SERVICE_SECTION_TITLE: "Add Services",
      SERVICE_SECTION_NEW_SERVICE:
        "If you want to add a service to a package, you must create it first. If you haven't created the service yet, you can do it in Services.",
      FEE_SECTION_TITLE: "Add Fee",
      FEE_SECTION_NEW_FEE:
        "You can select fees for this package that  will replace those that might be associated with the course and/or lodging.",
      SERVICES_CONTROL_ADD: "Add Service",
      SERVICES_CONTROL_SERVICE_LIST: "Service",
      SERVICES_CONTROL_QUANTITY: "Quantity",
      PRICING_SECTION_TITLE: "Pricing",
      PRICING_RADIO_USE_TEMPLATE: "Use Price Template",
      PRICING_RADIO_ENTER_TEMPLATE: "Enter Custom Prices",
      PRICING_CONTROL_PRICE_TEMPLATE_LIST: "Price Template",
    },
  },
};
