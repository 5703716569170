import Vue from "vue";
import ApiService from "@/core/services/api.service";
//Actions
export const GET_ALL_CATEGORY_LANGUAGES = "getAllCategoryLanguages";
export const GET_CATEGORY_LANGUAGE = "getCategoryLanguage";
export const STORE_CATEGORY_LANGUAGE = "storeCategoryLanguage";
export const UPDATE_CATEGORY_LANGUAGE = "updateCategoryLanguage";
export const DELETE_CATEGORY_LANGUAGE = "deleteCategoryLanguage";
export const CANCEL_CATEGORY_LANGUAGE = "cancelCategoryLanguage";

//Mutations
export const SET_CATEGORY_LANGUAGES = "setCategoryLanguages";
export const SET_CATEGORY_LANGUAGE = "setCategoryLanguage";
export const PUSH_NEW_CATEGORY_LANGUAGE = "pushCategoryLanguage";
export const PUSH_UPDATE_CATEGORY_LANGUAGE = "pushCategoryLanguage";
export const REMOVE_ONE_CATEGORY_LANGUAGE = "pushCategoryLanguage";
export const SET_LOADING = "setLoading";
export const SET_EDITING = "setEditing";
export const CLEAN_CATEGORY_LANGUAGE = "cleancategory_language";

const state = {
  categoryLanguages: [],
  categoryLanguage: {},
  isLoading: false,
  isEditing: false,
};

const getters = {
  CategoryLanguages(state) {
    return state.CategoryLanguages;
  },
  categoryLanguage(state) {
    return state.categoryLanguage;
  },
  isLoadingCategoryLanguage(state) {
    return state.isLoading;
  },
  isEditingCategoryLanguage(state) {
    return state.isEditing;
  },
};

const actions = {
  [GET_ALL_CATEGORY_LANGUAGES](context, config) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.query(
        `api/v1/category-language?campusId=${config.campusId}`,
        config
      )
        .then(({ data }) => {
          context.commit(SET_CATEGORY_LANGUAGES, data.data);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_LOADING, false);
        });
    });
  },

  [GET_CATEGORY_LANGUAGE](context, id) {
    context.commit(SET_LOADING, true);
    return new Promise(resolve => {
      ApiService.get(`api/v1/category-language/${id}`)
        .then(({ data }) => {
          context.commit(SET_CATEGORY_LANGUAGE, data);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [STORE_CATEGORY_LANGUAGE](context, data) {
    context.commit(SET_LOADING, true);
    return new Promise(resolve => {
      ApiService.post("api/v1/category-language", data)
        .then(({ data }) => {
          context.commit(PUSH_NEW_CATEGORY_LANGUAGE, data);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [UPDATE_CATEGORY_LANGUAGE](context, data) {
    context.commit(SET_LOADING, true);
    return new Promise(resolve => {
      ApiService.put(`api/v1/category-language/${data.id}`, data)
        .then(({ data }) => {
          context.commit(PUSH_UPDATE_CATEGORY_LANGUAGE, data);
          context.commit(SET_LOADING, false);
          context.commit(SET_EDITING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [DELETE_CATEGORY_LANGUAGE](context, id) {
    context.commit(SET_LOADING, true);
    return new Promise(resolve => {
      ApiService.delete(`api/v1/category-language/${id}`)
        .then(({ data }) => {
          context.commit(REMOVE_ONE_CATEGORY_LANGUAGE, data);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [CANCEL_CATEGORY_LANGUAGE](context) {
    context.commit(SET_LOADING, true);
    return new Promise(() => {
      context.commit(CLEAN_CATEGORY_LANGUAGE);
      context.commit(SET_LOADING, false);
    }).catch(() => {
      context.commit(SET_LOADING, false);
    });
  },
};
const mutations = {
  [SET_CATEGORY_LANGUAGES](state, data) {
    state.CategoryLanguages = data;
  },
  [SET_CATEGORY_LANGUAGE](state, data) {
    state.categoryLanguage = data;
  },
  [PUSH_NEW_CATEGORY_LANGUAGE](state, data) {
    state.categoryLanguages.push(data);
  },
  [PUSH_UPDATE_CATEGORY_LANGUAGE](state, data) {
    const position = state.categoryLanguages.indexOf(state.categoryLanguage);
    Vue.set(state.categoryLanguages, position, data);
  },
  [REMOVE_ONE_CATEGORY_LANGUAGE](state, data) {
    state.categoryLanguages.splice(state.categoryLanguages.indexOf(data), 1);
  },
  [SET_LOADING](state, data) {
    state.isLoading = data;
  },
  [SET_EDITING](state, data) {
    state.isEditing = data;
  },
  [CLEAN_CATEGORY_LANGUAGE](state) {
    Vue.set(state.categoryLanguage, {});
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
