//Mutations
export const SET_CAMPUS_TYPES = "setCampusTypes";

const state = {
  campusTypes: [],
};

const getters = {
  CampusTypes(state) {
    return state.campusTypes;
  },
};

const mutations = {
  [SET_CAMPUS_TYPES](state, data) {
    state.campusTypes = data;
  },
};

export default {
  state,
  mutations,
  getters,
};
