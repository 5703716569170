/* eslint-disable prettier/prettier */
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import UserService from "@/core/services/user.service";
import lodash from "lodash";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logoutUser";
export const REGISTER = "register";
export const REGISTER_TEAM = "registerTeams";
export const GET_ALL_USERS = "getAllUsers";
export const UPDATE_USER = "updateUser";
export const UPDATE_USER_ROL = "updateUserRol";
export const RESET_PASSWORD = "resetPassword";
export const FORGOT_PASSWORD = "forgotPassword";
export const GET_DELETE_USER = "deleteUser";
export const LOAD_STATIC_DATA = "loadStaticData";
export const LOAD_VARIABLE_DATA = "loadVariableData";
export const CLEAR_DATA_LOGOUT = "clearDataLogout";
export const UPDATE_LEFT_MENU = "updateLeftMenu";
export const UPDATE_QUICK_OPTIONS = "updateQuickOptions";
export const UPDATE_TOP_HEADER = "updateTopHeader";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_USER = "setUpdateUser";
export const SET_USER_ROL = "setUpdateUserRol";
export const SET_ALL_USERS = "getAllUsers";
export const SET_USER_LIST = "setUpdateUserList";
export const SET_DELETE_USER = "setDeleteUser";
export const SET_ERROR = "setError";
export const SET_KEY_LEFT_MENU = "setLeftMenu";
export const SET_QUICK_OPTION = "setQuickOption";
export const SET_TOP_HEADER = "setTopHeader";



const state = {
  errors: null,
  data: {},
  userList: [],
  isAuthenticated: !!JwtService.getToken(),
  keyLeftMenu: 0,
  keyQuickOption: 0,
  keyHeader: 0,
};

const getters = {
  currentUser(state) {
    return state.data;
  },
  userList(state) {
    return state.userList;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  keyLeftMenu(state) {
    return state.keyLeftMenu;
  },
  keyQuickOption(state) {
    return state.keyQuickOption;
  },
  keyHeader(state) {
    return state.keyHeader;
  }
};

const actions = {
  async [LOGIN](context, credentials) {
    context.commit("setIsVisibleLoader", true);
    const credential = {
      ...credentials,
      grant_type: process.env.VUE_APP_GRANT_TYPE,
      client_id: parseInt(process.env.VUE_APP_CLIENT_ID),
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
    };
    return await new Promise(resolve => {
      ApiService.post("auth/custom/login", credential)
        .then(({ data }) => {
          context.dispatch(LOAD_STATIC_DATA, data);
          context.dispatch(LOAD_VARIABLE_DATA, data);          

          const { access_token, expires_in, refresh_token } = data;
          const { user } = data;
          context.commit(SET_AUTH, {
            access_token,
            expires_in,
            refresh_token,
            user,
          });
          

          resolve(data);
        })
        .catch(() => {
          context.commit("setIsVisibleLoader", false);
        });
    });
  },
  async [LOGOUT](context) {
    context.commit("setIsVisibleLoader", true);
    ApiService.setHeader();
    return await new Promise((resolve) => {      
      ApiService.post("api/v1/auth/custom/logout").then(() => {
        context.dispatch(CLEAR_DATA_LOGOUT);
        context.commit("setIsVisibleLoader", false);
      });
      resolve(true);
    });
  },
  [REGISTER](context, data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("api/v1/user/register", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          context.commit(SET_ERROR, err);
          reject(err);
        });
    });
  },
  [REGISTER_TEAM](context, data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("api/v1/user", data)
        .then(({ data }) => {
          context.commit(SET_USER_LIST, data.user);
          resolve(data);
        })
        .catch(err => {
          context.commit(SET_ERROR, err);
          reject(err);
        });
    });
  },
  [GET_ALL_USERS](context) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("api/v1/user/all")
        .then(({ data }) => {
          context.commit(SET_ALL_USERS, data.users);
          resolve(data);
        })
        .catch(err => {
          context.commit(SET_ERROR, err);
          reject(err);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      const userData = UserService.getInfo();
      const { data } = this.state.auth;
      data.id = userData.id;
      data.email = userData.email;
      data.roleId = userData.roleId;
      data.name = userData.name;
      data.campusId = userData.campusId;
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_USER_ROL](context, payload) {
    ApiService.setHeader();
    const { id } = payload;
    return ApiService.post(`api/v1/user/update/${id}`, payload).then(data => {
      const { user } = data.data;
      context.commit(SET_USER_ROL, user);
      return data;
    });
  },
  [UPDATE_USER](context, payload) {
    ApiService.setHeader();
    const { id } = UserService.getInfo();
    return ApiService.update("api/v1/user", id, payload).then(data => {
      const { user } = data.data;
      context.commit(SET_USER, user);
      return data;
    });
  },
  [RESET_PASSWORD](context, json) {
    //ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("api/v1/user/reset", json)
        .then(({ data }) => {
          resolve(data);
          return data;
        })
        .catch(err => {
          reject(err);
        });
    });
    /*
    return ApiService.post("api/v1/user/reset", json).then(data => {
      return data;
    });
    */
  },
  [FORGOT_PASSWORD](context, json) {
    return ApiService.post("api/v1/user/reset/token", json).then(data => {
      return data;
    });
  },
  [GET_DELETE_USER](context, id) {
    ApiService.setHeader();
    return ApiService.delete(`api/v1/user/${id}`).then(data => {
      context.commit(SET_DELETE_USER, id);
      return data;
    });
  },
  [LOAD_STATIC_DATA](context, data) {
    const {
      AmenityType,
      BathroomType,
      DurationType,
      MealType,
      PromotionType,         
      RoomType,
      Category,
      Language,
      CompanyType,
      AccommodationType,
      DistanceType,
      TransportType,
      ApartmentType,
      ServiceType,
      Currency,
      DietType,
      Country,
      LanguageLevel,
      FeeType,
    } = data.data;
    context.commit("setAmenityTypes", AmenityType);
    context.commit("setBathroomTypes", BathroomType);
    context.commit("setDurationTypes", DurationType);
    context.commit("setMealTypes", MealType);
    context.commit("setPromotionTypes", PromotionType);
    context.commit("setRoomTypes", RoomType);
    context.commit("setCountries", Country);
    context.commit("setCategories", Category);
    context.commit("setLanguages", Language);
    context.commit("setCompanyTypes", CompanyType);
    context.commit("setAccommodationTypes", AccommodationType);
    context.commit("setDistanceTypes", DistanceType);
    context.commit("setTransportTypes", TransportType);
    context.commit("setApartmentTypes", ApartmentType);
    context.commit("setServiceTypes", ServiceType);
    context.commit("setCurrencies", Currency);
    context.commit("setDietTypes", DietType);
    context.commit("setIsVisibleLoader", false);
    context.commit("setEnglishLevel", LanguageLevel);
    context.commit("setFeeTypes", FeeType);

    const continent = lodash.groupBy(Country, "continent");
    context.commit("setCountriesContinent", continent);

  },
  [LOAD_VARIABLE_DATA](context, data) {
    
    const {
      CampusAccommodation,
      Company,
      Campus,
      ListCampus,
      ProductStartDateTemplate,
      Market
    } = data.data;
    context.commit("setAllAccommodation", CampusAccommodation);
    if(CampusAccommodation.length > 0)
      context.commit("setAccommodation", CampusAccommodation[0]);
    context.commit("setCampuses", ListCampus);
    context.commit("setCampus", Campus[0]);
    context.commit("setMarkets", Market);
    context.commit("setCompany", Company);
    context.commit("setProductStartDateTemplates", ProductStartDateTemplate);
    
    context.commit("setIsVisibleLoader", false);
  },
  [CLEAR_DATA_LOGOUT](context) {
    context.commit(PURGE_AUTH);

    //Here we delete all the state of the application.
    for (let field of Object.keys(context.rootState)) {
      for (let field2 of Object.keys(context.rootState[field])) {
        //We want to delete only our storage (the storage that comes from API, not the storage of the proper app)
        if (
          field !== "htmlClass" &&
                field !== "config" &&
                field !== "breadcrumbs"
        ) {
          if (Array.isArray(context.rootState[field][field2])) {
            context.rootState[field][field2] = [];
          } else if (typeof context.rootState[field][field2] === "object") {
            context.rootState[field][field2] = {};
          }
        }
      }
    }
  },
  [UPDATE_LEFT_MENU](context) {
    context.commit(SET_KEY_LEFT_MENU);
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, data) {
    state.isAuthenticated = true;
    state.data = data;
    state.errors = {};
    UserService.saveData(data.user);
    JwtService.saveToken(state.data);
  },
  [SET_USER_ROL](state, data) {
    state.userList.forEach((element, index) => {
      if (element.id === data.id) {
        state.userList[index] = data;
      }
    });
    state.errors = {};
  },
  [SET_USER](state, data) {
    state.data.user = data;
    state.errors = {};
    UserService.saveData(data);
  },
  [SET_USER_LIST](state, data) {
    state.userList.push(data);
  },
  [SET_ALL_USERS](state, data) {
    state.userList = data;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
    UserService.destroyData();
    localStorage.clear();
    localStorage.removeItem("vuex");
  },
  [SET_DELETE_USER](state, id) {
    state.userList.forEach((element, index) => {
      if (element.id === id) {
        state.userList.splice(index, 1);
      }
    });
  },
  [SET_KEY_LEFT_MENU](state) {
    state.keyLeftMenu += 1;
  }
};

export default {
  state,
  actions,
  mutations,
  getters,
};
