import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/vue-fontawesome";
import "@/core/plugins/vuelidate";
import "@/core/plugins/google-place";
import "vue-search-select/dist/VueSearchSelect.css";
import "@/core/plugins/vue-mask";
import "@/core/plugins/vue-easy-light";

// API service init
ApiService.init();

// Bugsnag Service
Bugsnag.start({
  apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()],
  onError: function(event) {
    if (typeof store.getters.currentUser.user !== "undefined") {
      event.setUser(
        store.getters.currentUser.user.id,
        store.getters.currentUser.user.email,
        `${store.getters.currentUser.user.name} ${store.getters.currentUser.user.lastname}`
      );
      event.addMetadata("userData", store.getters.currentUser.user);
    }
  },
});

// Bugsnag Initialize
Bugsnag.getPlugin("vue").installVueErrorHandler(Vue);

router.beforeEach((to, from, next) => {
  // rol validate
  if (
    typeof to.meta !== "undefined" &&
    typeof to.meta.rol !== "undefined" &&
    to.meta.rol !== ""
  ) {
    if (
      typeof store.getters.currentUser.user !== "undefined" &&
      typeof store.getters.currentUser.user.user_roles !== "undefined"
    ) {
      var permisos = store.getters.currentUser.user.user_roles.filter(rol => {
        if (rol.user_role_type.scope === to.meta.rol) return true;
        return false;
      });
      if (!permisos.length) {
        router.push("/restricted-area");
        //return false;
      }
    }
  }

  // if (store.state.breadcrumbs.changing) {
  //   if (confirm("¿Are you sure you don't waant to save?")) {
  //     next();
  //   } else {
  //     return false;
  //   }
  // }

  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

import VCalendar from "v-calendar";
Vue.use(VCalendar, {
  componentPrefix: "vc", // Use <vc-calendar /> instead of <v-calendar />
  screens: {
    sm: "640px", // (min-width: 640px)
    md: "768px", // (min-width: 768px)
    mdd: "992px", // (min-width: 768px)
    lg: "1100px", // (min-width: 1024px)
    xl: "1360px", // (min-width: 1280px)
    xxl: "1700px", // (min-width: 1280px)
  },
});

import VueMoment from "vue-moment";
Vue.use(VueMoment);

// WYSIWYG STUFF
import CKEditor from "@ckeditor/ckeditor5-vue";
Vue.use(CKEditor);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount("#app");
