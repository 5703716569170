// USA
export default {
  TITLE: "Promotions",
  ACTIVE: "Active",
  EXPIRED: "Expired",
  ALL: "All",
  VISIBILITY: "Visibility",
  PUBLISH: "Publish",
  PUBLISH_MESSAGE: "",
  ALL_COUNTRIES: "All Countries",
  COUNTRY: "Country",
  COUNTRIES: "Countries",
  NEW_PROMOTION: "New",
  EDIT_PROMOTION: "Edit",
  TYPES: {
    AMOUNT_OFF: {
      TITLE: "Amount Off",
      TEXT: "Discount offered to the customer based on a minimum booking",
      SUBTEXT: "ie. Take $100 off when booking 4 weeks or more.",
      HELP_TEXT1: "The amount that we will be deducting.",
      HELP_TEXT2:
        "The number of units the student needs to book to get this discount. The unit depends on what has been configured in the selected course, accommodation or services.",
      HELP_TEXT3:
        "This is the minimum number of weeks (or whatever unit is set in your selected product or service) the student needs to book in order to get the discount.",
      HELP_TEXT4: "This discount applies once per booking.",
      HELP_TEXT5:
        "This discount applies once per booking unit (week/month, etc).",
    },
    PERCENTAGE_OFF: {
      TITLE: "Percentage Off",
      TEXT:
        "Percentage discount offered to the customer based on a minimum booking",
      SUBTEXT: "ie. Get 10% off when booking 8 weeks or more.",
      HELP_TEXT1: "The percentage that we will be deducting.",
      HELP_TEXT2:
        "The number of units the student needs to book to get this discount. The unit depends on what has been configured in the selected course, accommodation or services.",
      HELP_TEXT3:
        "This is the minimum number of weeks (or whatever unit is set in your selected product or service) the student needs to book in order to get the discount.",
    },
    DISCOUNT_FEE: {
      TITLE: "Modify Fees",
      TEXT: "Reduce or waive a fee.",
      SUBTEXT: "ie. 30% off registration fee.",
      HELP_TEXT1:
        "Percentage discount offered to the customer based on a minimum booking.",
      HELP_TEXT2:
        "Discount offered to the customer based on a minimum booking.",
      HELP_TEXT3: "Set a new flat price instead of a recurring fee.",
      HELP_TEXT4:
        "The number of units the student needs to book to get this discount. The unit depends on what has been configured in the selected course, accommodation or services.",
    },
    DURATION_EXTENSION: {
      TITLE: "Free duration",
      TEXT: "Give bonus durations for your products",
      SUBTEXT: "ie. Book 4 weeks and pay only 3",
      HELP_TEXT1:
        "The number of units (weeks, lessons, etc.) that the student will get for free.",
      HELP_TEXT2:
        "The number of units the student needs to book to get this discount. The unit depends on what has been configured in the selected course, accommodation or services.",
      HELP_TEXT3:
        "Create promotions related to other products and services. Example: Get 2 weeks free when booking 6 or more weeks." +
        "First choose the discount you want to apply, and then select the product or service to which it will be applied.",
    },
    DISCOUNT_OTHER_OFFERING: {
      TITLE: "Discount Other Products and Services",
      TEXT: "Create promotions related to other products and services",
      SUBTEXT:
        "ie. Get 10% off  in homestay  accommodation when booking 4+ weeks.",
      HELP_TEXT1:
        "The amount that we will be deducting from other product or service.",
      HELP_TEXT2:
        "The percentage that we will be deducting form other product or service.",
      HELP_TEXT3:
        "The number of units the student needs to book to get this discount. The unit depends on what has been configured in the selected course, accommodation or services.",
    },
    AGENT_BONUS_COMMISSION: {
      TITLE: "Set up special commissions for specific products",
      TEXT:
        "set up special commissions for specific products you would like us to highlight",
      SUBTEXT: "ie. 5% extra commission for Business English courses",
      HELP_TEXT1:
        "If you enter 5%, we'll add that to the agency's default commission when they sell products or services from this promotion. (ex. 20% commission becomes 25%.)",
      HELP_TEXT2:
        "We'll add a fixed bonus to the commission (ex. $100 extra to each agent that sells offerings in this promotion. If it's 'per unit,' we'll add $100 for every week/months/etc they sell.)",
      HELP_TEXT3:
        "This replaces the default commission rate set for all agents when they sell  this promotion. (ex. Everyone will get 20% when they sell this promotion.)",
    },
    OVERRIDE_PRICE_TEMPLATE: {
      TITLE: "Override Prices",
      TEXT: "Useful when specifying market pricing.",
      SUBTEXT: "Completely overwrite the prices or the price template.",
      HELP_TEXT1:
        "You can set a price template that completely replaces the pricing you've set for the selected product or service in this promotion.",
      DELETE_BTN: "Do you want to delete this promotion and its data?",
    },
    CUSTOM: {
      TITLE: "Custom Offer",
      TEXT: "Describe a custom offer.",
      SUBTEXT: "ie. Get free a ticket for the London Eye.",
      HELP_TEXT1: ":A brief explanation of your custom offer.",
      HELP_TEXT2:
        "The number of units the student needs to book to get this discount. The unit depends on what has been set in the selected course, lodging, or service.",
      HELP_TEXT3: "Descriptions entered here will be visible to customers.",
    },
  },
  FORM: {
    PROMOTION_INFORMATION: "Promotion Information",
    PROMOTION_DETAILS: "Promotion Details",
    NAME: "Name",
    DESCRIPTION: "Description",
    ELEGIBLE_PRODUCTS: "Eligible Products and Services",
    COURSES: "Courses",
    ACCOMMODATION: "lodgings",
    SERVICES: "Services",
    ADD_TIER: "Add Row",
  },
  TRANSLATIONS: "Translations",
  TYPE: "Type",
  NAME: "Name",
  NATIONALITIES: "Nationalities",
  BOOKING_EXPIRY: "Booking Expiry",
  MIN_STUDY_DURATION: "Minimum Booking Duration",
  OFF: "Percentage Off",
  PER_REGISTRATION: "Per Booking",
  PER_UNIT: "Per Unit",
  PER_UNIT_2: "Per Unit (week, etc.)",
  FREE_DURATION: "Free Duration",
  BONUS_DURATION: "Free Duration",
  INCREASE_COMMISION_RATE_BY: "Increase commission rate by",
  DOLLAR_AMOUNT_BONUS: "Amount Bonus",
  OVERRIDE_COMMISSION_RATE: "Amount Override Commission Rate",
  BONUS_AMOUNT: "Bonus Amount",
  BONUS_PERCENTAGE: "Bonus Percentage",
  COMMISSION_OVERRIDE: "Overwrite Commission",
  ERROR_SELECT_PRODUCTS:
    "Please select at least one course, lodging or service!",
  VALID_TIME_FRAME: "Valid Time Frame",
  VALID_FOR_BOOKINGS_MADE: "Valid for bookings made",
  VALID_FOR_START_DATES: "Valid for courses/lodgings that start on these dates",
  START_DATES: "Start Dates",
  BOOKINGS: "Bookings",
  FEE: "Fee",
  SELECT_FEE: "Select Fee",
  FLAT_FREE: "Flat Free",
  FLAT_FEE: "Flat Charge",
  FEE_AMOUNT_OFF: "Amount Off",
  FEE_PERCENTAGE_OFF: "Percentage Off",
  DISCOUNT_TYPE: "Discount Type",
  AMOUNT_OFF: "Amount off",
  NO_NAME: "No name",
  MARKETS_COUNTRY_SELECTOR_TITLE: "The Market in which this Promotion applies",
  NEW_PROMOTION_HELP_TEXT1:
    "Please press `Continue` button for saving the data.",
  NEW_PROMOTION_HELP_TEXT2: "Continue to adding promotions and  discounts",
  MINIMUM_BOOKING_DURATION: "Minimum Booking Duration",
  PERCENTAGE_OFF: "Percentage Off",
};
