//EN
export default {
  TITLE: "Messages",
  READMESSAGE: "Read Message",
  PRODUCT: "Product",
  FROM: "From",
  MESSAGE: "Message",
  DATE: "Date",
  ACTIONS: "Actions",
  REPLY: "REPLY",
  FIRSTNAME: "First Name",
  LASTNAME: "Last Name",
  COUNTRY: "Country",
};
